import { trigger, state, style, group, animate, transition, keyframes, useAnimation } from '@angular/animations';
import { bounce } from 'ng-animate';

export const tournamentAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  [trigger('NumberStyle', [
    state('normal_number', style({
      'background-image': 'linear-gradient(to top, #ffffff, #eef1f8)',
      color: '#000000',
    })),
    state('system_selected_number', style({
      background: '#c60000',
      color: '#fff',
    })),
    state('system_selected_match_number', style({
      background: '#c60000',
      color: '#ffba1f',
    })),
    state('user_selected_number', style({
      background: '#c60000',
      color: '#fff',
    })),
    transition('user_selected_number => normal_number', [
      animate('0s')
    ]),
    transition('normal_number => user_selected_number', [
      animate('100ms')
    ]),
    transition('normal_number => system_selected_number',
      [
        group([
          animate('500ms 1500ms ease-in', style({ background: '#c60000', color: '#fff', })),
        ])

      ]),
    transition('system_selected_number => normal_number', [
      animate('0s')
    ]),
    transition('normal_number => system_selected_match_number',
      [
        group([
          animate('500ms 1500ms ease-in', style({ background: '#c60000', color: '#fff', })),
        ])

      ]),
    transition('system_selected_match_number => normal_number', [
      animate('0s')
    ]),
  ]),
  trigger('BetBallShowHide', [
    state('SelectNumber', style({
      opacity: 1,
      color: '#fff',
      transform: 'scale(1,1)'
    })),
    state('SelectMatchNumber', style({
      opacity: 1,
      color: '#ffba1f',
      transform: 'scale(1,1)'
    })),
    state('NotSelectNumber', style({
      opacity: 0,
      color: '#fff',
      transform: 'scale(0,0)'
    })),

    transition('NotSelectNumber => SelectNumber', [
      group([

        animate('1000ms 200ms ease-in', style({ opacity: 1, transform: 'scale(1,1)' })),
      ])
    ]),
    transition('SelectNumber => NotSelectNumber', [
      group([
        animate('400ms ease-in', style({ opacity: 0, transform: 'scale(0,0)', 'transition-delay': '150ms' })),
      ])
    ]),
    transition('NotSelectNumber => SelectMatchNumber', [
      group([
        animate('0ms', style({ color: '#ffba1f' })),
        animate('1000ms 200ms ease-in', style({ opacity: 1, transform: 'scale(1,1)' })),
      ])
    ]),
    transition('SelectMatchNumber => NotSelectNumber', [
      group([
        animate('400ms ease-in', style({ opacity: 0, transform: 'scale(0,0)', 'transition-delay': '150ms' })),
      ])
    ]),
  ]),
  trigger('ticketballmatch', [
    state('normal', style({
      background: '#fff'
    })),
    state('selected', style({
      background: '#ffba1f'
    })),

    transition('normal => selected', [
      animate('500ms 1000ms ease-in')
    ]),
    transition('selected => normal', [
      animate('0s')
    ]),
  ]),
  ];
