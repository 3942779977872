<section [style.display]="isLoadCompleted ? 'block' : 'none'" class="game-canvas">
    <div class="container-game">
        <div class="vital-canvas">
            <div class="head-sec">
                <div class="left">
                    <!-- https://apps.facebook.com/204477644001849 -->
                    <!-- /#/tournament -->
                    <a routerLink="tournament" target="_blank" class="tournament">TOURNAMENTS</a>
                    <div class="box_count box_count_red" id="help_conins">
                        <div class="bc_left">
                            <img src="assets/images/chip_img.png" />
                        </div>
                        <div class="bc_middle">
                            <span>${{playerDetails.user_coin | numbformat}}</span>
                            
                        </div>
                        <div class="bc_right">
                            <a href="javascript:void(0);" (click)="OpenPowerUp(powerUpmodal)"><img src="assets/images/add_icon.png" /></a>
                        </div>
                    </div>
                </div>
                <div class="middle" ngbTooltip="{{playerDetails.user_xp | numbformat}}/{{playerDetails.xp_needed | numbformat }}" tooltipClass="xp-tooltip" placement="auto">

                    <div style="width: 100px;position: absolute; margin-left: 7px; margin-top: 18px;">
                        <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType" [legend]="false" [colors]="doughnutChartColor" [options]="doughnutChartoptions2">
                        </canvas>
                    </div>
                    <div style="width: 170px;position: absolute; margin-left: -28px; margin-top: 0px;">
                        <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType" [legend]="false" [colors]="doughnutChartColor" [options]="doughnutChartoptions1">
                        </canvas>
                    </div>

                    <span class="level"> {{playerDetails.game_leave}} </span>
                </div>
                <div class="right">
                    <div class="rt_top">
                        <div class="box_left">
                            <div class="box_count box_count_deepred" id="help_diamonds">
                                <div class="bc_left">
                                    <img src="assets/images/bc_deepred_img.png" />
                                </div>
                                <div class="bc_middle">
                                    <span>${{playerDetails.user_bucks | numbformat}}</span>
                                </div>
                                <div class="bc_right">
                                    <a href="javascript:void(0);" (click)="OpenDiamon(diamondmodal)"><img src="assets/images/add_icon.png " /></a>
                                </div>
                            </div>
                        </div>
                        <div class="fb_icon ">
                            <a href="javascript:void(0); "><img src="assets/images/facebook_icon.png " /></a>
                        </div>
                    </div>
                    <div class="box_count box_count_blue" id="help_bugs">
                        <div class="bc_left ">
                            <img src="assets/images/bc_blue_img.png " />
                        </div>
                        <div class="bc_middle ">
                            <span>${{playerDetails.user_ruby | numbformat}}</span>
                        </div>
                        <div class="bc_right ">
                            <a href="javascript:void(0); " (click)="OpenPrize(prizemodal) "><img src="assets/images/add_icon.png " /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-sec ">
                <div class="body-left ">
                    <div class="stats-toolbar ">
                        <ul>
                            <li class="stats ">
                                <a href="javascript:void(0); " (click)="OpenStat(gamestatsmodal) "><img src="assets/images/stats_img.png " />STATS</a>
                            </li>
                            <li class="prizes ">
                                <a href="javascript:void(0); " (click)="OpenPrize(prizemodal) "><img src="assets/images/prizes_img.png " />PRIZES</a>
                            </li>
                            <li class="powerups ">
                                <a href="javascript:void(0); " (click)="OpenPowerUp(powerUpmodal) "><img src="assets/images/powerups_img.png " />POWERUPS</a>
                            </li>
                            <li class="achievement ">
                                <a href="javascript:void(0); " (click)="OpenAchievement(achimentmodal) "><img src="assets/images/achievement_img.png " />ACHIEVEMENTS</a>
                            </li>
                            <li class="help ">
                                <a href="javascript:void(0);" (click)="help()"><img src="assets/images/help_img.png " />HELP</a>
                            </li>
                        </ul>
                    </div>
                    <div [@slideOpenClose]="time>=11 ? 'Open' : 'Close'" class="marquee_sec" *ngIf="isLoadCompleted">
                        <marquee behavior="" direction="">best of luck {{playerDetails.name}}!</marquee>
                    </div>
                    <div class="board_list" id="help_board_list">
                        <ul>
                            <ng-template ngFor let-num [ngForOf]="numberset" let-i="index">
                                <span [@BetBallShowHide]="SytemSelectNumberStage(num)" class="ball_img {{SytemSelectNumberStage(num)}}" *ngIf="i < divNumber">
                                    <span>{{ num }}</span>
                                </span>
                                <li [@NumberStyle]="numberClass(num)" (click)="chooseNumber(num)" *ngIf="i < divNumber">
                                    <a href="javascript:void(0);">{{ num }}</a>
                                </li>
                            </ng-template>
                        </ul>
                        <div class="jackpot">
                            <span class="coin_pot" style="margin-left: 10px; float: left;"><img src="assets/images/coin_pot.png" /></span>
                            <span class="count">$ {{ numformat(jackpotBegin) }}</span>
                            <!-- <span class="count">$ 450,000,000,000,000,000</span> -->
                            <span class="id">#{{ numformat(gameNumber) }}</span>
                            <span class="timer">{{ timeformat(time) }}</span>
                            <span class="powerdraw" *ngIf="powerdraw>0"><span class="powerdraw_text">Power Draw</span> <span class="powerdraw_count"><span>{{ powerdraw }}X</span></span>
                            </span>
                        </div>
                        <ul>
                            <ng-template ngFor let-num [ngForOf]="numberset" let-i="index">
                                <span [@BetBallShowHide]="SytemSelectNumberStage(num)" class="ball_img {{SytemSelectNumberStage(num)}}" *ngIf="i >= divNumber">
                                    <span>{{ num }}</span>
                                </span>
                                <li [@NumberStyle]="numberClass(num)" (click)="chooseNumber(num)" *ngIf="i >= divNumber">
                                    <a href="javascript:void(0);">{{ num }}</a>
                                </li>
                            </ng-template>
                        </ul>
                    </div>
                    <div [@slideOpenClose]="time<11 ? 'Open' : 'Close'" class="balls-home">
                        <ul>
                            <ng-template ngFor let-lsn [ngForOf]="systemSelectNumbers" let-i="index">
                                <li [@fadeInOut] [ngClass]="{ 'powerball' : lsn==selectedpowernumber }">
                                    <a href="javascript:void(0);">{{ lsn }}</a>
                                </li>
                            </ng-template>
                        </ul>
                    </div>
                    <div class="tickets-main-sec">
                        <div class="tickets-main-top">
                            <h2 *ngIf="tickets_count>1 && tickets.length > 0"> {{tickets_count | numbformat }} tickets</h2>
                            <h2 *ngIf="tickets_count<=1 &&  tickets.length > 0"> {{tickets_count}} ticket</h2>
                        </div>
                        <div class="tickets-main-btm" *ngIf="tickets.length > 0">
                            <div class="tickets-main-inr">
                                <ng-template ngFor let-ticket [ngForOf]="tickets" let-i="index">
                                    <div class="tickets-block">
                                        <img src="assets/images/tickets_logo.png" class="ticket_logo">
                                        <h2 *ngIf="playticketStatus<=1"><img src="assets/images/tickets_coin.png"> ${{ticket.w | thousandSuff :2 }}</h2>
                                        <h2 *ngIf="playticketStatus==2"><img src="assets/images/tickets_coin.png"> ${{ticket.coins | thousandSuff :2}}</h2>
                                        <h2 *ngIf="playticketStatus==2"><img src="assets/images/prize_bucks_img.png"> ${{ticket.bucks | thousandSuff :2}}</h2>
                                        <h3 *ngIf="ticket.n.length<=1">{{ticket.n.length}} number</h3>
                                        <h3 *ngIf="ticket.n.length>1">{{ticket.n.length}} numbers</h3>
                                        <h3># {{ticket.l}} ({{draws}} draw)</h3>
                                        <h3 *ngIf="playticketStatus==1">{{getTicketNumbers(ticket.n)}}/{{ticket.np}}</h3>
                                        <ul class="numbers" [ngClass]="{ 'numbers-one' : ticket.n.length==1 ,'numbers-two' : ticket.n.length >1 && ticket.n.length<=4 ,'numbers-three' : ticket.n.length >4 && ticket.n.length<=9 ,'numbers-four' : ticket.n.length >9 && ticket.n.length<=16,'numbers-five' : ticket.n.length >16  }">
                                            <ng-template ngFor let-tno [ngForOf]="ticket.n" let-i="index">
                                                <li [@ticketballmatch]="ticketnumberClass(tno)"><a href="javascript:void(0)">{{tno}}</a></li>
                                            </ng-template>
                                        </ul>
                                        <div class="id-btn">
                                            <div class="id-number">{{ticket.i}}</div>
                                            <a href="javascript:void(0)" (click)="OpenPayout(payout,ticket.n.length,ticket.w)" class="payout-btn">PAYOUTS</a>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="tickets-main-sec" *ngIf="stickets.length>0">
                        <div class="tickets-main-btm">
                            <div class="tickets-main-inr">
                                <ng-template ngFor let-ticket [ngForOf]="stickets" let-i="index">
                                    <div class="tickets-block col-3">
                                        <img src="assets/images/tickets_logo.png" class="ticket_logo">
                                        <h2><img src="assets/images/tickets_coin.png"> ${{ticket.w}}</h2>
                                        <h3># {{ticket.l}}</h3>
                                        <h3>({{sdraws}} draw)</h3>
                                        <ul class="sidebet_numbers">
                                            <li [@sidebetmatch]="sidebetnumberClass(ticket.Numbers)" *ngIf="ticket.t!='power'"><a href="javascript:void(0)">{{ticket.t}}</a></li>
                                            <li [@sidebetmatch]="sidebetnumberClass(ticket.Numbers)" *ngIf="ticket.t=='power'"><a href="javascript:void(0)">{{ticket.b}}</a></li>
                                        </ul>
                                        <div class="id-btn">
                                            <div class="id-number">{{ticket.i}}</div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>

                        </div>
                    </div>
                    <div class="last-draw-winner-sec">
                        <div class="last-draw-top">

                            <div class="dropdown">
                                <div class="select">
                                    <span>Last Draw Winners</span>
                                </div>
                                <ul class="dropdown-menu">
                                    <li (click)="getwinnerList(item.key);" [(id)]="item.key" *ngFor="let item of leaderboard | keyvalue" >
                                        {{ item.value.title | uppercase }}
                                    </li>
                                </ul>
                            </div>

                            <div class="last-draw-head">
                                <h2>{{winnerData?.title}}</h2>
                                <h3>{{winnerData?.desc}}</h3>
                            </div>
                        </div>
                        <div class="last-draw-btm">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th" *ngFor="let item of winnerData?.cols">{{item}}</th>
                                        <!-- <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="ticket-th">Tickets</th>
                                        <th class="wagered-th">Wagered</th>
                                        <th class="chips-th">Chips</th>
                                        <th class="prize-th">Prize</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerData?.data; let i =index;">
                                        <td class="wagered" *ngFor="let smallItem of item; let j=index;">
                                            <h2 *ngIf="j!==1">{{smallItem | thousandSuff : 2}}</h2>
                                            <img *ngIf="j==1" src="{{ smallItem.p }}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <!-- <td class="wagered" *ngFor="let smallItem of item; let j=index;" *ngIf="j==1">
                                            <h2>{{ smallItem.n }} </h2>
                                        </td> -->
                                        <!-- <td class="img">
                                            <img src="{{ item[1].p }}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{ item[1].n }} </h2>
                                        </td>
                                        <td class="ticket">
                                            <h2>{{ item[2] | thousandSuff : 2 }} </h2>
                                        </td>
                                        <td class="wagered">
                                            <h2>{{ item[3] }} </h2>
                                        </td>
                                        <td class="chips">
                                            <h2>{{ item[4] }} </h2>
                                        </td>
                                        <td class="prize">
                                            <h2>{{ item[5] }} </h2>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="last-draw-btm" *ngIf="winnertypeselect==2 && winnerlist.XP_Level_Leaders.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">Level</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.XP_Level_Leaders; let i =index;">
                                        <td class="rank">
                                            <h2>{{i+1}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}} </h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==3 && winnerlist.Powerball_Winner.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="ticket-th">Won</th>
                                        <th class="wagered-th">Draw Id</th>
                                        <th class="chips-th">Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.Powerball_Winner">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{ item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{ item[1].n }}</h2>
                                        </td>
                                        <td class="ticket">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="wagered">
                                            <h2>#{{item[3]}}</h2>
                                        </td>
                                        <td class="chips">
                                            <h2>{{item[4]}}</h2>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==4 && winnerlist.Hightest_Wagered.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">Wagered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.Hightest_Wagered">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}} </h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==5 && winnerlist.resent_jp.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="ticket-th">Draw ID</th>
                                        <th class="wagered-th">Matched</th>
                                        <th class="chips-th">Coins Won</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.resent_jp">
                                        <td class="rank">
                                            <h2>{{ item[0] }}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{ item[1].p }}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="ticket">
                                            <h2>#{{ item[2] }}</h2>
                                        </td>
                                        <td class="wagered">
                                            <h2>{{ item[3] }}</h2>
                                        </td>
                                        <td class="chips">
                                            <h2>{{item[4]}}</h2>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==6  && winnerlist.biggest_jp_today.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="ticket-th">Draw ID</th>
                                        <th class="wagered-th">Matched</th>
                                        <th class="chips-th">Coins Won</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.biggest_jp_today">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="ticket">
                                            <h2>#{{item[2]}}</h2>
                                        </td>
                                        <td class="wagered">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                        <td class="chips">
                                            <h2>{{item[4]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==7  && winnerlist.Powerball_Comp_Winner.length>0">
                            <table class="table power_comp">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="win-th">Wins</th>
                                        <th class="totalchip-th">Total Chip Wins</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.Powerball_Comp_Winner">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}} </h2>
                                        </td>
                                        <td class="win">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="totalchip">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==8 && winnerlist.wager_comp_one.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">Wagered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.wager_comp_one">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==9 && winnerlist.wager_comp_two.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">Wagered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.wager_comp_two">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==10 && winnerlist.wager_comp_three.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">Wagered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.wager_comp_three">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==11 && winnerlist.wager_comp_four.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">Wagered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.wager_comp_four">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect==12  && winnerlist.biggest_jp_alltime.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="ticket-th">Draw ID</th>
                                        <th class="wagered-th">Matched</th>
                                        <th class="chips-th">Coins Won</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.biggest_jp_alltime">
                                        <td class="rank">
                                            <h2>{{ item[0] }}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="ticket">
                                            <h2>#{{item[2]}}</h2>
                                        </td>
                                        <td class="wagered">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                        <td class="chips">
                                            <h2>{{item[4]}}</h2>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                        <!-- <div class="last-draw-btm" *ngIf="winnertypeselect>=13 && winnertypeselect<=19 && winnerlist.com_one.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">3/3 Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.com_one">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect>=13 && winnertypeselect<=19 && winnerlist.com_two.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">3/3 Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.com_two">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect>=13 && winnertypeselect<=19 && winnerlist.com_three.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">3/3 Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.com_three">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect>=13 && winnertypeselect<=19 && winnerlist.com_four.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">3/3 Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.com_four">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect>=13 && winnertypeselect<=19 && winnerlist.com_five.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">3/3 Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.com_five">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect>=13 && winnertypeselect<=19 && winnerlist.com_six.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">3/3 Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.com_six">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="last-draw-btm" *ngIf="winnertypeselect>=13 && winnertypeselect<=19 && winnerlist.com_seven.length>0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="rank-th">Rank</th>
                                        <th class="img-th"></th>
                                        <th class="player-th">Player</th>
                                        <th class="level-th">XP Level</th>
                                        <th class="level-th">3/3 Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of winnerlist.com_seven">
                                        <td class="rank">
                                            <h2>{{item[0]}}</h2>
                                        </td>
                                        <td class="img">
                                            <img src="{{item[1].p}}&access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc">
                                        </td>
                                        <td class="name">
                                            <h2>{{item[1].n}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[2]}}</h2>
                                        </td>
                                        <td class="level">
                                            <h2>{{item[3]}}</h2>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div> -->
                    </div>
                </div>
                <div class="body-right">
                    <div class="buy-ticket-sec">
                        <a href="javascript:void(0);" class="buy_btn" *ngIf="isPlay" (click)="openBuyTicket(0)">buy tickets</a>
                        <a href="javascript:void(0);" class="buy_btn" *ngIf="!isPlay">Draw in progress</a>
                        <div [@slideInOut]="isBuyingTicket ? 'in' : 'out'" class="ticket-dropdown" id="help_ticket_booth">
                            <!-- Nav tabs -->
                            <ul ngbNav #nav="ngbNav" [(activeId)]="buyticketnav" [destroyOnHide]="false" class="nav-tabs">
                                <!-- Tabs Page -->
                                <li [ngbNavItem]="1" [destroyOnHide]="true">
                                    <a ngbNavLink>Regular</a>
                                    <ng-template ngbNavContent>

                                        <div class="tckt-block">
                                            <h2>wager</h2>
                                            <div class="amount-plusminus">
                                                <input appOnlyNumber="true" type="text" (change)="validatebetdetails('regular_wager')" [(ngModel)]="wager">
                                                <div class="plusminus">
                                                    <a href="javascript:void(0);" (click)="changewager(1)"><img src="assets/images/plus.png"></a>
                                                    <a href="javascript:void(0);" (click)="changewager(-1)"><img src="assets/images/minus.png"></a>
                                                </div>
                                                <div class="range">
                                                    <mv-slider [(value)]="wager" [(min)]="playerDetails.user_min_wager" [(max)]="playerDetails.user_max_wager ">
                                                    </mv-slider>
                                                </div>
                                            </div>
                                            <div class="tckt-block ">
                                                <h2>draws</h2>
                                                <div class="amount-plusminus ">
                                                    <input appOnlyNumber="true " type="text " (change)="validatebetdetails( 'regular_draw') " [(ngModel)]="draws " />
                                                    <div class="plusminus ">
                                                        <a href="javascript:void(0); " (click)="changedraws(1) "><img src="assets/images/plus.png " /></a>
                                                        <a href="javascript:void(0); " (click)="changedraws(-1) "><img src="assets/images/minus.png " /></a>
                                                    </div>
                                                </div>
                                                <div class="range ">
                                                    <mv-slider [(value)]="draws " [(min)]="mindraws " [(max)]="playerDetails.user_max_draw ">
                                                    </mv-slider>
                                                </div>
                                            </div>
                                            <div class="tckt-block ">
                                                <h2>tickets</h2>
                                                <div class="amount-plusminus ">
                                                    <input appOnlyNumber="true " type="text " (change)="validatebetdetails( 'regular_ticket');selectedNumbers=[ ]; " [(ngModel)]="playtickets" />
                                                    <div class="plusminus ">
                                                        <a href="javascript:void(0); " (click)="changetickets(1) "><img src="assets/images/plus.png " /></a>
                                                        <a href="javascript:void(0); " (click)="changetickets(-1) "><img src="assets/images/minus.png " /></a>
                                                    </div>
                                                </div>
                                                <div class="range ">
                                                    <mv-slider [(value)]="playtickets " [(min)]="minplaytickets " [(max)]="playerDetails.user_max_ticket " (valueChange)="selectedNumbers=[ ] ">
                                                    </mv-slider>
                                                </div>
                                            </div>
                                            <div class="quickpick_title ">QUICKPICK</div>
                                            <div class="values-plusminus ">
                                                <button class="minus " (click)="changequickpickcount(-1) "> - </button>
                                                <button class="text " (click)="quickpick() "> {{ quickpickNo }} </button>
                                                <button class="plus " (click)="changequickpickcount(1) "> + </button>
                                            </div>
                                            <div class="values_btn ">
                                                <button type="button " (click)="clearvalues()"> clear values </button>
                                                <button (click)="OpenPayout(payout, selectedNumbers.length, wager) ">payouts</button>
                                            </div>
                                            <div class="wager ">
                                                <h2>total wager</h2>
                                                <span>${{totalwager()}}</span>
                                                <div class="wager_btn ">
                                                    <button (click)="createTicket() ">purchase</button>
                                                    <button (click)="openBuyTicket(0) ">close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>

                                <!-- Tabs Page -->
                                <li [ngbNavItem]="2 ">
                                    <a ngbNavLink>Side bet</a>
                                    <ng-template ngbNavContent>
                                        <div class="tckt-block ">
                                            <h2>wager</h2>
                                            <div class="amount-plusminus ">
                                                <input appOnlyNumber="true " type="text " (change)="validatebetdetails( 'side_wager') " [(ngModel)]="swager ">
                                                <div class="plusminus ">
                                                    <a href="javascript:void(0); " (click)="schangewager(1) "><img src="assets/images/plus.png "></a>
                                                    <a href="javascript:void(0); " (click)="schangewager(-1) "><img src="assets/images/minus.png "></a>

                                                </div>
                                                <div class="range ">
                                                    <mv-slider [(value)]="swager " [(min)]="playerDetails.user_min_wager" [(max)]="playerDetails.user_max_wager ">
                                                    </mv-slider>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tckt-block ">
                                            <h2>draws</h2>
                                            <div class="amount-plusminus ">
                                                <input appOnlyNumber="true " type="text " (change)="validatebetdetails( 'side_draw') " [(ngModel)]="sdraws " />
                                                <div class="plusminus ">
                                                    <a href="javascript:void(0); " (click)="schangedraws(1) "><img src="assets/images/plus.png " /></a>
                                                    <a href="javascript:void(0); " (click)="schangedraws(-1) "><img src="assets/images/minus.png " /></a>
                                                </div>
                                            </div>
                                            <div class="range ">
                                                <mv-slider [(value)]="sdraws " [(min)]="smindraws " [(max)]="playerDetails.user_max_draw ">
                                                </mv-slider>
                                            </div>
                                        </div>
                                        <ul class="sidebet-direction ">
                                            <li><a href="javascript:void(0); " (mouseenter)="sideBetnNumberShow($event, 'odd') " (mouseleave)="sideBetnNumberclear($event) " (click)="setSideBet( 'odd') ">odd</a></li>
                                            <li><a href="javascript:void(0); " (mouseenter)="sideBetnNumberShow($event, 'even') " (mouseleave)="sideBetnNumberclear($event) " (click)="setSideBet( 'even') ">even</a></li>
                                            <li><a href="javascript:void(0); " (mouseenter)="sideBetnNumberShow($event, 'north') " (mouseleave)="sideBetnNumberclear($event) " (click)="setSideBet( 'north') ">north</a></li>
                                            <li><a href="javascript:void(0); " (mouseenter)="sideBetnNumberShow($event, 'south') " (mouseleave)="sideBetnNumberclear($event) " (click)="setSideBet( 'south') ">south</a></li>
                                            <li><a href="javascript:void(0); " (mouseenter)="sideBetnNumberShow($event, 'east') " (mouseleave)="sideBetnNumberclear($event) " (click)="setSideBet( 'east') ">east</a></li>
                                            <li><a href="javascript:void(0); " (mouseenter)="sideBetnNumberShow($event, 'west') " (mouseleave)="sideBetnNumberclear($event) " (click)="setSideBet( 'west') ">west</a></li>
                                            <li><a href="javascript:void(0); " (mouseenter)="sideBetnNumberShow($event, 'power') " (mouseleave)="sideBetnNumberclear($event) " (click)="setSideBet( 'power') ">power</a></li>
                                        </ul>
                                        <span *ngIf="sidebettype !='power' " class="choose-sidebet ">{{sidebettype}}</span>
                                        <span *ngIf="sidebettype=='power' && this.selectedSideBetNumbers.length>0 " class="choose-sidebet">{{this.selectedSideBetNumbers}}</span>
                                        <span *ngIf="sidebettype == 'power' && this.selectedSideBetNumbers.length==0 " class="choose-sidebet">CHOOSE A SIDEBET!</span>
                                        <div class="values_btn">
                                            <button type="button" (click)="clearsvalues()"> clear values </button>
                                            <p class="payout-text">payout: $0</p>
                                        </div>
                                        <div class="wager">
                                            <h2>total wager</h2>
                                            <span>{{stotalwager()}}$</span>
                                            <div class="wager_btn">
                                                <button (click)="createSideTicket()">purchase</button>
                                                <button (click)="openBuyTicket(0)">close</button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>

                            <div [ngbNavOutlet]="nav">

                            </div>


                        </div>
                        <div class="selection_sec">
                            <div class="direction">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);" (click)="openBuyTicket(2);" (mouseenter)="sideBetnNumberShow($event,'odd')" (mouseleave)="sideBetnNumberclear($event)">Odd/Even: <span>ODD</span></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" (click)="openBuyTicket(2);" (mouseenter)="sideBetnNumberShow($event,'west')" (mouseleave)="sideBetnNumberclear($event)">East/West: <span>West</span></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" (click)="openBuyTicket(2);" (mouseenter)="sideBetnNumberShow($event,'north')" (mouseleave)="sideBetnNumberclear($event)">North/south: <span>North</span></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" (click)="openBuyTicket(2);" (mouseenter)="sideBetnNumberShow($event,'power')" (mouseleave)="sideBetnNumberclear($event)">power
                      number: <span>{{powernumber}}</span></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="number-sel-sec">
                                <ul class="number-left">
                                    <li class="num-img-left" (mouseenter)="sideBetnNumberShow($event, 'hot') " (mouseleave)="sideBetnNumberclear($event) ">
                                        <img src="assets/images/red_fire.png" />
                                    </li>
                                    <li *ngFor="let hn of hotNumber" (mouseenter)="sideBetnNumberShow($event, 'hot') " (mouseleave)="sideBetnNumberclear($event) "><a href="javascript:void(0);">{{hn}}</a></li>

                                </ul>
                                <ul class="number-right">
                                    <li *ngFor="let cn of coldNumber" (mouseenter)="sideBetnNumberShow($event, 'cold') " (mouseleave)="sideBetnNumberclear($event) "><a href="javascript:void(0);">{{cn}}</a></li>
                                    <li class="num-img-left" (mouseenter)="sideBetnNumberShow($event, 'cold') " (mouseleave)="sideBetnNumberclear($event) ">
                                        <img src="assets/images/blue_fire.png" />
                                    </li>
                                </ul>
                                <ul class="quickpick_number">
                                    <ng-template ngFor let-lsn [ngForOf]="lastsystemSelectNumbers" let-i="index">
                                        <li [ngClass]="{ selected: powernumber == lsn }" (mouseenter)="sideBetnNumberShow($event, 'lastresult') " (mouseleave)="sideBetnNumberclear($event) ">
                                            <a href="javascript:void(0);">{{ lsn }}</a>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>
                            <div class="players-list">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>players online</th>
                                            <td>{{currentuserplaystatus.playerOnline | thousandSuff:2}}</td>
                                        </tr>
                                        <tr>
                                            <th>All live tickets</th>
                                            <td>{{currentuserplaystatus.allLiveticket | thousandSuff:2}}</td>
                                        </tr>
                                        <tr>
                                            <th>All chips wagered</th>
                                            <td>{{currentuserplaystatus.allChipWaged | thousandSuff:2}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                    <div class="chat-sec">
                        <div class="chat-textarea">
                            <textarea autosize [minRows]="5" placeholder="Enter message here!" (click)="showEmojiPicker = false;" #chatmsg [(ngModel)]="message" (keyup.enter)="sendchat();"></textarea>
                            <button (click)="toggleEmojiPicker()" style="position: relative;top: -30px;background: none;width: unset; display: block; float: right; height: 0px;">
                😀</button>
                            <div class="divemoji">
                                <emoji-mart class="emoji-mart" set="{{emojiemoj}}" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…"></emoji-mart>
                            </div>
                            <button (click)="sendchat();">Send message</button>
                        </div>
                        <ul ngbNav #navchat="ngbNav" [(activeId)]="chattype" [destroyOnHide]="false" class="chat-tabs">
                            <li [ngbNavItem]="1" [destroyOnHide]="true" class="chat-item lounge">
                                <a ngbNavLink style="padding:0;">Lounge</a>
                                <ng-template ngbNavContent>
                                    <div class="chat-tab-content">
                                        <div class="chat-tab-pane">
                                            <ng-template ngFor let-chat [ngForOf]="chats" let-i="index">
                                                <div class="chat_box" [ngStyle]="{'background-color': chat.b_bg}">
                                                    <div class="chat_head">
                                                        <span class="chat-title">{{chat.name}}</span>
                                                        <span class="chat-count">{{chat.xp_level}}</span>
                                                    </div>
                                                    <div class="chat_body">
                                                        <div class="left_c">
                                                            <div class="pic" [ngStyle]="{'background-image': 'url('+chat.pic+')'}"><img class="user_flag" src="assets/images/flag/{{chat.country | lowercase}}.png">
                                                            </div>
                                                            <span class="time">{{chat.l}}</span>
                                                        </div>
                                                        <div class="right_c">
                                                            <div class="comment">
                                                                <!-- <p>{{chat.chat_msg}}</p> -->
                                                                <p [innerHTML]="emojiemoj !== '' ? (chat.message | replaceEmojis:'facebook') : chat.chat_msg">
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="chat_foot">
                                                            <ul class="left_chat_btn">
                                                                <li [ngStyle]="{'visibility': chat.user_id==playerDetails.player_id ? 'hidden' : 'visible'}">
                                                                    <a href="javascript:void(0)" (click)="OpenDonate(donatemodal,chat)">donate</a></li>
                                                                <li class="dropdown dropright" *ngIf="playerDetails.type == 1 || chat.user_id != playerDetails.player_id"><a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">options</a>
                                                                    <div class="dropdown-menu">
                                                                        <a class="dropdown-item" href="javascript:void(0);" (click)="ignoreChat(chat.user_id)" *ngIf="chat.user_id != playerDetails.player_id">Ignore User</a>
                                                                        <a class="dropdown-item" href="javascript:void(0);" (click)="deleteChat(chat.chat_id)" *ngIf="playerDetails.type == 1">Delete
                                      Message</a>
                                                                        <a class="dropdown-item" href="javascript:void(0);" (click)="OpenAdmin(adminModal,chat)" *ngIf="playerDetails.type == 1">Admin</a>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <span class="usetype">{{chat.title }}</span>
                                                            <span class="just_now">{{chat.ts}} min ago</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" [destroyOnHide]="true" class="chat-item games">
                                <a ngbNavLink style="padding:0;">Games</a>
                                <ng-template ngbNavContent>
                                    <div class="chat-tab-content">
                                        <div class="chat-tab-pane">
                                            <ng-template ngFor let-chat [ngForOf]="gameChats" let-i="index">
                                                <div class="chat_box" [ngStyle]="{'background': chat.t_bg}">
                                                    <div class="chat_head">
                                                        <span class="chat-title">{{chat.name}}</span>
                                                        <span class="chat-count">{{chat.xp_level}}</span>
                                                    </div>
                                                    <div class="chat_body">
                                                        <div class="left_c">
                                                            <div class="pic" [ngStyle]="{'background-image': 'url('+chat.pic+')'}"><img class="user_flag" src="assets/images/flag/{{chat.country | lowercase}}.png">
                                                            </div>
                                                            <span class="time">{{chat.l}}</span>
                                                        </div>
                                                        <div class="right_c">
                                                            <div class="comment">
                                                                <!-- <p>{{chat.chat_msg}}</p> -->
                                                                <p [innerHTML]="emojiemoj !== '' ? (chat.message | replaceEmojis:'facebook') : chat.chat_msg">
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="chat_foot">
                                                            <ul class="left_chat_btn">
                                                                <li [ngStyle]="{'visibility': chat.user_id==playerDetails.player_id ? 'hidden' : 'visible'}">
                                                                    <a href="javascript:void(0)" (click)="OpenDonate(donatemodal,chat)">donate</a></li>
                                                                <li class="dropdown dropright" *ngIf="playerDetails.type == 1 || chat.user_id != playerDetails.player_id"><a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">options</a>
                                                                    <div class="dropdown-menu">
                                                                        <a class="dropdown-item" href="javascript:void(0);" (click)="ignoreChat(chat.user_id)" *ngIf="chat.user_id != playerDetails.player_id">Ignore User</a>
                                                                        <a class="dropdown-item" href="javascript:void(0);" (click)="deleteChat(chat.chat_id)" *ngIf="playerDetails.type == 1">Delete Message</a>
                                                                        <a class="dropdown-item" href="javascript:void(0);" (click)="OpenAdmin(adminModal,chat)" *ngIf="playerDetails.type == 1">Admin</a>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <span class="usetype">{{chat.title }}</span>
                                                            <span class="just_now">{{chat.ts}} min ago</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>

                        </ul>
                        <div [ngbNavOutlet]="navchat" class="chat-tab-content"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<section [style.display]="isLoadCompleted ? 'none' : 'block'" class="game-canvas">
    <div class="container-game">
        <div class="vital-canvas" style="min-height: 100vh; padding-top: 40vh;">
            <div>
                <div class="col-6 m-auto text-center">
                    <img src="assets/images/KenoLogo.png" class="loader-img">
                </div>
                <div class="col-6 m-auto">
                    <p class="loader-progress">
                        <ngb-progressbar type="warning" [value]="loadPercentage" [striped]="true" [animated]="true">
                        </ngb-progressbar>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- The Modal -->
<ng-template #dailybonusmodal let-modal>
    <div class="modal-inr-content">
        <div class="modal-head">
            <h2>daily bonus added!</h2>
            <h3>Have a good time today!</h3>
        </div>
        <div class="content">
            <div class="bonus-list">
                <div class="bonus-block" *ngFor="let item of dailybonus">
                    <div class="bonus-block-inr" [ngClass]="{'bonus-block-inr-active' : item.day == dailybonusday}">
                        <!-- <h2>day {{item.day}}</h2> -->
                        <h3>
                            <span *ngIf="item.credits != 0">
                                <img src="assets/images/bonus_note.png">{{item.credits | thousandSuff : 1}}
                            </span>
                        </h3>
                        <h4>
                            <span *ngIf="item.points>0">
                                <img src="assets/images/bonus_coin.png">{{item.points | thousandSuff : 1}}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
            <a href="javascript:void(0)" class="ok-btn" (click)="modal.close('Close click')">OK</a>
        </div>
    </div>
</ng-template>

<!-- The Game Stats Modal -->
<ng-template #gamestatsmodal let-modal>	
    <div class="modal-common-header">
        <h4 class="modal-title">STATS</h4>
        <button type="button" class="btn btn-right" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
    <div class="modal-common-body">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#main">main</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#draws" >your recent draws</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#sidebets" >recent sidebets</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#purchases">recent purchase</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#log" >dailies log</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#jackpots" >recent jackpots</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#donations" >donations</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#achievements" >achievements</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#powerups" >Recent PowerUps</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#ignores" >ignores</a>
            </li>
        </ul>
    </div>
    <div class="tab-content">
        <div class="tab-pane container active" id="main">
            <div class="chip_prize_bucks clearfix">
                <div class="chips cp_block">
                    <h2><img src="assets/images/chip_img.png"/>keno chips</h2>
                    <h3>{{ getformat(playerDetails.user_coin) }}</h3>
                    <p>({{playerDetails.user_coin | numbformat }})</p>
                </div>
                <div class="prize_bucks cp_block">
                    <h2><img src="assets/images/prize_bucks_img.png"/>prize bucks</h2>
                    <h3>{{getformat(playerDetails.user_bucks)}}</h3>
                    <p><small>({{playerDetails.user_bucks | numbformat }})</small></p>
                </div>
            </div>
            <ul class="stats-block-list">
                <li>
                    <div class="stats-block">
                        <h2>daily bonus</h2>
                        <span class="count">{{playerDetails.user_daily_bonus | thousandSuff : 2 }}</span>
                    </div>
                </li>
                <li>
                    <div class="stats-block">
                        <h2>max draws</h2>
                        <span class="count">{{playerDetails.user_max_draw | thousandSuff : 2 }}</span>
                    </div>
                </li>
                <li>
                    <div class="stats-block">
                        <h2>max tickets</h2>
                        <span class="count">{{playerDetails.user_max_ticket | thousandSuff : 2 }}</span>
                    </div>
                </li>
                <li>
                    <div class="stats-block">
                        <h2>max wager</h2>
                        <span class="count">{{playerDetails.user_max_wager | thousandSuff : 2 }}</span>
                    </div>
                </li>
                <li>
                    <div class="stats-block">
                        <h2>send limit</h2>
                        <span class="count">{{playerDetails.user_send_limit | thousandSuff : 2 }}</span>
                    </div>
                </li>
                <li>
                    <div class="stats-block">
                        <h2>receive limit</h2>
                        <span class="count">{{playerDetails.user_receive_limit | thousandSuff : 2 }}</span>
                    </div>
                </li>
                <li>
                    <div class="stats-block">
                        <h2>jp multiplier</h2>
                        <span class="count">{{playerDetails.user_jp_multiplier | thousandSuff : 2 }}X</span>
                    </div>
                </li>
            </ul>
            <ul class="stats-block-list">
                <li>
                    <div class="stats-block">
                        <h2>pb multiplier</h2>
                        <span class="count">1X</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="tab-pane container fade" id="draws">
            <div class="stats-table-head">
                <h2>
                    RECENT DRAWS
                </h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th>
                                Draw #
                            </th>
                            <th>
                                Tickets
                            </th>
                            <th>
                                <img src="assets/images/chips_th.png"/> Wagered
                            </th>
                            <th>
                                <img src="assets/images/bonus_note.png"/> Prize Bucks Won
                            </th>
                            <th>
                                <img src="assets/images/chips_th.png"/>Chips Won
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of recentdraws" >
                        <tr [ngStyle]="{'background-color': isEven(item.draw)?'white': '#ebebeb'}">
                            <td>{{item.draw | numbformat}}</td>
                            <td>{{item.tickets | thousandSuff : 2}}</td>
                            <td>{{item.wagered | thousandSuff : 2 }}</td>
                            <td>{{item.prize_bucks_won | thousandSuff : 2 }} </td>
                            <td>{{item.chips_won | thousandSuff : 2 }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="sidebets">
            <div class="stats-table-head">
                <h2>RECENT SIDEBETS</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th>Draw #</th>
                            <th>Type</th>
                            <th>Bet</th>
                            <th>Winning Value</th>
                            <th>Wager</th>
                            <th>Payout</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of recentsidedraws" [ngStyle]="{'background-color': isEven(item.draw_id)?'white': '#ebebeb'}">
                            <td>
                                {{ item.draw_id | numbformat }}
                            </td>
                            <td>
                                {{ item.type }}
                            </td>
                            <td>
                                {{ item.bet }}
                            </td>
                            <td>
                                {{ item.winning_bet }}
                            </td>
                            <td>
                                {{ item.wager | thousandSuff : 2 }}
                            </td>
                            <td>
                                {{ item.payout | thousandSuff : 2 }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="purchases">
            <div class="stats-table-head">
                <h2>RECENT PURCHASES</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th>Wager</th>
                            <th>First Draw</th>
                            <th>Last Draw</th>
                            <th>Total Draws</th>
                            <th>Tickets</th>
                            <th>Numbers</th>
                            <th>Wager p/ Draw</th>
                            <th>Total Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of recentpurchases">
                            <td>{{item.wager}}</td>
                            <td>#{{item.first_draw}}</td>
                            <td>#{{item.last_draw}}</td>
                            <td>{{item.total_draws}}</td>
                            <td>{{item.tickets}}</td>
                            <td>{{item.number}}</td>
                            <td>{{item.wager_p_draw | thousandSuff : 2}}</td>
                            <td>{{item.total_cost | thousandSuff : 2 }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="log">
            <div class="stats-table-head">
                <h2>RECENT DAILIES</h2>
                <h3>Current Base Amount: {{ playerDetails.user_daily_bonus | numbformat }}</h3>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th>Days in a Row</th>
                            <th>Chips</th>
                            <th>Prize Bucks</th>
                            <th>Claimed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dlog of dailieslogs; let indexOfelement=index;">
                            <td>{{dlog.day + 1}}</td>
                            <td>{{dlog.credits | thousandSuff : 2}}</td>
                            <td>{{dlog.points | numbformat }}</td>
                            <td>{{dlog.claimed_ts *1000 | date:'MMM d. HH:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="jackpots">
            <div class="stats-table-head">
                <h2>RECENT JACKPOT</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th>Draw</th>
                            <th>Number</th>
                            <th>Numbers Matched</th>
                            <th>Base Share Amount</th>
                            <th>Multiplier</th>
                            <th>Chips Won</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let jackpot of jackpots" [ngStyle]="{'background-color': isEven(jackpot.draw_id)?'white': '#ebebeb'}">
                            <td><div>{{ jackpot.draw_id | numbformat }}</div></td>
                            <td><div > {{ jackpot['nums'] }} </div></td>
                            <td><div > {{ jackpot['numbers_matched'] / jackpot['numbers_played'] }} </div></td>
                            <td><div> {{ jackpot['original_amount'] }} </div></td>
                            <td><div > {{ jackpot['jp_multiplier'] }} </div></td>
                            <td><div > {{ jackpot['amount'] }} </div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="donations">
            <div class="stats-table-head">
                <h2>RECENT DONATIONS SENT</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th><span class="dp-table-head">Receiver</span></th>
                            <th>Chips Sent</th>
                            <th>Time sent</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dsend of donationresendlist">
                            <td>
                                <div class="dp-table"><img src="https://graph.facebook.com/{{dsend['receiver']}}/picture?access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc"/>{{dsend.full_name}}</div>
                            </td>
                            <td>{{dsend.amount | thousandSuff : 2 }}</td>
                            <td>{{ (dsend.ts + (dsend.timezone * 3600))*1000 | date: 'MMM d, HH:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <div class="stats-table-head">
                <h2>RECENT DONATIONS RECEIVED</h2>
            </div>  
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th><span class="dp-table-head">Sender</span></th>
                            <th>Chips Received</th>
                            <th>Time Sent</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dreceive of donationrereceivedlist">
                            <td>
                                <div class="dp-table"><img src="https://graph.facebook.com/{{dreceive['user_id']}}/picture?access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc"/>{{dreceive.full_name}}</div>
                            </td>
                            <td>{{dreceive.amount}}</td>
                            <td>{{ (dreceive.ts + (dreceive.timezone * 3600))*1000 | date: 'MMM d, HH:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="achievements">
            <div class="stats-table-head">
                <h2>50 MOST RECENT ACHIEVEMENTS</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>level</th>
                            <th>Requirement</th>
                            <th>Chips</th>
                            <th>Diamonds</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let achivement of recentachivement">
                            <td>{{achivement.name.toUpperCase()}}</td>
                            <td>{{achivement.subtype.toUpperCase()}}</td>
                            <td>{{achivement.level}}</td>
                            <td>{{ achivement.requirement_1 | thousandSuff : 2 }}</td>
                            <td>{{ achivement.credits | thousandSuff : 2 }}</td>
                            <td> {{ achivement.diamonds | thousandSuff : 2 }}</td>
                            <td>{{ achivement.ts * 1000 | date : 'MMM d, HH:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="powerups">
            <div class="stats-table-head">
                <h2>50 MOST RECENT POWERUPS</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Diamond Cost</th>
                            <th>Amount Received</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let powerup of recentpowerups">
                            <td>{{powerup.type.toUpperCase()}}</td>
                            <td>{{powerup.diamonds | numbformat }}</td>
                            <td>{{powerup.quantity | numbformat }}</td>
                            <td>{{powerup.ts | date:' MMM d, HH:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="tab-pane container fade" id="ignores">
            <div class="stats-table-head">
                <h2>IGNORED PLAYERS</h2>
            </div>
            <div class="table-responsive">
                <table class="table table-modal">
                    <thead>
                        <tr>
                            <th><span class="dp-table-head">Name</span></th>
                            <th>Time</th>
                            <th>Unignore</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ignor of chatignorlist">
                            <td>
                                <div class="dp-table"><img src="https://graph.facebook.com/{{ignor['ignore_id']}}/picture?access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc"/>{{ ignor.full_name }}</div>
                            </td>
                            <td>
                                {{ignor.ts | date: ' MMM d, HH: mm a'}}
                            </td>
                            <td><a href="javascript:void(0);" class="unignore_btn" (click)="UnignoreChat(ignor.id)">Unignore</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-common-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<!-- The payout  Modal -->
<ng-template #payout let-modal>

    <div class="modal-inr-content">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><img src="assets/images/close_betting.png"></span>
    </button>
        <div class="modal-head">
            <h2>Betting {{selectedpayoutlist.nocount}} Numbers</h2>

        </div>
        <div class="content">
            <div class="payout-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th>MATCHES</th>
                            <th>CHIPS X</th>
                            <th>PRIZE BUCKS X</th>
                            <th>WAGER</th>
                            <th>CHIPS</th>
                            <th>PRIZE BUCKS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of selectedpayoutlist.payoutlist">
                            <td>{{item.matches}}/{{item.pick}}</td>
                            <td>{{item.credits | thousandSuff : 1}}</td>
                            <td>{{item.points | thousandSuff : 1}}</td>
                            <td>{{item.wagers | thousandSuff : 1}}</td>
                            <td>{{item.chips | thousandSuff : 1}}</td>
                            <td>{{item.prizeBuks | thousandSuff : 1}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<!-- The Prize  Modal -->
<ng-template #prizemodal let-modal>
    <div class="modal-common-header">
        <h4 class="modal-title">PRIZES</h4>
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">
      Close
    </button>
    </div>
    <div class="modal-common-body">
        <div class="prizes_head">
            <h2><img src="assets/images/prize_bucks_head_img.png">{{playerDetails.user_bucks | numbformat}}</h2>
        </div>
        <div class="prizes_container">
            <a href="javascript:void(0);" class="btn btn-success back_btn" (click)="BackPrize();" *ngIf="prizeWindowType!=0">BACK</a>
            <ul class="pz_levels levelfirst_list" *ngIf="prizeWindowType==0">
                <li *ngFor="let level of prizelevels" (click)="OpenPrizeCategory(level.stage)">
                    <a href="javascript:void(0);" class="pz_block_shadow" [ngStyle]="{'background-color':level.stage_color}">
                        <div class="prizes_overlay"><img src="assets/images/prizes_overlay_bg.png"></div>
                        <div class="inr">
                            <h2>level {{level.stage}}</h2>
                            <div class="progress-popup">
                                <div class="progress-bar" [ngStyle]="{'width':level.per+'%'}"></div>
                                <span *ngIf="level.per!=100"> {{level.per}}% </span>
                                <span *ngIf="level.per==100"> DONE! </span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
            <ul class="pz_levels levelsecond_list" *ngIf="prizeWindowType==1">
                <li *ngFor="let prizecategory of prizecategorys">
                    <a href="javascript:void(0);" class="pz_block_border" [ngStyle]="{'background-color':prizebackcolor}" (click)="OpenPrizeItems(prizecategory.category)">
                        <div class="prizes_overlay"><img src="assets/images/prizes_overlay_bg.png"></div>
                        <div class="inr">
                            <h2>{{prizecategory.category}}</h2>
                            <img src="assets/images/prizes/items/{{prizecategory.category_picture}}" class="prizes_modal_img">
                            <div class="progress-popup">
                                <div class="progress-bar" [ngStyle]="{'width':prizecategory.per+'%'}"></div>
                                <span *ngIf="prizecategory.per!=100"> {{prizecategory.per}}% </span>
                                <span *ngIf="prizecategory.per==100"> DONE! </span>
                            </div>
                            <h3><img src="assets/images/diamond_powerup_img.png">25,000</h3>
                        </div>
                    </a>
                </li>

            </ul>
            <a href="javascript:void(0);" class="btn btn-info back_btn float-right" (click)="BuyAllPrize(prizeitems);" *ngIf="prizeWindowType==2">Buy All</a>
            <ul class="pz_levels levelthird_list" *ngIf="prizeWindowType==2">
                <li *ngFor="let prizeitem of prizeitems">
                    <a href="javascript:void(0);" class="pz_block_border" [ngStyle]="{'background-color':prizebackcolor , 'opacity': prizeitem.is_purchased == 1 ? 1 : .5 }">
                        <div class="prizes_overlay"><img src="assets/images/prizes_overlay_bg.png"></div>
                        <div class="inr">
                            <h4>{{prizeitem.name}}</h4>
                            <img src="assets/images/prizes/items/{{prizeitem.name_picture}}" class="prizes_modal_big_img">
                            <ul class="prize_cats">
                                <li>Cost: {{prizeitem.cost | thousandSuff}}</li>
                                <li>Dailies: {{prizeitem.dailies | numbformat}}</li>
                                <li>Tickets: {{prizeitem.tickets| numbformat}}</li>
                            </ul>
                            <a *ngIf="prizeitem.is_purchased == 0 " href="javascript:void(0);" class="btn btn_back" (click)="BuyPrize(prizeitem)">BUY</a>
                            <a *ngIf="prizeitem.is_purchased == 1 " href="javascript:void(0);" class="btn btn_back">PURCHASED!</a>
                        </div>
                    </a>
                </li>

            </ul>
            <a href="javascript:void(0);" class="btn btn-success back_btn" (click)="scrollTop('.modal-prize',20, 20);">Top</a>
        </div>

    </div>
    <div class="modal-common-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<!-- The PowerUp  Modal -->
<ng-template #powerUpmodal let-modal>
    <div class="modal-common-header">
        <h4 class="modal-title">powerups</h4>
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">
      Close
    </button>
    </div>
    <div class="modal-common-body">
        <div class="powerup_head">
            <h2><img src="assets/images/powerup/diamond_powerup_img.png">{{playerDetails.user_bucks| numbformat}}</h2>
        </div>
        <div class="powerup_container">
            <h2 class="pw_title">chips powerups</h2>
            <ul class="pw_block_list">
                <li *ngFor="let item of chipspoweruplist;let indexOfelement=index;">
                    <div class="pw_block">
                        <img src="assets/images/powerup/chips_powerup_img.png">
                        <h3>{{item.chips | thousandSuff : 2}}</h3>
                    </div>
                    <a href="javascript:void(0);" (click)="powerupconfirmation(item,powerUpConfirmmodal, indexOfelement+1)" class="pw_block_btn"><img src="assets/images/powerup/diamond_powerup_img.png">{{item.diamonds}}</a>
                </li>
            </ul>
        </div>
        <div class="powerup_container">
            <h2 class="pw_title">misc powerups</h2>
            <ul class="pw_block_list">
                <li>
                    <h2 class="pw_block_title">max wager</h2>
                    <div class="pw_block pw_block_pt">
                        <img src="assets/images/powerup/chips_powerup_img.png">
                    </div>
                    <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">{{powerupselected.max_wager.value |thousandSuff:2}}</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="powerupselect('max_wager',item)" *ngFor="let item of poweruplist | objectFilter : 'max_wager' : ['type'] ">{{item.value | thousandSuff : 2}}
                <small>{{item.cost}} Diamonds</small></a>

                        </div>
                    </div>
                    <a href="javascript:void(0);" (click)="powerupconfirmation(powerupselected.max_wager,powerUpConfirmmodal)" class="pw_block_btn pw_block_small_btn"><img src="assets/images/powerup/diamond_powerup_img.png">{{powerupselected.max_wager.cost | numbformat}}</a>
                </li>
                <li>
                    <h2 class="pw_block_title">max draws</h2>
                    <div class="pw_block pw_block_pt">
                        <img src="assets/images/powerup/chips_powerup_img.png">
                    </div>
                    <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">{{powerupselected.max_draws.value |thousandSuff:2}}</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="powerupselect('max_draws',item)" *ngFor="let item of poweruplist | objectFilter : 'max_draws' : ['type'] ">{{item.value | thousandSuff : 2}}
                <small>{{item.cost}} Diamonds</small></a>

                        </div>
                    </div>
                    <a href="javascript:void(0);" (click)="powerupconfirmation(powerupselected.max_draws,powerUpConfirmmodal)" class="pw_block_btn pw_block_small_btn"><img src="assets/images/powerup/diamond_powerup_img.png">{{powerupselected.max_draws.cost| numbformat}}</a>
                </li>
                <li>
                    <h2 class="pw_block_title">max tickets</h2>
                    <div class="pw_block pw_block_pt">
                        <img src="assets/images/powerup/tickets.png">
                    </div>
                    <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">{{powerupselected.max_tickets.value |thousandSuff:2}}</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="powerupselect('max_tickets',item)" *ngFor="let item of poweruplist | objectFilter : 'max_tickets' : ['type'] ">{{item.value | thousandSuff : 2}}
                <small>{{item.cost}} Diamonds</small></a>
                        </div>
                    </div>
                    <a href="javascript:void(0);" (click)="powerupconfirmation(powerupselected.max_tickets,powerUpConfirmmodal)" class="pw_block_btn pw_block_small_btn"><img src="assets/images/powerup/diamond_powerup_img.png">{{powerupselected.max_tickets.cost| numbformat}}</a>
                </li>
                <li>
                    <h2 class="pw_block_title">donation limit</h2>
                    <div class="pw_block pw_block_pt">
                        <img src="assets/images/powerup/chips_powerup_img.png">
                    </div>
                    <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">{{powerupselected.receive_limit.value |thousandSuff:2}}</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="powerupselect('receive_limit',item)" *ngFor="let item of poweruplist | objectFilter : 'receive_limit' : ['type'] ">{{item.value | thousandSuff : 2}}
                <small>{{item.cost}} Diamonds</small></a>

                        </div>
                    </div>
                    <a href="javascript:void(0);" (click)="powerupconfirmation(powerupselected.receive_limit,powerUpConfirmmodal)" class="pw_block_btn pw_block_small_btn"><img src="assets/images/powerup/diamond_powerup_img.png">{{powerupselected.receive_limit.cost| numbformat}}</a>
                </li>
                <li>
                    <h2 class="pw_block_title">send limit</h2>
                    <div class="pw_block pw_block_pt">
                        <img src="assets/images/powerup/chips_powerup_img.png">
                    </div>
                    <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">{{powerupselected.send_limit.value |thousandSuff:2}}</button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="powerupselect('send_limit',item)" *ngFor="let item of poweruplist | objectFilter : 'send_limit' : ['type'] ">{{item.value | thousandSuff : 2}}
                <small>{{item.cost}} Diamonds</small></a>

                        </div>
                    </div>
                    <a href="javascript:void(0);" (click)="powerupconfirmation(powerupselected.send_limit,powerUpConfirmmodal)" class="pw_block_btn pw_block_small_btn"><img src="assets/images/powerup/diamond_powerup_img.png">{{powerupselected.send_limit.cost| numbformat}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="modal-common-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<!-- The PowerUp Confirm Modal -->
<ng-template #powerUpConfirmmodal let-modal>
    <div class="powerup_inner_modal" *ngFor="let item of powerupconfirmtexts |objectFilter : powerupforbuy.type : ['type'] ">
        <h2>{{item.h2}}</h2>
        <h3>{{item.h3}}</h3>
        <h4>+{{powerupforbuy.value| numbformat}} {{item.h4}}</h4>
        <img src="{{item.img}}" class="chips_logo">
        <h5><img src="assets/images/diamond_powerup_img.png">{{powerupforbuy.cost}}</h5>
        <div class="powerup_confirm_buttons" #powerUpConfirmMsgBox>
            <button class="blue_pcb" (click)="powerupbuy(powerUpConfirmMsgBox,modal)">ok</button>
            <button class="white_pcb" (click)="modal.close('Close click')">cancel</button>
        </div>
    </div>
</ng-template>

<!-- The ACHIEVEMENTS  Modal -->
<ng-template #achimentmodal let-modal>
    <div class="modal-common-header">
        <h4 class="modal-title">ACHIEVEMENTS</h4>
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">
      Close
    </button>
    </div>
    <div class="modal-common-body">
        <div class="achv_container">
            <h2 class="achv_title">misc</h2>
            <ul class="achv_block_list">
                <li>
                    <div class="achv_block">
                        <h2 class="achv_block_title">tickets alltime</h2>
                        <div class="achv_block_inr">
                            <img src="assets/images/dialpad.jpg" class="achv_block_img">
                            <div class="progress-popup">
                                <div class="progress-bar" [ngStyle]="{width: achivemntlist_misc[1].pt+'%'}"></div><span  tooltipClass="my-tooltip" placement="bottom-right"> {{achivemntlist_misc[1].current | thousandSuff :2}}
                  /{{achivemntlist_misc[1].needed | thousandSuff :2 }} </span>
                            </div>
                            <h3>level {{achivemntlist_misc[1].level}}</h3>
                            <h4><img src="assets/images/diamond_powerup_img.png">{{achivemntlist_misc[1].diamonds | thousandSuff : 2}}</h4>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="achv_block">
                        <h2 class="achv_block_title">wager alltime</h2>
                        <div class="achv_block_inr">
                            <img src="assets/images/chips_powerup_img.png" class="achv_block_img">
                            <div class="progress-popup">
                                <div class="progress-bar" [ngStyle]="{width: achivemntlist_misc[2].pt+'%'}"></div><span ngbTooltip="{{achivemntlist_misc[2].current | numbformat}}/{{achivemntlist_misc[2].needed | numbformat}}" tooltipClass="my-tooltip" placement="bottom-right"> {{achivemntlist_misc[2].current | thousandSuff :2}}
                  /{{achivemntlist_misc[2].needed | thousandSuff :2 }} </span>
                            </div>
                            <h3>level {{achivemntlist_misc[2].level}}</h3>
                            <h4><img src="assets/images/diamond_powerup_img.png">{{achivemntlist_misc[2].diamonds | thousandSuff : 2}}</h4>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="achv_container">
            <h2 class="achv_title">matches</h2>
            <ul class="achv_block_list">
                <li *ngFor="let achivement of achivemntlist_matches; let indexOfachivement=index; ">
                    <div class="achv_block">
                        <h2 class="achv_block_title">MATCH {{indexOfachivement+1}}</h2>
                        <div class="achv_block_inr">
                            <img src="assets/images/chips_powerup_img.png" class="achv_block_img">
                            <div class="progress-popup">
                                <div class="progress-bar" [ngStyle]="{width: achivement.pt+'%'}"></div><span ngbTooltip="{{achivement.current | numbformat}}/{{achivement.needed | numbformat}}" tooltipClass="my-tooltip" placement="bottom-right"> {{achivement.current | thousandSuff :2}}
                  /{{achivement.needed | thousandSuff :2 }} </span>
                            </div>
                            <h3>level {{ achivement.level }}</h3>
                            <h4><img src="assets/images/diamond_powerup_img.png">{{achivement.diamonds | thousandSuff : 2}}</h4>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="modal-common-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<!-- The DIAMOND  Modal -->
<ng-template #diamondmodal let-modal>
    <div class="modal-common-header">
        <h4 class="modal-title">DIAMONDS</h4>
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">
      Close
    </button>
    </div>
    <div class="modal-common-body">
        <ul class="diamond_block_list">
            <li>
                <div class="diamond_block">
                    <h2 class="diamond_block_title">PILE OF DIAMONDS</h2>
                    <div class="diamond_block_inr">
                        <img src="assets/images/diamond_modal_img1.png" class="diamond_block_img">
                        <h3>50,000</h3>
                    </div>
                    <a href="javascript:void(0);">$4.99 usd</a>
                </div>
            </li>
            <li>
                <div class="diamond_block">
                    <h2 class="diamond_block_title">CUP OF DIAMONDS</h2>
                    <div class="diamond_block_inr">
                        <img src="assets/images/diamond_modal_img2.png" class="diamond_block_img">
                        <h3>100,000</h3>
                    </div>
                    <a href="javascript:void(0);">$9.99 usd</a>
                </div>
            </li>
            <li>
                <div class="diamond_block">
                    <h2 class="diamond_block_title">BAG OF DIAMONDS</h2>
                    <div class="diamond_block_inr">
                        <img src="assets/images/diamond_modal_img3.png" class="diamond_block_img">
                        <h3>400,000</h3>
                    </div>
                    <a href="javascript:void(0);">$24.99 usd</a>
                </div>
            </li>
            <li>
                <div class="diamond_block">
                    <h2 class="diamond_block_title">SACK OF DIAMONDS</h2>
                    <div class="diamond_block_inr">
                        <img src="assets/images/diamond_modal_img4.png" class="diamond_block_img">
                        <h3>1,600,000</h3>
                    </div>
                    <a href="javascript:void(0);">$49.99 usd</a>
                </div>
            </li>
            <li>
                <div class="diamond_block">
                    <h2 class="diamond_block_title">BOX OF DIAMONDS</h2>
                    <div class="diamond_block_inr">
                        <img src="assets/images/diamond_modal_img5.png" class="diamond_block_img">
                        <h3>5,000,000</h3>
                    </div>
                    <a href="javascript:void(0);">$99.99 usd</a>
                </div>
            </li>
            <li>
                <div class="diamond_block">
                    <h2 class="diamond_block_title">CHEST OF DIAMONDS</h2>
                    <div class="diamond_block_inr">
                        <img src="assets/images/diamond_modal_img6.png" class="diamond_block_img">
                        <h3>16,000,000</h3>
                    </div>
                    <a href="javascript:void(0);">$199.99 usd</a>
                </div>
            </li>
        </ul>
        <p class="sales_final">All sales final.</p>
    </div>
    <div class="modal-common-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

<!-- The Donate  Modal -->
<ng-template #donatemodal let-modal>
    <div class="modal-inr-content">
        <div class="modal-donate-head">
            <h2>Donate</h2>
            <h3>To {{donationDetails.donatePlayer_name}}</h3>
        </div>
        <div class="modal-donate-body">
            <img src="{{donationDetails.donatePlayer_pic}}" class="thumbnail_pic">
            <div class="tckt-block">
                <div class="amount-plusminus">
                    <input appOnlyNumber type="text" [(ngModel)]="donationDetails.donateAmt">
                    <div class="plusminus">
                        <a href="javascript:void(0);" (click)="changeDonation(1)"><img src="assets/images/plus.png"></a>
                        <a href="javascript:void(0);" (click)="changeDonation(-1)"><img src="assets/images/minus.png"></a>
                    </div>
                </div>
                <div class="range">
                    <mv-slider [(value)]="donationDetails.donateAmt" [(min)]="minDonateAmt" [(max)]="maxDonateAmt"></mv-slider>
                </div>
            </div>
            <p *ngIf="donationmodalstage==0">Donate upto $1000 daily!</p>
            <p *ngIf="donationmodalstage==0">Receive upto $1000 daily!</p>
            <p *ngIf="donationmodalstage==1">Sending {{donationDetails.donatePlayer_name}} ${{donationDetails.donateAmt}}!</p>
            <p *ngIf="donationmodalstage==2">Donation Sent!</p>
            <p *ngIf="donationmodalstage==3">PERSON RECIVING COIN HAS MAXOUT HIS LIMIT FOR RECIVING COIN</p>

        </div>
        <div class="modal-donate-foot">
            <button _ngcontent-afy-c115="" class="ng-tns-c115-0" (click)="sendDonation(modal)">SEND FUND</button>
            <button _ngcontent-afy-c115="" class="ng-tns-c115-0" (click)="modal.close('Close click')">close</button>
        </div>
    </div>
</ng-template>

<!-- The Message  Modal -->
<ng-template #msgModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-head">
            <h4 class="modal-title" id="modal-basic-title">{{msgModaltile}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>

        <div class="content">
            <p>{{msgModalbody}}</p>
            <a href="javascript:void(0)" (click)="modal.close('Close click')" class="ok-btn">OK</a>

        </div>
    </div>
</ng-template>

<!-- The Admin  Modal -->
<ng-template #adminModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-head">
            <h2>Admin</h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="content" style="background-color: white; padding: 20px;border: 3px solid #f5f5f5;">
            <div style="display: block; background-color: #f5f5f5;border: 3px solid #f5f5f5;">
                <div style="display: flex; padding: 10px;">
                    <p style="display: block; flex: 1; margin: 0;">
                        <img style="width: 70px;" src="https://graph.facebook.com/{{adminsearchplayerDetails.user_id}}/picture?access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc"/>
                    </p>    
                    <p style="font-size: 40px; font-weight: bold; flex: 2;text-align: left;margin: 0;">{{adminsearchplayerDetails.full_name}}</p>
                </div>
            </div>
            <div>
              <div>
                <p style="margin-top: 20px;">
                    <a href="{{environment.projectBaseURL}}?signed_request={{adminsearchplayerDetails.signed_request}}">Play Keno as {{ adminsearchplayerDetails.first_name }}</a>
                </p>
                <p>(Please be careful...you will be chatting as this person)</p>
              </div>
              <div style="display: flex;">
                <div style="flex: 1;">
                    <table class="table table-bordered table-striped ui-corner-all">
                        <tbody>
                            <tr>
                                <td style="color: black;font-weight: bold;">FB Link:</td>
                                <td><a href="https://www.facebook.com/{{adminsearchplayerDetails.user_id}}">Click for FB Profile</a></td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">User ID:</td>
                                <td>{{adminsearchplayerDetails.user_id}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Player Since:</td>
                                <td>{{adminsearchplayerDetails.ts_created}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Last played:</td>
                                <td>{{adminsearchplayerDetails.last_hit_ts}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">XP Level:</td>
                                <td>{{adminsearchplayerDetails.xp_level}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Chips:</td>
                                <td>{{adminsearchplayerDetails.credits}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Prize Bucks:</td>
                                <td>{{adminsearchplayerDetails.points}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Max Tickets:</td>
                                <td>{{adminsearchplayerDetails.max_tickets}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Max Draws:</td>
                                <td>{{adminsearchplayerDetails.max_draws}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Send Limit:</td>
                                <td>{{adminsearchplayerDetails.send_limit}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Receieve Limit:</td>
                                <td>{{adminsearchplayerDetails.receive_limit}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">Prize Stage:</td>
                                <td>{{adminsearchplayerDetails.prize_stage}}</td>
                            </tr>
                            <tr>
                                <td style="color: black;font-weight: bold;">User Agent:</td>
                                <td>{{ adminsearchplayerDetails.user_agent }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="flex: 0.8; margin-left: 30px;overflow-y: scroll;height: 100vh;">
                    <h2>RECENT CHAT:</h2>
                    <table class="table table-bordered table-striped ui-corner-all">
                        <tbody>
                            <tr *ngFor="let item of adminsearchplayerDetails?.chat">
                                <td><img src="https://graph.facebook.com/{{item.user_id}}/picture?access_token=1554176298146038|Ido_SeRpaBXuGCMhmcq6ONjX4qc"/></td>
                                <td>
                                    <p>{{item.message}}</p>
                                    <P style="font-size: 12px;font-weight: bold;">{{item.ts}}</P>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
            </div>
        </div>
    </div>
</ng-template>


<!-- The Level Up  Modal -->
<ng-template #levelupModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-head">
            <h2 class="modal-title" id="modal-basic-title">level up</h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>

        <div class="content">
            <div class="level_number"><span>{{playerDetails.game_leave}}</span></div>
            <ul>
                <li>Max Draws now at {{playerDetails.user_max_draw}}!</li>
                <li>Max Wager now at ${{playerDetails.user_max_wager}}!</li>
                <li>Max Tickets now at {{playerDetails.user_max_ticket}}!</li>
                <li>Battery Bonus now at ${{playerDetails.user_base_bonus}}!</li>
                <li>Daily Bonus now at ${{playerDetails.user_daily_bonus}}!</li>
            </ul>
            <h3><img src="assets/images/diamond_powerup_img.png">{{this.playerDetails.user_bucks}}
            </h3>
            <a href="javascript:void(0)" (click)="modal.close('Close click')" class="accept-btn">ACCEPT</a>

        </div>
    </div>
</ng-template>

<!-- The Game payout  Modal -->
<ng-template #gamepayoutModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-head">
            <h2 class="modal-title" id="modal-basic-title">You Won!</h2>
        </div>

        <div class="content">
            <h3 *ngIf="gamepayout.coins>0"><img src="assets/images/chips_powerup_img.png">${{gamepayout_getformat(gamepayout.coins)}}
            </h3>
            <h3 *ngIf="gamepayout.bucks>0"><img src="assets/images/prize_bucks_head_img.png">${{gamepayout_getformat(gamepayout.bucks)}}
            </h3>
        </div>
    </div>
</ng-template>
