import { Component, OnInit, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef, ɵConsole } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { tournamentAnimation } from '../_animations/touranimation';
import { KenoapiService } from '../services/kenoapi.service';
import { TournamentapiService } from '../services/tournamentapi.service';
import { FacebooksdkService } from '../services/facebooksdk.service';

import { Globalfunction } from '../common/globalfunction';
import { Sound } from '../common/sound';

import { environment } from '../../environments/environment';

import * as _ from 'lodash';
import * as _Bigint from 'big-integer';

import { EmojiSearch } from '@ctrl/ngx-emoji-mart';

import * as introJs from 'intro.js/intro.js';
import { ThemeService } from 'ng2-charts';


@Component({
  selector: 'app-tournament',
  templateUrl: './tournament.component.html',
  styleUrls: ['./tournament.component.css'],
  animations: [tournamentAnimation],
})
export class TournamentComponent implements OnInit {

  @ViewChild('chatmsg') chatmsg: ElementRef;

  public environment = environment;
  timecounter = 0;


  // Emoji variables
  showEmojiPicker = false;
  emojisets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];
  emojiemoj = 'facebook';
  // END Emoji variables


  // Chat variables
  initialchatCount = 0;
  chatsending = false;
  message = '';
  chats = [];
  lastChatId = 0;
  // END Chat variables

  // Game Initialization
  isLoadCompleted = false;
  loadingp = 0;
  loadPercentage = 0;
  numberset = [];

  totalNumberCount = 80;
  maxSelectableCount = 15;
  minSelectableCount = 1;
  systemSelectCount = 20;
  divNumber = 40;
  quickpickNos = [];
  quickpickNo = 0;
  lastdraw = [];


  // ticket
  isTicketbuying = false;
  ticket = {
    Tournament_id: 0,
    Drawno: 0,
    Numbers: [],
    Wager: 0,
    Draws: 1,
    PlayerId: '',
    Ticket_no: '',
    bucks: null,
    coins: null,
  };
  drawtickets = [];
  prevdrawtickets = [];

  // Facebook login Details
  userObj = {
    player_name: '',
    player_id: '',
    player_picture: '',
  };

  // Player Details
  playerDetails = {
    id: 0,
    player_id: '',
    name: '',
    player_picture: '',
    ip_address: '',
    country_code: '',
    country: '',
    type: 0,
    created_at: '',
    updated_at: '',
    gl_coin_ruby_id: 0,
    game_leave: 1,
    user_coin: 0,
    user_bucks: 0,
    user_ruby: 0,
    user_max_ticket: 1,
    user_daily_bonus: 0,
    user_max_draw: 1,
    user_max_wager: 15,
    user_base_bonus: 0,
    user_receive_limit: 0,
    user_send_limit: 0,
    user_jp_multiplier: 0,
    user_xp: 0,
    xp_needed: 0,
  };

  // tourment
  tourlist = {
    list: [],
    noreg: []
  };
  touruserlist = [];


  playerCurrentTour = {
    playertourid: 0,
    player_count: 0,
    player_rank: 1,
    max_draws: 0,
    prize_pool: 0,
    current_draw: 0,
    virtual_draw_id: 0,
    draw_left: 0,
    tourney_bucks: 0,
    tourney_points: 0,

  };
  selectedtourid = '';

  // Draw
  time = 0;
  DrawRunningStatus = 0;
  currentSelectNumber = 0;
  selectedNumbers = [];
  systemSelectNumbers = [];
  playticketStatus = 0;
  allticketnumber = [];

  // Payout variables
  payoutlist = [];

  statusmsg = '';
  statusmsgset = {
    reg: 'Please standby for the tournament to start.',
    reginprog: 'Registration in progress ...',
    unreg: 'Click Register to join tournament.',
    unreginprog: 'Unregister in progress ...',
  };

  ticketPayout = {
    Numbers: [],
    NumbersMatch: [],
    MatchCount: 0,
    Count: 0,
    Wager: 0,
    Payout: []
  };

  tournamentpayout = [];
  tournamentpayoutlist = [];
  playerstats = [];
  signedRequest = 'pkk5';
  tourney_id = null;
  seats_left = 0;
  constructor(private modalService: NgbModal, private apiService: KenoapiService,
    private fbsdk: FacebooksdkService, private emojiSearch: EmojiSearch,
    public gbf: Globalfunction, private cdr: ChangeDetectorRef,
    private tourapiservice: TournamentapiService, public sound: Sound) {
    this.numberset = _.range(1, this.totalNumberCount + 1, 1);
    this.quickpickNos = _.range(1, 16, 1);
    if (environment.production) {
      fbsdk.init({
        appId: environment.appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v6.0'
      });
      this.fblogin();
    } else {
      this.userObj.player_picture = 'https://graph.facebook.com/102576284705784/picture';
      this.userObj.player_name = 'Daniil Timchenko';
      this.userObj.player_id = '186891609489274';
      this.onStart();
    }
  }

  ngOnInit(): void {

  }


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // facebook login and user
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  fblogin() {
    this.fbsdk.getLoginStatus().then(response => {console.log('fdsafdasd')
      if (response.status == 'connected') {
        // this.loadPercentage = 10;
        this.fbsdk.api('/me?fields=email,name,picture').then(userdata => {
          this.userObj.player_picture = 'https://graph.facebook.com/' + userdata.id + '/picture'; //userdata.picture.data.url;
          this.userObj.player_name = userdata.name;
          this.userObj.player_id = userdata.id;
          this.onStart();
        });
      } else {
        this.fbsdk.login(response).then(logindata => {
          this.fblogin();
        });
      }
    });
  }
  async onStart() {

    this.apiService.getPaulData(this.signedRequest).subscribe(async data => {
        this.payoutlist = data.payouts;
        this.playerDetails.name = data.vitals.first_name;
        this.playerDetails.player_id = data.user_id;
        this.playerDetails.country = '';
        this.playerDetails.country_code = '';
        this.playerDetails.created_at = '';
        this.playerDetails.gl_coin_ruby_id = 4343334;
        this.playerDetails.player_picture = data.vitals.pic;
        this.playerDetails.updated_at = '';
        this.playerDetails.user_base_bonus = 0;
        this.playerDetails.user_daily_bonus = data.vitals.daily_bonus;
        this.playerDetails.user_jp_multiplier = 0;
        this.playerDetails.user_max_draw = data.vitals.max_draws;
        this.playerDetails.user_receive_limit = data.vitals.receive_limit;
        this.playerDetails.user_send_limit = data.vitals.send_limit;
        this.playerDetails.user_xp = data.vitals.xp.xp_current;
        this.playerDetails.xp_needed = data.vitals.xp.xp_needed;
        this.playerDetails.user_coin = data.vitals.currency.credits;
        this.playerDetails.user_bucks = data.vitals.currency.diamonds;
        this.playerDetails.user_ruby = data.vitals.currency.points;
        this.playerDetails.game_leave = data.vitals.xp_level;
        this.gettourmentlist();
        this.lastdrawinit();
        this.backgroundfunction();
        this.isLoadCompleted = true;
        this.fbsdk.setFbCanvasSize(760, 2800);
    })
    this.apiService.checkStatus().subscribe(data => {
      if(data.status) {
        this.tourney_id = data.tourney_id;
        this.seats_left = data.seats_left;
      }
    })
    
  }
  // Background Event
  backgroundfunction() {
    const backgroundinterval = setInterval(() => {
      this.timecounter++;
      if (this.playerCurrentTour.playertourid == 0) {
        if (this.timecounter % 20 == 0) {
          this.gettourmentlist();
        }
      } else if (this.playerCurrentTour.playertourid != 0) {
        if (this.DrawRunningStatus == 1) {
          if (this.time <= 6) {
            this.DrawRunningStatus = 2;
          } else if (this.time > 0) {
            this.time--;
          }
        } else if (this.DrawRunningStatus == 2) {
          if (this.time <= 0) {
            this.DrawRunningStatus = 3;
            this.play();
          } else {
            this.sound.time.play();
            this.time--;
          }
        } else {
          if (this.timecounter % 20 == 0) {
            this.gettourmentlist();
            this.gettouruserlist(this.selectedtourid);
          }
        }
        if (this.timecounter % 10 == 0) {
          this.getNewChat();
        }
      } else {
        if (this.timecounter % 10 == 0) {
          this.gettourmentlist();
        }
      }
    }, 1000);
    if (this.timecounter == 60) {
      this.timecounter = 0;
    }
  }

  OpenStats(content) {
    this.tourapiservice.playerStats({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.playerstats = data.main_data.sort((a, b) => b.tournament_id - a.tournament_id);
        this.modalService.open(content, { windowClass: 'modal-tstats' });
      }
    });
  }

  OpenRules(content) {
    this.modalService.open(content, { windowClass: 'modal-trules' });
  }

  OpenTicketPayout(content, ticket) {
    this.ticketPayout.Numbers = ticket.Numbers;
    if (ticket.NumbersMatch) {
      this.ticketPayout.NumbersMatch = ticket.NumbersMatch;
    } else {
      this.ticketPayout.NumbersMatch = ticket.Numbers.map(x => false);
    }
    this.ticketPayout.MatchCount = this.ticketPayout.NumbersMatch.filter(x => x).length;
    this.ticketPayout.Count = ticket.Numbers.length;
    this.ticketPayout.Wager = ticket.Wager;
    this.ticketPayout.Payout = this.payoutlist.filter(x => x.pick == this.ticketPayout.Count && (x.points > 0 || x.credits > 0)).map(y => {
      return {
        matches: y.matches,
        pb: ticket.Wager * y.points,
        kb: ticket.Wager * y.credits,
      };
    });
    this.modalService.open(content, { windowClass: 'modal-ticket-payout' });
  }

  OpenGamePayout(content) {
    this.tourapiservice.tourpayoutlist({ tournament_id: this.playerCurrentTour.playertourid }).subscribe(data => {
      if (data.sta) {
        this.tournamentpayoutlist = data.main_data;
        this.modalService.open(content, { windowClass: 'modal-game-payout' });
      }
    });
  }

  goToLink(url: string, isNew: boolean) {
    if (isNew) {
      window.open(url, '_blank');
    } else {
      window.open(url, '_self');
    }

  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Style and Animation function
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  SytemSelectNumberStage(num: any): string {
    if ((this.currentSelectNumber == num) && _.includes(this.allticketnumber, num)) {
      return 'SelectMatchNumber';
    } else if (this.currentSelectNumber == num) {
      return 'SelectNumber';
    } else {
      return 'NotSelectNumber';
    }
  }
  numberClass(num: any): string {
    if (_.includes(this.systemSelectNumbers, num) && _.includes(this.allticketnumber, num)) {
      return 'system_selected_match_number';
    } else if (_.includes(this.systemSelectNumbers, num)) {
      return 'system_selected_number';
    } else if (_.includes(this.ticket.Numbers, num)) {
      return 'user_selected_number';
    } else {
      return 'normal_number';
    }
  }
  ticketnumberClass(num: any): string {
    if (_.includes(this.systemSelectNumbers, num)) {
      return 'selected';
    } else {
      return 'normal';
    }
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // End Style and Animation function
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // tourment events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  gettourmentlist() {
    if (this.DrawRunningStatus > 0) {
      return;
    }
    this.tourapiservice.tourlist({ player_id: this.playerDetails.player_id }).subscribe(data => {

        this.tourlist = {
          list: data,
          noreg: []
        };
        this.playerCurrentTour.playertourid = data.active_tornament_id;
        this.playerCurrentTour.max_draws = data.main_max_draws;
        this.playerCurrentTour.player_count = data.main_player_count;
        this.playerCurrentTour.prize_pool = data.main_prize_pool;
        this.gettouruserlist(this.selectedtourid);
        if (data.active_tornament_id > 0) {
          this.starttournament();
          this.settouramentpoints(data.reg_details);
          this.statusmsg = this.statusmsgset.reg;
        }
  
    });
  }

  gettouruserlist(tId: any) {
    if (this.playerCurrentTour.playertourid != 0) {
      tId = this.playerCurrentTour.playertourid;
    }
    if (tId == '') {
      return;
    }
    this.tourapiservice.touruserlist({ tournaments_id: tId }).subscribe(data => {
      if (data.sta) {
        this.touruserlist = data.main_data;
      }
    });
  }
  settourregdereg(tId: any, atype: any) {
    if (tId == '') {
      return;
    }
    if (atype == 'reg') {
      this.statusmsg = this.statusmsgset.reginprog;
    } else {
      this.statusmsg = this.statusmsgset.unreginprog;
    }
    this.tourapiservice.tourregdereg({ tournaments_id: tId, player_id: this.playerDetails.player_id, reg_type: atype }).subscribe(data => {
      if (data.sta) {
        if (atype == 'reg') {
          this.playerCurrentTour.playertourid = data.main_tournament_id;
          this.gettouruserlist(0);
          this.statusmsg = data.message;
          this.settouramentpoints(data.reg_details);
          setTimeout(() => {
            this.statusmsg = this.statusmsgset.reg;
          },
            2000);
        } else {
          this.statusmsg = data.message;
          this.playerCurrentTour.playertourid = 0;
          this.touruserlist = [];
          setTimeout(() => {
            this.statusmsg = this.statusmsgset.unreg;
          },
            2000);
        }
      }
    });
  }
  starttournament() {
    if (this.DrawRunningStatus == 0) {
      this.tourapiservice.tournamentisstarted({
        player_id: this.playerDetails.player_id,
        tournament_id: this.playerCurrentTour.playertourid
      }).subscribe(data => {
        if (data.sta) {
          this.getAllChat();
          this.gettournamentStatus();
        }
      });
    }
  }
  gettournamentStatus() {
    this.tourapiservice.tournamentStatus({
      player_id: this.playerDetails.player_id,
      tournament_id: this.playerCurrentTour.playertourid
    }).subscribe(data => {
      if (data.sta) {
        this.time = data.time_intervel;
        this.playerCurrentTour.current_draw = data.main_data[0].tournament_draw_id;
        this.playerCurrentTour.virtual_draw_id = data.main_data[0].virtual_draw_id;
        this.playerCurrentTour.draw_left = data.draw_left;
        this.settouramentpoints(data.reg_details);
        if (data.previous_draw_details.length > 0) {
          this.lastdraw = data.previous_draw_details[0].number.split(', ', 20).sort((a, b) => a - b).map(y => this.gbf.toint(y));
        }
        this.drawtickets = data.current_draw_p_details.map(x => {
          return {
            Tournament_id: x.tournament_id,
            Numbers: x.game_numbers.split(', ', 20).sort((a, b) => a - b).map(y => this.gbf.toint(y)),
            Drawno: x.virtual_draw_id,
            Wager: x.wager,
            Draws: x.draws,
            PlayerId: x.player_id,
            Ticket_no: x.ticket_no,
            bucks: x.payout_amount_b,
            coins: x.payout_amount_c,
          };
        });
        this.prevdrawtickets = data.previous_draw_p_details.map(x => {
          const sysselnum = x.computer_number.split(', ', 20).sort((a, b) => a - b).map(y => this.gbf.toint(y));
          return {
            Tournament_id: x.tournament_id,
            Numbers: x.game_numbers.split(', ', 20).sort((a, b) => a - b).map(y => this.gbf.toint(y)),
            NumbersMatch: x.game_numbers.split(', ', 20).sort((a, b) => a - b).map(y => _.includes(sysselnum, this.gbf.toint(y))),
            Drawno: x.virtual_draw_id,
            Wager: x.wager,
            Draws: x.draws,
            PlayerId: x.player_id,
            Ticket_no: x.ticket_no,
            bucks: x.payout_amount_b,
            coins: x.payout_amount_c,
          };
        });
        this.DrawRunningStatus = 1;
      } else {
        this.DrawRunningStatus = 4;
        return;
      }
    });
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // End tourment events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // bet events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  changewager(chng: number) {
    this.ticket.Wager = this.gbf.toint(this.ticket.Wager);
    if (this.ticket.Wager + chng >= 0) {
      this.ticket.Wager = this.ticket.Wager + chng;
    }
  }
  changedraws(chng: number) {
    this.ticket.Draws = this.gbf.toint(this.ticket.Draws);
    if (this.ticket.Draws + chng >= 1 && this.ticket.Draws + chng <= this.playerCurrentTour.draw_left + 1) {
      this.ticket.Draws = this.ticket.Draws + chng;
    }
  }
  quickpick(num) {
    this.quickpickNo = num;
    this.sound.numberSelect.play();
    this.ticket.Numbers = _.sampleSize(this.numberset, num);
  }
  chooseNumber(num: any) {
    if (this.DrawRunningStatus != 1) {
      return;
    } else {

      if (_.includes(this.ticket.Numbers, num)) {
        this.sound.numberSelect.play();
        this.ticket.Numbers = this.ticket.Numbers.filter(x => x != num);
      } else {
        if (this.ticket.Numbers.length >= this.maxSelectableCount) {
          return;
        }
        this.sound.numberSelect.play();
        this.ticket.Numbers.push(num);
      }
    }
  }
  clearbet() {
    this.ticket.Wager = 0;
    this.ticket.Draws = 1;
    this.quickpickNo = 0;
  }
  createTicket() {
    if (this.isTicketbuying || this.ticket.Wager <= 0 || this.ticket.Draws <= 0 || this.ticket.Numbers.length <= 0) {
      return;
    }
    this.isTicketbuying = true;
    this.ticket.Tournament_id = this.playerCurrentTour.playertourid;
    this.ticket.PlayerId = this.playerDetails.player_id;
    this.ticket.Drawno = this.playerCurrentTour.current_draw;
    let _t = JSON.parse(JSON.stringify(this.ticket));
    this.tourapiservice.buyticket(_t).subscribe(data => {
      if (data.sta) {
        _t.Ticket_no = data.main_data.ticket_no[0];
        _t.Drawno = this.playerCurrentTour.virtual_draw_id;
        this.drawtickets.push(_t);
        this.sound.buyticket.play();
        this.ticket.Numbers = [];
        this.quickpickNo = 0;
        this.settouramentpoints(data.main_data.reg_details);
      }
      this.isTicketbuying = false;
    });



  }
  reSelect(Numbers: []) {
    if (this.time > 5) {
      this.ticket.Numbers = Numbers;
    }
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // end bet events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // draw events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  async play() {
    this.clearbet();
    this.selectedNumbers = [];
    let snumber = [];
    // snumber = _.sampleSize(this.numberset, 20);
    const gameDetails = await this.tourapiservice.drawresult({
      player_id: this.playerDetails.player_id,
      tournament_draw_id: this.playerCurrentTour.current_draw,
      tournament_id: this.playerCurrentTour.playertourid
    }).toPromise();
    if (gameDetails.sta) {
      snumber = gameDetails.tournament_data[0].number.split(', ', 20);
      this.allticketnumber = [];
      this.drawtickets = gameDetails.main_data.map(x => {
        this.allticketnumber = _.unionBy(x.game_numbers.split(', ', 20).sort((a, b) => a - b).map(y => this.gbf.toint(y)), this.allticketnumber);
        return {
          Tournament_id: x.tournament_id,
          Numbers: x.game_numbers.split(', ', 20).sort((a, b) => a - b).map(y => this.gbf.toint(y)),
          Drawno: x.tournament_draw_id,
          Wager: x.wager,
          Draws: x.draws,
          PlayerId: x.player_id,
          Ticket_no: x.ticket_no,
          bucks: x.payout_amount_b,
          coins: x.payout_amount_c,
        };
      });
    } else {
      return;
    }
    if (snumber.length != this.systemSelectCount) {
      this.play();
      return;
    }
    this.systemSelectNumbers = [];
    let i = -1;
    this.playticketStatus = 1;
    const localinterval = setInterval(() => {
      if (i < 0) {

      } else if (i > this.systemSelectCount + 1) {
        clearInterval(localinterval);
        setTimeout(() => {
          this.clearbet();
          this.playticketStatus = 0;
          this.lastdraw = this.systemSelectNumbers.concat().sort((a, b) => a - b);
          this.systemSelectNumbers = [];
          this.settouramentpoints(gameDetails.reg_details);
          this.tourapiservice.tourresult({ tournament_id: this.playerCurrentTour.playertourid }).subscribe(data => {
            if (data.sta) {
              this.gettournamentStatus();
              this.tournamentpayout = data.main_data;
            }
          });
        },
          2500);
      } else if (i < this.systemSelectCount) {
        this.currentSelectNumber = snumber[i];
        this.systemSelectNumbers.push(this.gbf.toint(snumber[i]));
        if (_.includes(this.allticketnumber, this.gbf.toint(this.currentSelectNumber))) {
          setTimeout(() => {
            this.sound.systemnumberSelectMatch.play();
          }, 500);
        } else {
          setTimeout(() => {
            this.sound.systemnumberSelect.play();
          }, 500);
        }
      } else {
        this.playticketStatus = 2;
        this.currentSelectNumber = 0;

      }
      i++;
    }, 1500);
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // end draw events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // chat events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event) {
    this.showEmojiPicker = false;
    // const { message } = this;
    // const text = `${message}${event.emoji.native}`;
    const startPos = this.chatmsg.nativeElement.selectionStart;
    this.chatmsg.nativeElement.focus();
    this.chatmsg.nativeElement.value = this.chatmsg.nativeElement.value.substr(0, this.chatmsg.nativeElement.selectionStart)
      + event.emoji.native + this.chatmsg.nativeElement.value.substr(this.chatmsg.nativeElement.selectionStart, this.chatmsg.nativeElement.value.length);
    this.chatmsg.nativeElement.selectionStart = startPos + event.emoji.native.length;
    this.chatmsg.nativeElement.selectionEnd = startPos + event.emoji.native.length;
    this.chatmsg.nativeElement.focus();
    console.log(event.emoji.native.length);

    // this.message = this.message + event.emoji.native;
  }
  sendchat() {
    if (this.chatsending) {
      return;
    }

    this.chatsending = true;
    this.showEmojiPicker = false;
    this.message = this.chatmsg.nativeElement.value;
    const msg = this.gbf.replaceemoji(this.message.trim());
    this.message = '';
    if (msg.length > 0) {
      const _chat = {
        chat_type: this.playerCurrentTour.playertourid,
        player_id: this.playerDetails.player_id,
        message: msg
      };
      this.apiService.createchat(this.signedRequest, _chat).subscribe(data => {
        this.getNewChat();
        this.chatsending = false;
      });
    }

  }
  getAllChat() {
    if (this.playerCurrentTour.playertourid == 0) {
      return;
    }
    let _chat = {
      lastchatid: 0,
      type: this.playerCurrentTour.playertourid,
      count: this.initialchatCount,
      player_id: this.playerDetails.player_id,
      signed_request: this.signedRequest,
test: 1
    };

    this.apiService.getchat(_chat).subscribe(data => {
      this.chats = data.data;
      if (this.chats.length > 0) {
        this.lastChatId = this.chats[0].chat_id;
      }
    });
  }
  getNewChat() {
    let _chat = {
      lastchatid: this.lastChatId,
      type: this.playerCurrentTour.playertourid,
      count: 0,
      player_id: this.playerDetails.player_id,
      signed_request: this.signedRequest
    };
    this.apiService.getchat(_chat).subscribe(data => {
      this.chats = _.unionBy(data.data, this.chats, 'chat_id');
      if (this.chats.length > 0) {
        this.lastChatId = this.chats[0].chat_id;
      }
    });
  }
  ignoreChat(ignoreId) {
    const _ignore = {
      player_id: this.playerDetails.player_id,
      player_id_ignor: ignoreId
    };
    this.apiService.setchatignore('4564563',_ignore).subscribe(data => {
      if (data.sta) {
        this.chats = this.chats.filter(x => x.player_id != ignoreId);
      }
    });
  }
  deleteChat(chatId) {
    const _delete = {
      player_id: this.playerDetails.player_id,
      chat_id: chatId
    };

    this.apiService.deletechat('1223213', _delete).subscribe(data => {
      if (data.sta) {
        this.chats = this.chats.filter(x => x.chat_id != chatId);
      }
    });
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // chat end
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // functions
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  lastdrawinit() {
    this.lastdraw = [];
    let i = 0;
    while (i < this.systemSelectCount) {
      this.lastdraw.push('?');
      i++;
    }
  }

  timeformat(time) {
    // Hours, minutes and seconds
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';
    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
  }
  settouramentpoints(data: any[]) {
    if (data.length > 0) {
      this.playerCurrentTour.tourney_bucks = data[0].tourney_bucks;
      this.playerCurrentTour.tourney_points = data[0].tourney_points;
      this.playerCurrentTour.player_rank = data[0].player_rank;
    }
  }
  backtolobby() {
    this.gettourmentlist();
    this.DrawRunningStatus = 0;
    this.playerCurrentTour.playertourid = 0;
    this.touruserlist = [];
    this.prevdrawtickets = [];
    this.drawtickets = [];
    this.selectedtourid = '';

  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  end functions
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
}
