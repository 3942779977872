import { trigger, state, style, group, animate, transition, keyframes, useAnimation } from '@angular/animations';
import { bounce } from 'ng-animate';

export const gameAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  [trigger('NumberStyle', [
    // ...
    state('normal_number', style({
      background: 'url(assets/images/number_bg.png) no-repeat',
      'background-size': 'cover'
    })),
    state('system_selected_number', style({
      background: 'url(assets/images/red_number_bg.png) no-repeat',
      'background-size': 'cover'
    })),
    state('system_selected_power_number', style({
      background: 'url(assets/images/green_number_bg.png) no-repeat',
      'background-size': 'cover'
    })),
    state('user_selected_number', style({
      background: 'url(assets/images/red_number_bg.png) no-repeat',
      'background-size': 'cover'
    })),
    state('side_bet_number', style({
      background: 'url(assets/images/pink_number_bg.png) no-repeat',
      'background-size': 'cover'
    })),
    state('side_bet_powernumber', style({
      background: 'url(assets/images/green_number_bg.png) no-repeat',
      'background-size': 'cover'
    })),
    transition('user_selected_number => normal_number', [
      animate('0s')
    ]),
    transition('normal_number => user_selected_number', [
      group([
        animate('450ms ease-in', style({ background: 'url(assets/images/number_bg.png) no-repeat', 'background-size': 'cover', })),
        animate('451ms ease-in', style({ background: 'url(assets/images/red_number_bg.png) no-repeat', 'background-size': 'cover', 'transition-delay': '450ms' })),
      ])
    ]),
    transition('normal_number => system_selected_number',
      [
        group([
          animate('500ms 3000ms ease-in', style({ background: 'url(assets/images/red_number_bg.png) no-repeat', 'background-size': 'cover' })),
        ])

      ]),
    transition('system_selected_number => normal_number', [
      animate('0s')
    ]),
    transition('normal_number => system_selected_power_number',
      [
        group([
          animate('500ms 3000ms ease-in', style({ background: 'url(assets/images/green_number_bg.png) no-repeat', 'background-size': 'cover' })),
        ])
      ]),
    transition('system_selected_power_number => normal_number', [
      animate('0s')
    ]),
    transition('side_bet_number => normal_number', [
      animate('200ms')
    ]),
    transition('normal_number => side_bet_number', [
      animate('200ms')
    ]),
    transition('side_bet_powernumber => normal_number', [
      animate('200ms')
    ]),
    transition('normal_number => side_bet_powernumber', [
      animate('200ms')
    ]),
  ]),
  trigger('BetBallShowHide', [
    state('SelectNumber', style({
      opacity: 1,
      transform: 'scale(1,1)'
    })),
    state('PowerSelectNumber', style({
      opacity: 1,
      transform: 'scale(1,1)',
      background: 'url(assets/images/ball_green.png) no-repeat',
      'background-size': 'cover',
    })),
    state('NotSelectNumber', style({
      opacity: 0,
      transform: 'scale(0,0)'
    })),

    transition('NotSelectNumber => SelectNumber', [
      group([
        animate('1500ms', style({ opacity: 0, transform: 'scale(0,0)', })),
        animate('2000ms ease-in', style({ opacity: 1, transform: 'scale(1,1) rotate(1440deg)', 'transition-delay': '1500ms' })),
      ])
    ]),
    transition('SelectNumber => NotSelectNumber', [
      group([
        animate('500ms ease-in', style({ opacity: 0, transform: 'scale(0,0) rotate(1080deg)', 'transition-delay': '300ms' })),
      ])
    ]),

    transition('NotSelectNumber => PowerSelectNumber', [
      group([
        animate('1500ms', style({ opacity: 0, transform: 'scale(0,0)', })),
        animate('2000ms ease-in', style({ opacity: 1, transform: 'scale(1,1) rotate(1440deg)', 'transition-delay': '1500ms' })),
      ])
    ]),
    transition('PowerSelectNumber => NotSelectNumber', [
      animate('300ms', style({ background: 'url(assets/images/ball_green.png) no-repeat', 'background-size': 'cover' })),
      animate('500ms ease-in', style({ opacity: 0, transform: 'scale(0,0) rotate(1080deg)','transition-delay': '300ms' })),
    ]),

  ]),
  trigger('slideInOut', [
    state('in', style({
      'max-height': '600px', opacity: '1', visibility: 'visible'
    })),
    state('out', style({
      'max-height': '0px', opacity: '0', visibility: 'hidden'
    })),
    transition('in => out', [group([
      animate('700ms ease-in-out', style({
        'max-height': '0px',
        opacity: '0'
      })),
      animate('900ms ease-in-out', style({
        visibility: 'hidden'
      }))
    ]
    )]),
    transition('out => in', [group([
      animate('1ms ease-in-out', style({
        visibility: 'visible'
      })),
      animate('600ms ease-in-out', style({
        'max-height': '600px'
      })),
      animate('800ms ease-in-out', style({
        opacity: '1'
      }))
    ]
    )])
  ]),
  trigger('slideOpenClose', [
    state('Open', style({
      'max-height': '82px', opacity: '1', visibility: 'visible'
    })),
    state('Close', style({
      'max-height': '0px', opacity: '0', visibility: 'hidden'
    })),
    transition('Open => Close', [group([
      animate('700ms ease-in-out', style({
        'max-height': '0px',
        opacity: '0'
      })),
      animate('900ms ease-in-out', style({
        visibility: 'hidden'
      }))
    ]
    )]),
    transition('Close => Open', [group([
      animate('1ms ease-in-out', style({
        visibility: 'visible'
      })),
      animate('600ms ease-in-out', style({
        'max-height': '82px'
      })),
      animate('800ms ease-in-out', style({
        opacity: '1'
      }))
    ]
    )])
  ]),
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *',
      group([

        animate('500ms 3000ms ease-in', style({ opacity: 1 })),
      ])
    ),
  ]),
  trigger('donation', [
    transition('void <=> *',
      useAnimation(bounce, {
        params: { timing: 2, delay: 2 }
      })
    ),
  ]),
  trigger('ticketballmatch', [
    state('normal', style({
      background: '#fff'
    })),
    state('selected', style({
      background: '#ff0000'
    })),
    state('power', style({
      background: '#01d61b'
    })),
    transition('normal => selected', [
      animate('500ms 3000ms ease-in')
    ]),
    transition('normal => power', [
      animate('500ms 3000ms ease-in')
    ]),
    transition('power => normal', [
      animate('0s')
    ]),
    transition('selected => normal', [
      animate('0s')
    ]),
  ]),
  trigger('sidebetmatch', [
    state('normal', style({
      background: '#fff'
    })),
    state('selected', style({
      background: '#ff0000'
    })),
    transition('normal => selected', [
      animate('500ms 3000ms ease-in')
    ]),
    transition('selected => normal', [
      animate('0s')
    ]),
  ]),
  ];
