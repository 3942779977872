import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Globalfunction {

  public replaceemoji(text) {
    const emoticons = {
      S2: '❤️',
      s2: '❤️',
      '<3': '❤️',
      ':*': '💋',
      ':)': '🙂',
      '(Y)': '👍',
      '>:)': '😆',
      '>:D': '😈',
      ':D': '😄',
      XD: '😆',
      ';)': '😉',
      '>:(': '😠',
      ':(': '😟',
      ':|': '😐',
      ':P': '😛',
      ':p': '😛',
      ':O': '😮',
      ':S': '😕',
      ':\'(': '😭',
      'B)': '😎',
      ':")': '👹',
      '>-)': '👽',
      '9_9': '🤓',
      o_O: '😵',
      O_o: '🥴',
      O_O: '🥴',
      '(N)': '👎',
      '(clap)': '👏',
      '(d)': '🥚'
    };
    const chars = [];
    const icons = [];

    for (const t in emoticons) {
      chars.push(t);
      icons.push(emoticons[t]);
    }

    const newText = (this.str_replace(chars, icons, text, 0));
    return newText;
  }

  public str_replace(search, replace, subject, count) {
    let wordcount = 0;
    let i = 0,
      j = 0,
      temp = '',
      repl = '',
      sl = 0,
      fl = 0,
      f = [].concat(search),
      r = [].concat(replace),
      s = subject,
      ra = Object.prototype.toString.call(r) === '[object Array]',
      sa = Object.prototype.toString.call(s) === '[object Array]';
    s = [].concat(s);
    if (count) {
      wordcount = 0;
    }

    for (i = 0, sl = s.length; i < sl; i++) {
      if (s[i] === '') {
        continue;
      }
      for (j = 0, fl = f.length; j < fl; j++) {
        temp = s[i] + '';
        repl = ra ? (r[j] !== undefined ? r[j] : '') : r[0];
        s[i] = (temp).split(f[j]).join(repl);
        if (count && s[i] !== temp) {
          wordcount += (temp.length - s[i].length) / f[j].length;
        }
      }
    }
    return sa ? s : s[0];
  }

  toint(num: any) {
    let result = 0;
    if (num) {
      result= parseInt(num.toString().trim(), 10);
    }
    return result;
  }
}
