import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numbformat',
  pure: false
})
export class NumbformatPipe implements PipeTransform {

  transform(value: any): any {
   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
