<section class="game-canvas" [style.display]="isLoadCompleted ? 'block' : 'none'" class="game-canvas">
    <div class="container-game">
        <div class="tournament-vital-canvas">
            <div class="tournament-head">
                <ul>
                    <li (click)="goToLink(environment.projectBaseURL,true)">
                        <i><img src="assets/images/tournament_top_img1.png"></i>
                        <span>Play</span>
                    </li>
                    <li (click)="OpenStats(statsModal)">
                        <i><img src="assets/images/tournament_top_img2.png"></i>
                        <span>Stats</span>
                    </li>
                    <li (click)="goToLink('https://facebook.com/funkeno',true)">
                        <i><img src="assets/images/tournament_top_img3.png"></i>
                        <span>Forum</span>
                    </li>
                    <li (click)="OpenRules(rulesModal)">
                        <i><img src="assets/images/tournament_top_img4.png"></i>
                        <span>Rules</span>
                    </li>
                </ul>
            </div>
            <div class="tournament-welcome">
                <img src="assets/images/welcome_cross.png" class="welcome_cross">
                <h2>Welcome Back to Keno tournaments...</h2>
            </div>
            <div class="tournament_keno_prize">
                <img src="assets/images/tournament_note_coin.png" class="tournament_note_coin">
                <img src="assets/images/tournament_arrow.png" class="tournament_arrow">
                <div class="tour_keno" *ngIf="playerCurrentTour.playertourid==0">
                    <h2>{{playerDetails.user_bucks | numbformat}}</h2>
                    <p>Keno Bucks</p>
                </div>
                <div class="tour_keno" *ngIf="playerCurrentTour.playertourid!=0">
                    <h2>{{playerCurrentTour.tourney_bucks | numbformat}}</h2>
                    <p>Tourney Bucks</p>
                </div>
                <div class="tour_prize" *ngIf="playerCurrentTour.playertourid==0">
                    <h2>{{playerDetails.user_coin | numbformat}}</h2>
                    <p>Prize Bucks</p>
                </div>
                <div class="tour_prize" *ngIf="playerCurrentTour.playertourid!=0">
                    <h2>{{playerCurrentTour.tourney_points | numbformat}}</h2>
                    <p>Tourney Points</p>
                </div>
            </div>
            <div class="tournament-separator"></div>
            <div class="tournament-player-table" *ngIf="DrawRunningStatus==0">
                <div class="tournament-pt-head">
                    <h2><img src="assets/images/tournament_top_img1.png">Tournaments</h2>
                </div>
                <div class="row tournament-pt-body">
                    <div class="col-7 pr-1 tournament-table">
                        <div class="tournament-outer-table">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Buy In</th>
                                        <th>Max Draws</th>
                                        <th>Max Players</th>
                                        <th>Register</th>
                                        <th>Prize Pool</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr [ngClass]="{ 'active' : playerCurrentTour.playertourid == item.tournament_id }" *ngFor="let item of tourlist.list;let i=index;" (click)="selectedtourid = item.tournament_id;gettouruserlist(item.tournament_id)">
                                        <td>
                                            <div>{{item.tournament_id}}</div>
                                        </td>
                                        <td>
                                            <div>{{item.buyin | thousandSuff }} + {{item.buyin/10 | thousandSuff }}</div>
                                        </td>
                                        <td>
                                            <div>{{item.max_draws}}</div>
                                        </td>
                                        <td>
                                            <div>{{item.max_players}}</div>
                                        </td>
                                        <td>
                                            <div>{{tourlist.noreg[item.tournament_id]}}</div>
                                        </td>
                                        <td>
                                            <div>{{item.prize_pool | thousandSuff : 2}}</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="tournament-register">
                            <span class="statusmsg">{{statusmsg}}</span>
                            <a href="javascript:void(0);" *ngIf="playerCurrentTour.playertourid==0" (click)="settourregdereg(selectedtourid,'reg')">Register</a>
                            <a href="javascript:void(0);" *ngIf="playerCurrentTour.playertourid!=0" (click)="settourregdereg(playerCurrentTour.playertourid,'unreg')">Un-Register</a>
                        </div>
                    </div>
                    <div class="col-5 pl-1 tournament-player">
                        <div class="tournament-outer-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="player-th">Player</th>
                                        <th class="name-th">Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of touruserlist">
                                        <td class="player-td">
                                            <div><img src="{{item.player_picture}}"></div>
                                        </td>
                                        <td class="name-td">
                                            <div>{{item.name}}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tournament-quickpick" *ngIf="DrawRunningStatus>0">
                <div class="row m-0">
                    <div class="col-4 p-0">
                        <div class="bet-draws-sec">
                            <a href="javascript:void(0);" (click)="sound.mute()" *ngIf="sound.soundMute" class="sound_off">turn sound
                On</a>
                            <a href="javascript:void(0);" (click)="sound.mute()" *ngIf="!sound.soundMute" class="sound_off">turn sound
                off </a>
                            <div class="bet-draw-logo" *ngIf="DrawRunningStatus>1">
                                <div class="keno_logo_big transform-middle">
                                    <img src="assets/images/keno_logo_big.png">
                                </div>
                            </div>
                            <div class="bet-draw-all" *ngIf="DrawRunningStatus==1">
                                <div class="keno_logo_big">
                                    <img src="assets/images/keno_logo_big.png">
                                </div>
                                <div class="select-number">
                                    <img src="assets/images/select_number_arrow.png" class="select_number_arrow">
                                    <span>select your number</span>
                                </div>
                                <div class="bet-drw-select">
                                    <div class="row m-0">
                                        <div class="col-7 p-0">
                                            <div class="bd_slct_div">
                                                <h2>bet</h2>
                                                <div class="bd_select">
                                                    <input appOnlyNumber="true" type="text" [(ngModel)]="ticket.Wager">
                                                    <div class="up_down_arrow">
                                                        <button class="up_arrow" (click)="changewager(1)"><i class="fas fa-caret-up"></i></button>
                                                        <button class="down_arrow" (click)="changewager(-1)"><i
                                class="fas fa-caret-down"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5 p-0">
                                            <div class="bd_slct_div">
                                                <h2>draws</h2>
                                                <div class="bd_select">
                                                    <input appOnlyNumber="true" type="text" [(ngModel)]="ticket.Draws" readonly>
                                                    <div class="up_down_arrow">
                                                        <button class="up_arrow" (click)="changedraws(1)"><i class="fas fa-caret-up"></i></button>
                                                        <button class="down_arrow" (click)="changedraws(-1)"><i
                                class="fas fa-caret-down"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bet-number-clear">
                                    <ul>
                                        <li (click)="changewager(5)"><a href="javascript:void(0);">+5</a></li>
                                        <li (click)="changewager(25)"><a href="javascript:void(0);">+25</a></li>
                                        <li (click)="changewager(100)"><a href="javascript:void(0);">+100</a></li>
                                        <li (click)="changewager(500)"><a href="javascript:void(0);">+500</a></li>
                                        <li (click)="changewager(1000)"><a href="javascript:void(0);">+1000</a></li>
                                        <li (click)="clearbet()"><a href="javascript:void(0);">CLEAR</a></li>
                                    </ul>
                                </div>
                                <div class="bet-number-count">
                                    <span>{{ticket.Wager}}</span>
                                </div>
                                <div class="buy_ticket_btn_outer">
                                    <button class="buy_ticket_btn" (click)="createTicket()">BUY TICKET</button>
                                </div>
                                <div class="buy_ticket_number_list">
                                    <ul *ngIf="ticket.Numbers.length>0">
                                        <ng-template ngFor let-pout [ngForOf]="payoutlist | objectFilter : ticket.Numbers.length.toString() : ['pick'] " let-i="index">
                                            <li *ngIf="pout.credits>0 && pout.points >0"><span class="number">{{pout.matches}}</span><span class="arrow"><img src="assets/images/ticket_arrow_right.png"></span><span class="ticket">x{{pout.points | numbformat}} PB - x{{pout.credits | numbformat}} kb</span>
                                            </li>
                                            <li *ngIf="pout.credits==0 && pout.points >0"><span class="number">{{pout.matches | numbformat}}</span><span class="arrow">
                          <img src="assets/images/ticket_arrow_right.png"></span><span class="ticket">x{{pout.points}}
                          PB</span></li>
                                        </ng-template>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 p-0">
                        <div class="tournament-numberpick-sec">
                            <div class="tourpicker" *ngIf="time>5">
                                <h2 class="tourpicker_title">Quick Pick</h2>
                                <ul>
                                    <li *ngFor="let qpick of quickpickNos" (click)="quickpick(qpick)"><a href="javascript:void(0);" [ngClass]="{'selected': quickpickNo==qpick}">{{qpick}}</a></li>
                                    <li (click)="ticket.Numbers=[];"><a href="javascript:void(0);">CLEAR</a></li>
                                </ul>
                            </div>
                            <div class="tour_number_countdown">
                                <ul class="tn_main_list">
                                    <ng-template ngFor let-num [ngForOf]="numberset" let-i="index">
                                        <li class="tn_list" *ngIf="i<divNumber" (click)="chooseNumber(num)"><a href="javascript:void(0);" [@NumberStyle]="numberClass(num)">{{num}}</a>
                                            <span class="ball_img text-center" [@BetBallShowHide]="SytemSelectNumberStage(num)">
                                                {{ num }}
                                            </span>
                                        </li>
                                    </ng-template>
                                </ul>
                                <div class="tour_welcome">
                                    <div class="tour_welc_left">
                                        <span class="id">#{{this.playerCurrentTour.virtual_draw_id}}</span>
                                    </div>
                                    <div class="tour_welc_middle">
                                        <h2 class="welc_title">Welcome to the tourney!</h2>
                                        <h2 class="gl_title">Good luck Harry!!</h2>
                                    </div>
                                    <div class="tour_welc_right">
                                        <span class="time" [ngStyle]="{'color': time <=5 ? '#ca1919' : '#000'}">{{ timeformat(time) }}</span>
                                    </div>
                                </div>
                                <ul class=" tn_main_list ">
                                    <!-- tn_list_selected -->
                                    <ng-template ngFor let-num [ngForOf]="numberset " let-i="index ">
                                        <li class="tn_list " *ngIf="i>=divNumber" (click)="chooseNumber(num)"><a href="javascript:void(0);" [@NumberStyle]="numberClass(num)">{{num}}</a>
                                            <span class="ball_img text-center" [@BetBallShowHide]="SytemSelectNumberStage(num)">
                                                {{ num }}
                                            </span>
                                        </li>
                                    </ng-template>
                                </ul>
                            </div>
                            <div class="tourdraws">
                                <h2 class="tourpicker_title">Last Draws</h2>
                                <ul>
                                    <li *ngFor="let ld of lastdraw"><a href="javascript:void(0);">{{ld}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tournament-bottom-sec" *ngIf="playerCurrentTour.playertourid!=0">
                <!-- -->
                <div class="row">
                    <div class="col-8 pr-1">
                        <div class="tour_main_tct_sec" *ngIf="DrawRunningStatus!=4">
                            <div class="tournament-tickets">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="ticketimg-th"><img src="assets/images/tournament_ticket_fly.png">
                                            </th>
                                            <th class="title-th">Tickets</th>
                                            <th class="select-th">
                                                <!-- <div class="dropdown">
                                                    <div class="select" #selectticket>
                                                        <span class="sel_text">Previous</span>
                                                        <span class="sel_arrow"><i class="fas fa-caret-down"></i></span>
                                                    </div>
                                                    <ul class="dropdown-menu">
                                                        <li class="active"><a href="#">Previous</a></li>
                                                        <li><a href="#">Current</a></li>
                                                    </ul>
                                                    </div> -->
                                                <div class="select">
                                                    <select #selectticket>
                            <option value="0">Previous</option>
                            <option value="1">Current</option>
                          </select>
                                                </div>
                                            </th>
                                            <th class="flip-th">
                                                <div class="check">
                                                    <label for=""><input type="checkbox">Flip tickets</label>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                                <span class="some_tickets transform-middle" *ngIf="drawtickets.length==0">Buy Some tickets...</span>
                                <div class="tour_ticket_main_box row" *ngIf="selectticket.value==0">
                                    <div class="tour_ticket_box" *ngFor="let tckt of this.prevdrawtickets">
                                        <img src="assets/images/tournament_top_img1.png" class="tour_ticket_logo">
                                        <span class="id">D#{{tckt.Drawno}}</span>
                                        <span class="id">T#{{tckt.Ticket_no}}</span>
                                        <span class="coin"><img src="assets/images/ticket_box_coin.png">{{tckt.Wager}}</span>
                                        <h2 class="goodluck_title lost" *ngIf="tckt.bucks==0">Better Luck Next Time!</h2>
                                        <h2 class="goodluck_title" *ngIf="tckt.bucks!=0">{{tckt.bucks}} Prize Bucks</h2>
                                        <ul class="tct_main_list">
                                            <li class="tct_list" [ngClass]="{'tct_list_selected':tckt.NumbersMatch[i]}" *ngFor="let tno of tckt.Numbers; let i=index"><a href="javascript:void(0);">{{tno}}</a>
                                            </li>
                                        </ul>
                                        <ul class="tct_btns">
                                            <li class="reselect" (click)="reSelect(tckt.Numbers)"><a href="javascript:void(0);">reselect</a>
                                            </li>
                                            <li class="payouts" (click)="OpenTicketPayout(ticketPayoutModal,tckt)"><a href="javascript:void(0);">payouts</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="tour_ticket_main_box row" *ngIf="selectticket.value==1">
                                    <div class="tour_ticket_box" *ngFor="let tckt of this.drawtickets">
                                        <img src="assets/images/tournament_top_img1.png" class="tour_ticket_logo">
                                        <span class="id">D#{{tckt.Drawno}}</span>
                                        <span class="id">T#{{tckt.Ticket_no}}</span>
                                        <span class="coin"><img src="assets/images/ticket_box_coin.png">{{tckt.Wager}}</span>
                                        <h2 class="goodluck_title">Good Luck !</h2>
                                        <ul class="tct_main_list">
                                            <li class="tct_list" *ngFor="let tno of tckt.Numbers"><a href="javascript:void(0);" [@ticketballmatch]="ticketnumberClass(tno)">{{tno}}</a>
                                            </li>
                                        </ul>
                                        <ul class="tct_btns">
                                            <li class="reselect" (click)="reSelect(tckt.Numbers)"><a href="javascript:void(0);">reselect</a>
                                            </li>
                                            <li class="payouts" (click)="OpenTicketPayout(ticketPayoutModal,tckt)"><a href="javascript:void(0);">payouts</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="tournament-payout">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td class="payoutimg-td"><img src="assets/images/tournament_payout_img.png">
                                            </td>
                                            <td class="payout-id">
                                                <span class="id">Tourney ID</span>
                                                <span class="id-number"><img
                            src="assets/images/tournament_payout_ticket.png">{{playerCurrentTour.playertourid | numbformat}}</span>
                                            </td>
                                            <td class="payout-playing">
                                                <span class="playing">People Playing</span>
                                                <span class="playing-number"><img
                            src="assets/images/tournament_payout_user.png">{{playerCurrentTour.player_count | numbformat}}</span>
                                            </td>
                                            <td class="payout-prize">
                                                <span class="prize-pool">Prize Pool</span>
                                                <span class="prize-number"><img
                            src="assets/images/tournament_payout_note_coin.png">{{playerCurrentTour.prize_pool | thousandSuff : 2}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="payoutimg-td"></td>
                                            <td class="payout-id">
                                                <span class="id">Tourney Draw</span>
                                                <span class="id-number"><img
                            src="assets/images/tournament_payout_ticket.png">{{playerCurrentTour.max_draws}}</span>
                                            </td>
                                            <td class="payout-playing">
                                                <span class="playing">Current Rank</span>
                                                <span class="playing-number"><img
                            src="assets/images/tournament_payout_user.png">{{playerCurrentTour.player_rank}}</span>
                                            </td>
                                            <td class="payout-prize">
                                                <a href="javascript:void(0);" (click)="OpenGamePayout(gamePayoutModal)" class="payout">PAYOUT</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tourney-players">
                                <div class="tourney-head">
                                    <img src="assets/images/tourney_img.png" class="tourney_img">
                                    <h2>Tourney Players</h2>
                                </div>
                                <div class="tourney-table">
                                    <table class="table">
                                        <thead>
                                            <th class="rank-th">Rank</th>
                                            <th class="img-th"></th>
                                            <th class="name-th">Players</th>
                                            <th class="pt-th">Points</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of touruserlist">
                                                <td class="rank-td">
                                                    <div>{{item.player_rank}}</div>
                                                </td>
                                                <td class="img-td">
                                                    <div><img src="{{item.player_picture}}"></div>
                                                </td>
                                                <td class="name-td">
                                                    <div>{{item.name}}</div>
                                                </td>
                                                <td class="pt-td">
                                                    <div>{{item.tourney_points}}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tournament-radio">
                                <div class="tour-radio-head">
                                    <h2> <img src="assets/images/tournament_radio_img.png">Radio</h2>
                                </div>
                                <div class="tour-radio-body">
                                    <div class="select">
                                        <select>
                      <option value="">60S</option>
                    </select>
                                    </div>
                                    <div class="select">
                                        <select>
                      <option value="">60S</option>
                    </select>
                                    </div>
                                    <div class="tour-radio-btn clearfix">
                                        <button class="sound_btn">Sound Problems? Reload!</button>
                                        <button class="music_btn">Stop The Music!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tournament_result_sec" *ngIf="DrawRunningStatus==4">
                            <div class="tour_resut_head">
                                <h2><img src="assets/images/tour_bronze_img.png">Tournament Results</h2>
                                <a href="javascript:void(0);" class="back_to_lobby" (click)="backtolobby()">back
                  to lobby</a>
                            </div>
                            <div class="tour_resut_body">
                                <div class="tour_resut_block " [ngClass]="{'tour_resut_block_yellow': i==0,'tour_resut_block_grey':i!=0}" *ngFor="let item of tournamentpayout; let i=index;">
                                    <h2 class="tour_resut_title">{{item.player_rank}}.{{item.name}}<span>[]</span></h2>
                                    <div class="td-img"><img src="{{item.player_picture}}"></div>
                                    <div class="tour_resut_table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th class="th-rank">Rank</th>
                                                    <th class="th-point">Point</th>
                                                    <th class="th-bucks">Keno Bucks Won</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td class="td-rank">{{item.player_rank}}</td>
                                                    <td class="td-point">{{item.tourney_points}}</td>
                                                    <td class="td-bucks">{{item.tourney_bucks}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-4 pl-1">
                        <div class="tournament-chat-textarea">
                            <div class="tr-chat-text-head">
                                <img src="assets/images/Tourney_Chat.png">
                                <img src="assets/images/Button_Tourney.png">

                            </div>
                            <div class="tr-chat-text-body">
                                <textarea autosize [minRows]="3" placeholder="Enter message here!" (click)="showEmojiPicker = false;" #chatmsg [(ngModel)]="message" (keyup.enter)="sendchat();"></textarea>
                                <button (click)="toggleEmojiPicker()" style="position: relative;top: -30px;background: none;width: unset; display: block; float: right; height: 0px;border: 0;">
                  😀</button>
                                <div class="divemoji">
                                    <emoji-mart class="emoji-mart" set="{{emojiemoj}}" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…"></emoji-mart>
                                </div>
                                <button (click)="sendchat();">send</button>
                            </div>
                        </div>
                        <div class="tournament-chat-sec">
                            <div class="tournament-chat-main" *ngFor="let chat of chats">
                                <div class="tournament-chat-block">
                                    <div class="tournament-chat-head">
                                        <div class="left">
                                            <h2>{{chat.name}}</h2>
                                            <p>none</p>
                                        </div>
                                        <div class="right">
                                            <div class="star">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                            </div>
                                            <p>{{chat.time_and_date | mTimeElapsed}}</p>
                                        </div>
                                    </div>
                                    <div class="tournament-chat-body" [ngStyle]="{'background': chat.type_color}">
                                        <div class="img" [ngStyle]="{'background-image': 'url('+chat.player_picture+')'}">

                                        </div>
                                        <div class="text">
                                            <p [innerHTML]="emojiemoj !== '' ? (chat.message | replaceEmojis:'facebook') : chat.chat_msg">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section [style.display]="isLoadCompleted ? 'none' : 'block'" class="game-canvas">
    <div class="load-game">
        <img src="/assets/images/loader.gif">
    </div>
</section>
<!-- The rules  Modal -->
<ng-template #rulesModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Tournament Rules</h4>
            <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
        </div>

        <div class="content">
            <div>
                <ul>
                    <li><span>All payouts will pay Chips or Diamonds.</span></li>
                    <li><span>Tourneys with 50 players or more will pay 25% to the top winners.</span> </li>
                    <li><span>In the event that a tourney is not properly finished all Diamonds will be refunded and the
              tournament will be rendered null.</span></li>
                    <li><span>Good Luck and Have Fun!!</span></li>
                </ul>
            </div>
        </div>
        <div class="modal-footer">
            <button _ngcontent-afy-c115="" class="ng-tns-c115-0" (click)="modal.close('Close click')">close</button>
        </div>
    </div>
</ng-template>
<!-- The Stats  Modal -->
<ng-template #statsModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Your Tournament Stats</h4>
            <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
        </div>

        <div class="content">
            <h2>Your Recent Tournaments</h2>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Tourney ID</th>
                            <th>Buyin</th>
                            <th>Prize Pool</th>
                            <th>Players</th>
                            <th>You Placed</th>
                            <th>Payout</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of playerstats">
                            <td>#{{item.tournament_id}}</td>
                            <td>{{item.buyin | thousandSuff : 2}}</td>
                            <td>{{item.prize_pool | thousandSuff : 2}}</td>
                            <td>{{item.max_players}}</td>
                            <td>{{item.player_rank |ordinal}}</td>
                            <td>{{item.tourney_payout_amount}}</td>
                            <td>
                                <span class="date">{{item.date_and_time | date: 'yyyy-MM-dd' }}</span>
                                <span class="time">{{item.date_and_time | date:'hh:mm:00'}}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button _ngcontent-afy-c115="" class="ng-tns-c115-0" (click)="modal.close('Close click')">close</button>
        </div>
    </div>
</ng-template>

<!-- The Ticket Payout  Modal -->
<ng-template #ticketPayoutModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-head">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><img src="assets/images/modal_close.png"></span>
      </button>
        </div>

        <div class="modal_inr">
            <h2 class="h2_tc_payout"><img src="assets/images/ticket_box_coin.png"><span class="green_text">{{ticketPayout.Wager}}</span> Keno Bucks</h2>
            <div class="number_tc_payout">
                <h3>{{ticketPayout.Count}} Numbers</h3>
                <ul class="tc_main_list">
                    <li [ngClass]="{'tct_list_selected':ticketPayout.NumbersMatch[i]}" *ngFor="let item of ticketPayout.Numbers; let i=index;"><a href="javascript:void(0);">{{item}}</a></li>
                </ul>
            </div>

            <div class="ticket_table">
                <table class="table">
                    <tbody>
                        <tr *ngFor="let item of ticketPayout.Payout">
                            <td>Match <span class="yellow_text">{{item.matches}}</span> Win</td>
                            <td [ngClass]="{'matched': item.matches==ticketPayout.MatchCount}">
                                <span class="coin_purse">Prize Bucks <img src="assets/images/coin_purse.png"></span>
                                <span class="coin_note">Keno Bucks <img src="assets/images/tournament_note_coin.png"></span>
                            </td>
                            <td [ngClass]="{'matched': item.matches==ticketPayout.MatchCount}">
                                <span class="coin_purse_count">{{item.pb | numbformat}}</span>
                                <span class="coin_note_count">{{item.kb | numbformat}}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>


<!-- The Game Payout  Modal -->
<ng-template #gamePayoutModal let-modal>
    <div class="modal-inr-content">
        <div class="modal-head">
            <h2>Tourney Payouts</h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><img src="assets/images/modal_close.png"></span>
      </button>
        </div>

        <div class="modal_inr">
            <img src="assets/images/ticket_box_coin.png" class="modal_ticket_box_coin">

            <div class="modal_payout_table">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="th-rank">Rank</th>
                            <th class="th-percent">Percent</th>
                            <th class="th-amount">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tournamentpayoutlist">
                            <td class="td-rank">{{item.id}}</td>
                            <td class="td-percent">{{gbf.toint(item.persentage)}}%</td>
                            <td class="th-amount">{{gbf.toint(item.amount) | numbformat}} KB</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
