import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatFilter'
})
export class ChatFilterPipe implements PipeTransform {

  transform(items: any[], chatType: number): any[] {
    if (!items) { return []; }
    if (!chatType) { return items; }

    const filteredItems = items.filter(x => x.chat_type == chatType)

    return filteredItems;
  }

}
