import { Pipe, PipeTransform, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { emojis, EmojiService, CompressedEmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';

/**
 * Angular2+ pipe to transform HTML containing unicode emojis to
 * HTML containing emoji image elements
 *
 * Sample usage (angular template code):
 * `<div [innerHTML]="'<a title=\'Ang🧛‍♀️ular\'>Hello</a> 👌 Ang🧛‍♀️ular 👌!' | replaceEmojis"></div>`
 *

 */
@Pipe({
  name: 'replaceEmojis'
})
export class ReplaceEmojisPipe implements PipeTransform {
  public static readonly DEFAULT_SHEET = 'facebook';
  public static readonly DEFAULT_IMAGE_SIZE = 22;
  public static readonly DEFAULT_SHEET_SIZE = 64;
  private static cachedEmojiRegex: RegExp;


  /**
   * Utility method to get all text node descendants of a DOM node
   * @param node the DOM node to get text nodes for
   */
  public static getAllTextNodes(node: Node) {
    const all = [];
    for (node = node.firstChild; node; node = node.nextSibling) {
      if (node.nodeType === Node.TEXT_NODE) {
        all.push(node);
      } else {
        all.push(...ReplaceEmojisPipe.getAllTextNodes(node));
      }
    }
    return all;
  }

  constructor(@Inject(DOCUMENT) private document: Document, private sanitizer: DomSanitizer, private emojiService: EmojiService) { }

  public transform(
    html: string,
    set: '' | 'facebook' | 'apple' | 'google' | 'twitter' = ReplaceEmojisPipe.DEFAULT_SHEET
  ): SafeHtml {

    return this.sanitizer.bypassSecurityTrustHtml(
      this.emojisToImages(
        html,
        set
      )
    );
  }


  public emojisToImages(
    html: string,
    set: '' | 'facebook' | 'apple' | 'google' | 'twitter'

  ): string {
    // Ensure most html entities are parsed to unicode:
    const div = this.document.createElement('div') as Element;
    div.innerHTML = html;

    const textNodes = ReplaceEmojisPipe.getAllTextNodes(div);
    for (let currentItem of textNodes) {
      let match: RegExpExecArray;
      while ((match = this.emojiRegex.exec(currentItem.textContent)) !== null) {
        const unicodeEmoji = currentItem.textContent.substr(match.index, match[0].length);
        const hexCodeSegments = [];
        let j = 0;
        while (j < unicodeEmoji.length) {
          const segment = unicodeEmoji.codePointAt(j).toString(16).toUpperCase();
          hexCodeSegments.push(segment);

          j += Math.ceil(segment.length / 4);
        }
        const hexCode = hexCodeSegments.join('-');
        const matchingData = this.findEmojiData(hexCode);
        if (matchingData) {
          const span = document.createElement('span');
          span.setAttribute('contenteditable', 'false');
          span.className = 'emoji-pipe-image';


          const styles = this.emojiService.emojiSpriteStyles(
            matchingData.sheet,
            set
          );
          Object.assign(span.style, styles);

          const text = currentItem.textContent;
          currentItem.textContent = text.substr(0, match.index);
          currentItem.parentNode.insertBefore(span, currentItem.nextSibling);
          currentItem = this.document.createTextNode(text.substr(match.index + match[0].length));
          span.parentNode.insertBefore(currentItem, span.nextSibling);

          this.emojiRegex.lastIndex = 0;
        }
      }
    }

    return div.innerHTML;
  }

	/**
	 * Regex matching all unicode emojis contained in emoji-mart
	 */
  private get emojiRegex(): RegExp {
    if (ReplaceEmojisPipe.cachedEmojiRegex) {
      return ReplaceEmojisPipe.cachedEmojiRegex;
    }

    let characterRegexStrings: string[] = [];
    for (const emoji of emojis) {
      characterRegexStrings.push(this.emojiService.unifiedToNative(emoji.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

      if (emoji.skinVariations) {
        for (const skinVariation of emoji.skinVariations) {
          characterRegexStrings.push(this.emojiService.unifiedToNative(skinVariation.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        }
      }
    }

    characterRegexStrings = characterRegexStrings.sort((a, b) => {
      if (a.length > b.length) {
        return -1;
      }

      if (b.length > a.length) {
        return 1;
      }

      return 0;
    });

    const strings = characterRegexStrings;
    const reString = '(' + strings.join('|') + ')';
    ReplaceEmojisPipe.cachedEmojiRegex = new RegExp(reString, 'gu');

    return ReplaceEmojisPipe.cachedEmojiRegex;
  }

	/**
	 * Find raw emoji-mart data for a specific emoji hex code
	 * @param hexCode String representation of the emoji hex code
	 */
  private findEmojiData(hexCode: string): CompressedEmojiData {
    for (const emojiData of emojis) {
      if (emojiData.unified === hexCode) {
        return emojiData;
      }

      if (emojiData.skinVariations) {
        for (const skinVariation of emojiData.skinVariations) {
          if (skinVariation.unified === hexCode) {
            const skinData = Object.assign({}, emojiData);
            skinData.sheet = skinVariation.sheet;
            return skinData;
          }
        }
      }
    }

    return null;
  }
}
