import { Component, OnInit, Renderer2, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef, Pipe } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { gameAnimation } from '../_animations/animation';
import { KenoapiService } from '../services/kenoapi.service';
import { FacebooksdkService } from '../services/facebooksdk.service';
import { Globalfunction } from '../common/globalfunction';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import * as _Bigint from 'big-integer';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';
import * as introJs from 'intro.js/intro.js';
import { ChartType } from 'chart.js';
import { SingleDataSet, Label, Color } from 'ng2-charts';
import { async } from '@angular/core/testing';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { ActivatedRoute } from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-kenoboard',
  templateUrl: './kenoboard.component.html',
  styleUrls: ['./kenoboard.component.css'],
  animations: [gameAnimation],
})



export class KenoboardComponent implements OnInit {

  @ViewChild('chatmsg') chatmsg: ElementRef;
  @ViewChild('msgModal') msgModal: ElementRef;
  @ViewChild('levelupModal') levelupModal: ElementRef;
  @ViewChild('dailybonusmodal') dailybonusmodal: ElementRef;
  @ViewChild('gamepayoutModal') gamepayoutModal: ElementRef;
  @ViewChild('data_bind') data_bind: ElementRef;

  public environment = environment;

  // help
  introJS = introJs();
  // End help

  // Emoji variables
  showEmojiPicker = false;
  emojisets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];
  emojiemoj = 'facebook';
  // END Emoji variables

  // XP Meter
  public doughnutChartLabels: Label[] = ['User XP', 'XP Needed'];
  public doughnutChartData: SingleDataSet = [0, 100];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartColor: Color[] = [{ backgroundColor: ['#1B45D9', '#a0a1a1'], borderWidth: [0, 0] }];
  public doughnutChartoptions = {
    legend: {
      display: false,
    },
    'animation.animateScale': true,
    'animation.animateRotate': true,
    // animation: {
    //   animateRotate: true,
    //   render: false,
    // },
    cutoutPercentage: 95
  };
  public doughnutChartoptions1 = {
    legend: {
      display: false,
    },
    tooltips: false,
    'animation.animateScale': true,
    'animation.animateRotate': true,
    // animation: {
    //   animateRotate: true,
    //   render: false,
    // },
    cutoutPercentage: 65
  };
  public doughnutChartoptions2 = {
    legend: {
      display: false,
    },
    tooltips: false,
    'animation.animateScale': true,
    'animation.animateRotate': true,
    // animation: {
    //   animateRotate: true,
    //   render: false,
    // },
    cutoutPercentage: 85
  };
  // END XP Meter

  // stats variables
  chatignorlist = [];
  donationresendlist = [];
  donationrereceivedlist = [];
  dailieslogs = [];
  recentdraws = [];
  recentsidedraws = [];
  recentpurchases = [];
  recentpowerups = [];
  recentachivement = [];
  stats_enable = 1;
  jackpots = [];
  // ENDstats variables

  // Chat variables
  initialchatCount = 0;
  chatsending = false;
  chattype = 1;
  message = '';
  chats = [];
  gameChats = [];
  lastChatId = 0;
  // END Chat variables

  // Game Initialization
  isLoadCompleted = false;
  isTicketbuying = false;
  loadPercentage = 0;
  totalNumberCount = 80;
  maxSelectableCount = 20;
  minSelectableCount = 1;
  systemSelectCount = 20;
  divNumber = 40;
  isBuyingTicket = false;
  modalTemplate = `<button class="btn btn-success">click</button>`;
  isPlay = false;
  quickpickNo = 0;
  buyticketnav = 1;
  backgroundfunctionIntervalTime = 3000;
  winnerListType = [
    { id: 1, api: 'get-lastgame-winner', key: 'Last_Draw_Winners', type: 'Last Draw Winners', heading: 'Biggest Winners from draw #' },
    { id: 2, api: 'get-xp', key: 'XP_Level_Leaders', type: 'XP Level Leaders', heading: 'XP Level Leaders' },
    { id: 3, api: 'get-powerball-winner', key: 'Powerball_Winner', type: 'Powerball Winners', heading: '10 Most Recent Powerball Wins' },
    { id: 4, api: 'get-wager', key: 'Hightest_Wagered', type: 'Hightest Wagered', heading: 'Top Wagerers of ' + new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' }) },
    { id: 5, api: 'get-resent-jp', key: 'resent_jp', type: 'Recent Jackpots', heading: 'Jackpod Total :' },
    { id: 6, api: 'get-biggest-jp-today', key: 'biggest_jp_today', type: 'Biggest Jp\'s Today', heading: 'Top 10 Daily JP Winners' },
    { id: 7, api: 'get-powerball-comp-winner', key: 'Powerball_Comp_Winner', type: 'Powerball Comp', heading: 'Top 10 Powerball Winners' },
    { id: 8, api: 'get-wager-comp-one', key: 'wager_comp_one', type: 'Wager Comp - Tier 1', heading: 'XP Level(52-107) 1,180,530 to 1,182,260' },
    { id: 9, api: 'get-wager-comp-two', key: 'wager_comp_two', type: 'Wager Comp - Tier 2', heading: 'XP Level(31-51) 1,180,530 to 1,182,260' },
    { id: 10, api: 'get-wager-comp-three', key: 'wager_comp_three', type: 'Wager Comp - Tier 3', heading: 'XP Level(16-30) 1,180,530 to 1,182,260' },
    { id: 11, api: 'get-wager-comp-four', key: 'wager_comp_four', type: 'Wager Comp - Tier 4', heading: 'XP Level(1-15) 1,180,530 to 1,182,260' },
    { id: 12, api: 'get-biggest-jp-alltime', key: 'biggest_jp_alltime', type: 'Biggest AllTime', heading: 'Top 10 All Time JP Winners' },
    { id: 13, api: 'get-three-one', key: 'com_one', type: '3/3 COMP (TIER 1)', heading: 'Most 3/3\'s Matched (#1200690- 1202420)' },
    { id: 14, api: 'get-three-two', key: 'com_two', type: '3/3 COMP (TIER 2)', heading: 'Most 3/3\'s Matched (#1200690- 1202420)' },
    { id: 15, api: 'get-three-three', key: 'com_three', type: '3/3 COMP (TIER 3)', heading: 'Most 3/3\'s Matched (#1200690- 1202420)' },
    { id: 16, api: 'get-three-four', key: 'com_four', type: '3/3 COMP (TIER 4)', heading: 'Most 3/3\'s Matched (#1200690- 1202420)' },
    { id: 17, api: 'get-three-five', key: 'com_five', type: '3/3 COMP (TIER 5)', heading: 'Most 3/3\'s Matched (#1200690- 1202420)' },
    { id: 18, api: 'get-three-six', key: 'com_six', type: '3/3 COMP (TIER 6)', heading: 'Most 3/3\'s Matched (#1200690- 1202420)' },
    { id: 19, api: 'get-three-seven', key: 'com_seven', type: '3/3 COMP (TIER 7)', heading: 'Most 3/3\'s Matched (#1200690- 1202420)' },
  ];
  leaderboard;
  winnertypeselect = 'last_draw_winners';
  winnertypeheader = 'Last Draw Winners';
  winnertypeheaderDetails = 'Biggest Winners from draw #1,167,987';
  winnerData;
  winnerlist = {
    Last_Draw_Winners: [],
    Hightest_Wagered: [],
    XP_Level_Leaders: [],
    wager_comp_one: [],
    wager_comp_two: [],
    wager_comp_three: [],
    wager_comp_four: [],
    Powerball_Comp_Winner: [],
    Powerball_Winner: [],
    com_one: [],
    com_two: [],
    com_three: [],
    com_four: [],
    com_five: [],
    com_six: [],
    com_seven: [],
    resent_jp: [],
    biggest_jp_today: [],
    biggest_jp_alltime: []
  };

  meta_winnerlist = {
    Last_Draw_Winners: [],
    Hightest_Wagered: [],
    XP_Level_Leaders: [],
    wager_comp_one: [],
    wager_comp_two: [],
    wager_comp_three: [],
    wager_comp_four: [],
    Powerball_Comp_Winner: [],
    Powerball_Winner: [],
    com_one: [],
    com_two: [],
    com_three: [],
    com_four: [],
    com_five: [],
    com_six: [],
    com_seven: [],
    resent_jp: [],
    biggest_jp_today: [],
    biggest_jp_alltime: []
  };


  sidebetType = {
    odd: { type: 'ODDEVEN', value: 'O', win: 'O', lost: 'E' },
    even: { type: 'ODDEVEN', value: 'E', win: 'E', lost: 'O' },
    north: { type: 'NORTHSOUTH', value: 'N', win: 'N', lost: 'S' },
    south: { type: 'NORTHSOUTH', value: 'S', win: 'S', lost: 'N' },
    east: { type: 'EASTWEST', value: 'E', win: 'E', lost: 'W' },
    west: { type: 'EASTWEST', value: 'E', win: 'E', lost: 'W' },
    power: { type: 'POWER', value: '', win: '', lost: '' },
  };
  xplist = [];
  achivemntlist_misc = [];
  achivemntlist_matches = [];
  // END Game Initialization

  // Donate variables
  donationDetails = {
    donatePlayer_name: '',
    donatePlayer_pic: '',
    donatePlayer_id: '',
    donateAmt: 1,
  };
  maxDonateAmt = 1000;
  minDonateAmt = 1;
  donationmodalstage = 0;
  // END Donate variables

  // Prize variables
  prizebackcolor = '#ffffff';
  prizeWindowType = 0;
  prizestageId = 0;
  prizeselectcategory = '';
  prizelevels = [];
  prizecategorys = [];
  prizeitems = [];
  // END Prize variables

  // Payout variables
  payoutlist = [];
  selectedpayoutlist = {
    nocount: 0,
    payoutlist: []
  };
  gamepayout = {
    coins: 0,
    bucks: 0
  };
  // END Payout variables

  // POWERUPS variables
  poweruplist = [];
  chipspoweruplist = [];
  powerupselected = {
    max_wager: { power_up_id: 1, type: 'max_wager', level: 1, cost: 100, value: 500 },
    battery_bonus: { power_up_id: 7, type: 'battery_bonus', level: 1, cost: 200, value: 500 },
    daily_bonus: { power_up_id: 13, type: 'daily_bonus', level: 1, cost: 300, value: 500 },
    max_tickets: { power_up_id: 19, type: 'max_tickets', level: 1, cost: 250, value: 50 },
    max_draws: { power_up_id: 24, type: 'max_draws', level: 1, cost: 25000, value: 1 },
    receive_limit: { power_up_id: 28, type: 'receive_limit', level: 1, cost: 100, value: 500 },
    send_limit: { power_up_id: 34, type: 'send_limit', level: 1, cost: 100, value: 500 },
  };
  powerupforbuy = { power_up_id: 0, type: '', level: 0, cost: 0, value: 0 };
  powerupconfirmtexts = [
    { type: 'chips', h2: 'CHIPS POWERUP', h3: '', h4: 'Chips', img: 'assets/images/chips_powerup_inner_img.png' },
    { type: 'max_wager', h2: 'MAX WAGER POWERUP', h3: 'Increasing your overall max wager', h4: 'Chips', img: 'assets/images/powerup/chips_powerup_inner_img.png' },
    { type: 'max_tickets', h2: 'MAX TICKETS POWERUP', h3: 'Increasing your tickets per draw', h4: 'Tickets', img: 'assets/images/powerup/chips_powerup_inner_img.png' },
    { type: 'max_draws', h2: 'MAX DRAWS POWERUP', h3: 'Increasing your max tickets per draw', h4: 'Draws', img: 'assets/images/powerup/chips_powerup_inner_img.png' },
    { type: 'receive_limit', h2: 'RECEIVE LIMIT POWERUP', h3: 'Increasing your donation receive limit', h4: 'Chips', img: 'assets/images/powerup/chips_powerup_inner_img.png' },
    { type: 'send_limit', h2: 'SEND LIMIT POWERUP', h3: 'Increasing your donation send limit', h4: 'Chips', img: 'assets/images/powerup/chips_powerup_inner_img.png' },
  ];
  // END POWERUPS variables

  // Intervals
  interval;
  jackpotinterval;
  backgroundinterval;
  // END Intervals

  numberset = [];
  selectedNumbers = [];
  sidebetnumbers = [];
  sidebettype = 'CHOOSE A SIDEBET!';
  selectedSideBetNumbers = [];
  tickets = [];
  tickets_count = 0;
  stickets = [];
  powerdraw = 0;
  currentSelectNumber = 0;


  currentuserplaystatus = {
    playerOnline: 0,
    allLiveticket: 0,
    allChipWaged: 0,
  };

  playticketStatus = 0;

  //  Server Site variable
  selectedpowernumber = 0;
  gameNumber: number;
  jackpotBegin = _Bigint(0);
  jackpotAmount = _Bigint(0);
  time = 9;

  dailybonus = [];
  dailybonusday = 0;

  systemSelectNumbers = [];
  lastsystemSelectNumbers = [];
  powernumber = 0;
  hotNumber = [];
  coldNumber = [];

  // Regular Bet
  wager = 0;
  draws = 0;
  mindraws = 1;
  playtickets = 0;
  minplaytickets = 1;

  // Side Bet
  swager = 15;
  sdraws = 0;
  smindraws = 1;

  // msgModal
  msgModaltile = '';
  msgModalbody = '';
  // End msgModal

  // Facebook login Details
  userObj = {
    player_name: '',
    player_id: '',
    player_picture: '',
  };

  // Player Details
  playerDetails = {
    id: 0,
    player_id: '',
    name: '',
    player_picture: '',
    ip_address: '',
    country_code: '',
    country: '',
    type: 0,
    created_at: '',
    updated_at: '',
    gl_coin_ruby_id: 0,
    game_leave: 1,
    user_coin: 0,
    user_bucks: 0,
    user_ruby: 0,
    user_max_ticket: 1,
    user_daily_bonus: 0,
    user_max_draw: 1,
    user_max_wager: 15,
    user_min_wager: 15,
    user_base_bonus: 0,
    user_receive_limit: 0,
    user_send_limit: 0,
    user_jp_multiplier: 0,
    user_xp: 0,
    xp_needed: 0,
  };

  // Admin Search Player Details
  adminsearchplayerDetails: any;
  signedRequest = `pkk5`;

  today: number = Date.now();
  timeZoneOffset = new Date().getTimezoneOffset();
  constructor (
    private modalService: NgbModal, private apiService: KenoapiService,
    private fbsdk: FacebooksdkService, private emojiSearch: EmojiSearch,
    private gbf: Globalfunction, private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private route: ActivatedRoute
  ) 
    {
        this.numberset = _.range(1, this.totalNumberCount + 1, 1);
        const signed_request: string = this.route.snapshot.queryParamMap.get('signed_request');
      
        if (environment.production) {
          if(signed_request == null) {
            fbsdk.init({
              appId: environment.appId,
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v6.0'
            });
            this.fblogin();
          } else {
            this.signedRequest = signed_request;  
          }
        } else {
          if(signed_request !== null) {
            this.signedRequest = signed_request; }
          else {
            this.userObj.player_picture = 'https://graph.facebook.com/186891609489274/picture';
            this.userObj.player_name = 'Daniil Timchenko';
            this.userObj.player_id = '186891609489274';//309820490093083, 102576284705784
            this.onStart();
          }
        }
        setInterval(() => {
          this.today = Date.now();
        }, 1);
  }

  
  ngOnInit(): void {
    
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // facebook login and user
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  fblogin() {
    this.fbsdk.getLoginStatus().then(response => {console.log(response);
      if (response.status == 'connected') {
        this.signedRequest = response.authResponse.signedRequest;
        // this.loadPercentage = 10;
        this.fbsdk.api('/me?fields=email,name,picture').then(userdata => {
          this.userObj.player_picture = 'https://graph.facebook.com/' + userdata.id + '/picture'; //userdata.picture.data.url;
          this.userObj.player_name = userdata.name;
          this.userObj.player_id = userdata.id;
          this.onStart();
        });
      } else {
        this.fbsdk.login(response).then(logindata => {
          this.fblogin();
        });
      }
    });
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // End facebook login and user
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // Game Start
  async  onStart() {
    const localinterval1 = setInterval(() => {
      if (this.loadPercentage >= 49) {
        clearInterval(localinterval1);
      }
      this.loadPercentage++;
    }, 100);
   
    this.apiService.getPaulData(this.signedRequest).subscribe(async data => {

      this.loadPercentage = 50;
      if(data.popups.length) {
        this.dailybonus = data?.popups.filter((d) =>  d.type === "daily" )[0].data?.all_rates;
      }
      this.dailybonusday = data.vitals.daily_bonus;
      this.playerDetailsFill(data.vitals);
      this.payoutlist = data.payouts;
      this.playerDetails.name = data.vitals.first_name;
      this.playerDetails.type = data.admin;
      this.playerDetails.player_id = data.user_id;
      this.playerDetails.country = '';
      this.playerDetails.country_code = '';
      this.playerDetails.created_at = '';
      this.playerDetails.gl_coin_ruby_id = 4343334;
      this.playerDetails.player_picture = data.vitals.pic;
      this.playerDetails.updated_at = '';
      this.playerDetails.user_base_bonus = 0;
      this.playerDetails.user_daily_bonus = data.vitals.daily_bonus;
      this.playerDetails.user_jp_multiplier = 0;
      this.playerDetails.user_max_draw = data.vitals.max_draws;
      if(data.vitals.max_draws > 400) {
        this.playerDetails.user_max_draw = 400;
      }
      this.playerDetails.user_receive_limit = data.vitals.receive_limit;
      this.playerDetails.user_send_limit = data.vitals.send_limit;
      this.playerDetails.user_xp = data.vitals.xp.xp_current;
      this.playerDetails.xp_needed = data.vitals.xp.xp_needed;
      this.tickets_count = data.tickets_played;
      console.log('inintial ticket count', this.tickets_count);
      this.tickets = data.tickets;
      this.stickets = data.sidebets;
      this.clearsvalues();
      this.clearvalues();
      var newDate = new Date();
      var current_timestamp = Math.floor(newDate.getTime()/1000)
      data.chat.map((item) => {
        var res = Math.abs(current_timestamp - item.ts*1000);
        var minutes = Math.floor(res / 60) % 60;
        item.ts = minutes;
        item.b_bg = '#'+item.b_bg; 
        // var h = item.l.split(':')[0];console.log(Number(h))
        // var m = item.l.split(':')[1].split(' ');
        // if(m[1] == 'pm') {
        //   var time_diff = (480-this.timeZoneOffset)/60;
        //   if((time_diff + Number(h) + 12) > 24) {
        //     m[1] = 'am';
        //     h = (time_diff + Number(h) + 12) - 24;
        //   } else {
        //     h = (time_diff + Number(h));
        //   }
        // } else {
        //   var time_diff = (480-this.timeZoneOffset)/60;
        //   if((time_diff + Number(h)) > 12) {
        //     m[1] = 'pm';
        //     h = (time_diff + Number(h)) - 12;
        //   } else {
        //     h = (time_diff + Number(h));
        //   }

        // }
        // item.l = h +':'+m[0] +' '+m[1];
        return item;
      })
      this.chats = data.chat;
      if (this.chats.length > 0) {
        this.lastChatId = this.chats[0].chat_id;
      }
      this.jackpotAmount = _Bigint(data.jackpot.amount);
      this.gameNumber = data.draw_info.draw_id;
      this.time = data.draw_info.timeleft;//kjm
      this.jackpotBegin = _Bigint(data.jackpot.amount);
      this.playerDetails.user_coin = data.vitals.currency.credits;
      this.playerDetails.user_bucks = data.vitals.currency.diamonds;
      this.playerDetails.user_ruby = data.vitals.currency.points;
      this.playerDetails.game_leave = data.vitals.xp_level;
      this.hotNumber = data.draw_info.hot_cold.hot;
      this.coldNumber = data.draw_info.hot_cold.cold;
      if(data.draw_stats) {
        this.currentuserplaystatus.playerOnline = data.draw_stats.players;
        this.currentuserplaystatus.allLiveticket = data.draw_stats.tickets;
        this.currentuserplaystatus.allChipWaged = data.draw_stats.chips;
      }
      console.log('data leaderboard', data.leaderboard)
      if(data.leaderboard) {

        this.leaderboard = data.leaderboard;
        console.log(this.leaderboard);
        if(data.leaderboard.last_draw_winners) {
          this.winnerlist.Last_Draw_Winners = data.leaderboard.last_draw_winners.data;
          this.meta_winnerlist.Last_Draw_Winners = data.leaderboard.last_draw_winners;
          this.winnerData = this.leaderboard[this.winnertypeselect];
        }
        if(data.leaderboard.highest_wagered_today) {
          this.winnerlist.Hightest_Wagered = data.leaderboard.highest_wagered_today.data;
          this.meta_winnerlist.Hightest_Wagered = data.leaderboard.highest_wagered_today;
        }
        if(data.leaderboard.xp_leaders) {
          this.winnerlist.XP_Level_Leaders = data.leaderboard.xp_leaders.data;
          this.meta_winnerlist.XP_Level_Leaders = data.leaderboard.xp_leaders;
        }
        if(data.leaderboard.powerball_winners) {
          this.winnerlist.Powerball_Winner = data.leaderboard.powerball_winners.data;
          this.meta_winnerlist.Powerball_Winner = data.leaderboard.powerball_winners;
        }
        if(data.leaderboard.jackpot_winners) {
          this.winnerlist.resent_jp = data.leaderboard.jackpot_winners.data;
          this.meta_winnerlist.resent_jp = data.leaderboard.jackpot_winners;
        }
        if(data.leaderboard.jackpot_winners_today) {
          this.winnerlist.biggest_jp_today = data.leaderboard.jackpot_winners_today.data;
          this.meta_winnerlist.biggest_jp_today = data.leaderboard.jackpot_winners_today;
        }
        if(data.leaderboard.powerball_winners_comp) {
          this.winnerlist.Powerball_Comp_Winner = data.leaderboard.powerball_winners_comp.data;
          this.meta_winnerlist.Powerball_Comp_Winner = data.leaderboard.powerball_winners_comp;
        }
        if(data.leaderboard.highest_wagered_1) {
          this.winnerlist.wager_comp_one = data.leaderboard.highest_wagered_1.data;
          this.meta_winnerlist.wager_comp_one = data.leaderboard.highest_wagered_1;
        }
        if(data.leaderboard.highest_wagered_2) {
          this.winnerlist.wager_comp_two = data.leaderboard.highest_wagered_2.data;
          this.meta_winnerlist.wager_comp_two = data.leaderboard.highest_wagered_2;
        }
        if(data.leaderboard.highest_wagered_3) {
          this.winnerlist.wager_comp_three = data.leaderboard.highest_wagered_3.data;
          this.meta_winnerlist.wager_comp_three = data.leaderboard.highest_wagered_3;
        }
        if(data.leaderboard.highest_wagered_4) {
          this.winnerlist.wager_comp_four = data.leaderboard.highest_wagered_4.data; 
          this.meta_winnerlist.wager_comp_four = data.leaderboard.highest_wagered_4; 
        }
        if(data.leaderboard.jackpot_winners_alltime) {
          this.winnerlist.biggest_jp_alltime = data.leaderboard.jackpot_winners_alltime.data;
          this.winnerlist.biggest_jp_alltime = data.leaderboard.jackpot_winners_alltime;
        }
        if(data.leaderboard.match_comp_1_1) {
          this.winnerlist.com_one = data.leaderboard.match_comp_1_1.data;
          this.meta_winnerlist.com_one = data.leaderboard.match_comp_1_1;
        }
        if(data.leaderboard.match_comp_1_2) {
          this.winnerlist.com_two = data.leaderboard.match_comp_1_2.data;
          this.meta_winnerlist.com_two = data.leaderboard.match_comp_1_2;
        }
        if(data.leaderboard.match_comp_1_3) {
          this.winnerlist.com_three = data.leaderboard.match_comp_1_3.data;
          this.meta_winnerlist.com_three = data.leaderboard.match_comp_1_3;
        }
        if(data.leaderboard.match_comp_1_4) {
          this.winnerlist.com_four = data.leaderboard.match_comp_1_4.data;
          this.meta_winnerlist.com_four = data.leaderboard.match_comp_1_4;
        }
        if(data.leaderboard.match_comp_1_5) {
          this.winnerlist.com_five = data.leaderboard.match_comp_1_5.data;
          this.meta_winnerlist.com_five = data.leaderboard.match_comp_1_5;
        }

        if(data.leaderboard.match_comp_1_6) {
          this.winnerlist.com_six = data.leaderboard.match_comp_1_6.data; 
          this.meta_winnerlist.com_six = data.leaderboard.match_comp_1_6; 
        }
        if(data.leaderboard.match_comp_1_7) { 
          this.winnerlist.com_seven = data.leaderboard.match_comp_1_7.data; 
          this.meta_winnerlist.com_seven = data.leaderboard.match_comp_1_7; 
        }
      }
      this.loadPercentage = 80;
      this.tickets = data.tickets;
      this.tickets_count = data.tickets_played;
      this.stickets = data.sidebets;
      console.log(this.leaderboard[this.winnertypeselect].cols)
      const localinterval = await setInterval(() => {
        if (this.loadPercentage == 99) {
          clearInterval(localinterval);
        }
        this.loadPercentage++;
      }, 100);
      
      await this.startTimer();
      
      setTimeout(() => {
        this.fbsdk.setFbCanvasSize(760, 2800);
        this.isLoadCompleted = true;
        setTimeout(() => {
          this.doughnutChartData = [data.vitals.xp.xp_current, data.vitals.xp.xp_needed - data.vitals.xp.xp_current];
          if (this.dailybonus.length > 0) {
            this.modalService.open(this.dailybonusmodal, { windowClass: 'modal-dailybonus', backdrop: 'static', keyboard: false });
          }
        }, 1000);
      }, 1500);
    })

    const updateInterval = await setInterval(() => {
      this.apiService.update(this.signedRequest,{ chat_id: this.lastChatId, room_id: this.chattype }).subscribe(data => {
        this.jackpotAmount = _Bigint(data.jackpot);
        if(data.chat.length > 0) {
          data.chat.map((item) => {
            var newDate = new Date();
            var current_timestamp = newDate.getTime()
            var res = Math.abs(current_timestamp - item.ts*1000);
            var minutes = Math.floor(res / 60) % 60;
            item.ts = minutes;
            item.b_bg = '#'+item.b_bg; 
            // var h = item.l.split(':')[0];
            // var m = item.l.split(':')[1].split(' ');
            // if(m[1] == 'pm') {
            //   var time_diff = (480-this.timeZoneOffset)/60;
            //   if((time_diff + Number(h) + 12) > 24) {
            //     m[1] = 'am';
            //     h = (time_diff + Number(h) + 12) - 24;
            //   } else {
            //     h = (time_diff + Number(h));
            //   }
            // } else {
            //   var time_diff = (480-this.timeZoneOffset)/60;
            //   if((time_diff + Number(h)) > 12) {
            //     m[1] = 'pm';
            //     h = (time_diff + Number(h)) - 12;
            //   } else {
            //     h = (time_diff + Number(h));
            //   }
    
            // }
            // item.l = h +':'+m[0] +' '+m[1];
            return item;
          })
          if(this.chattype == 1) {
            this.chats = _.unionBy(data.chat, this.chats, 'chat_id').sort((a, b) => {
              return b.chat_id - a.chat_id;
            });
            this.lastChatId = this.chats[0].chat_id;
          } else {
            this.gameChats = _.unionBy(data.chat, this.gameChats, 'chat_id').sort((a, b) => {
              return b.chat_id - a.chat_id;
            });
            this.lastChatId = this.chats[0].chat_id;
          } 
        }
      })
    }, 10000)
    
    this.apiService.getGamechat(this.signedRequest).subscribe(async data => {
      data.map((item) => {
        var newDate = new Date();
        var current_timestamp = newDate.getTime()
        var res = Math.abs(current_timestamp - item.ts*1000);
        var minutes = Math.floor(res / 60) % 60;
        item.ts = minutes;
        item.b_bg = '#'+item.b_bg; 
        // var h = item.l.split(':')[0];console.log(Number(h))
        // var m = item.l.split(':')[1].split(' ');
        // if(m[1] == 'pm') {
        //   var time_diff = (480-this.timeZoneOffset)/60;
        //   if((time_diff + Number(h) + 12) > 24) {
        //     m[1] = 'am';
        //     h = (time_diff + Number(h) + 12) - 24;
        //   } else {
        //     h = (time_diff + Number(h));
        //   }
        // } else {
        //   var time_diff = (480-this.timeZoneOffset)/60;
        //   if((time_diff + Number(h)) > 12) {
        //     m[1] = 'pm';
        //     h = (time_diff + Number(h)) - 12;
        //   } else {
        //     h = (time_diff + Number(h));
        //   }

        // }
        // item.l = h +':'+m[0] +' '+m[1];
        return item;
      })
      this.gameChats = data;
    })
  }

  async play() {
    this.apiService.draw(this.signedRequest).subscribe(async data => {
      this.selectedNumbers = [];
      let snumber = [];
      snumber = data.draw_info.draw_numbers; 
      if (snumber.length == 0) {
        this.play();
        return;
      }
  
      this.selectedpowernumber = snumber[snumber.length - 1];
      this.systemSelectNumbers = [];
      let i = -1;
      this.playticketStatus = 1;
      this.tickets = data.tickets;
      this.tickets.sort((a, b) => { return b.n.length - a.n.length });
      const localinterval = setInterval(() => {
        if (i < 0) {
        } else if (i > this.systemSelectCount + 1) {
          this.powernumber = snumber[snumber.length - 1];
          clearInterval(localinterval);
          setTimeout(() => {
            this.playticketStatus = 0;
            this.lastsystemSelectNumbers = this.systemSelectNumbers.concat().sort((a, b) => a - b);
            console.log('lastsystemStatus', this.lastsystemSelectNumbers);
            this.systemSelectNumbers = [];
            this.tickets_count = data.tickets_played;
            // this.tickets = [];
            // this.stickets = [];
            this.isTicketbuying = false;
            this.startTimer();
          }, 3000);
          return;
        } else if (i < this.systemSelectCount) {
            this.currentSelectNumber = snumber[i];
            this.systemSelectNumbers.push(this.gbf.toint(snumber[i]));
            if (i == this.systemSelectCount - 2) {
            
            }
            this.tickets.sort((a, b) => { 
              return this.matchcount(b.n) - this.matchcount(a.n)
            });
        } else if (i == this.systemSelectCount) {
          this.playticketStatus = 2;
          // this.currentSelectNumber = 0;
          if (this.gamepayout.coins > 0 || this.gamepayout.bucks > 0) {
            this.OpenGamePayout(this.gamepayoutModal);
          }
          this.getwinnerList(this.winnertypeselect);
        }
        if(i == 15) {
          this.apiService.drawend(this.signedRequest).subscribe( async data => {
            console.log('draw end', data);
            if(data.powerups) {
              this.poweruplist = [
                ...data.powerups.max_draws.rates, ...data.powerups.max_tickets.rates,
                ...data.powerups.max_wager.rates, ...data.powerups.receive_limit.rates,
                ...data.powerups.send_limit.rates
              ]
            }
            this.jackpotAmount = _Bigint(data.jackpot_amount);
            this.gameNumber = data.draw_info.draw_id;
            this.playerDetails.user_coin = data.vitals.currency.credits;
            this.playerDetails.user_bucks = data.vitals.currency.diamonds;
            this.playerDetails.user_ruby = data.vitals.currency.points;
            this.hotNumber = data.draw_info.hot_cold.hot;
            this.coldNumber = data.draw_info.hot_cold.cold;
            this.gamepayout.coins = data.credits_won;
            this.gamepayout.bucks = data.points_won;
            if(!data.draw_stats) {
              this.currentuserplaystatus.playerOnline = data.draw_stats.players;
              this.currentuserplaystatus.allLiveticket = data.draw_stats.tickets;
              this.currentuserplaystatus.allChipWaged = data.draw_stats.chips;
            }
            if(data.leaderboard) {
              this.leaderboard = data.leaderboard;
              if(data.leaderboard.last_draw_winners) {
                this.winnerlist.Last_Draw_Winners = data.leaderboard.last_draw_winners.data;
                this.meta_winnerlist.Last_Draw_Winners = data.leaderboard.last_draw_winners;
              }
              if(data.leaderboard.highest_wagered_today) {
                this.winnerlist.Hightest_Wagered = data.leaderboard.highest_wagered_today.data;
                this.meta_winnerlist.Hightest_Wagered = data.leaderboard.highest_wagered_today;
              }
              if(data.leaderboard.xp_leaders) {
                this.winnerlist.XP_Level_Leaders = data.leaderboard.xp_leaders.data;
                this.meta_winnerlist.XP_Level_Leaders = data.leaderboard.xp_leaders;
              }
              if(data.leaderboard.powerball_winners) {
                this.winnerlist.Powerball_Winner = data.leaderboard.powerball_winners.data;
                this.meta_winnerlist.Powerball_Winner = data.leaderboard.powerball_winners;
              }
              if(data.leaderboard.jackpot_winners) {
                this.winnerlist.resent_jp = data.leaderboard.jackpot_winners.data;
                this.meta_winnerlist.resent_jp = data.leaderboard.jackpot_winners;
              }
              if(data.leaderboard.jackpot_winners_today) {
                this.winnerlist.biggest_jp_today = data.leaderboard.jackpot_winners_today.data;
                this.meta_winnerlist.biggest_jp_today = data.leaderboard.jackpot_winners_today;
              }
              if(data.leaderboard.powerball_winners_comp) {
                this.winnerlist.Powerball_Comp_Winner = data.leaderboard.powerball_winners_comp.data;
                this.meta_winnerlist.Powerball_Comp_Winner = data.leaderboard.powerball_winners_comp;
              }
              if(data.leaderboard.highest_wagered_1) {
                this.winnerlist.wager_comp_one = data.leaderboard.highest_wagered_1.data;
                this.meta_winnerlist.wager_comp_one = data.leaderboard.highest_wagered_1;
              }
              if(data.leaderboard.highest_wagered_2) {
                this.winnerlist.wager_comp_two = data.leaderboard.highest_wagered_2.data;
                this.meta_winnerlist.wager_comp_two = data.leaderboard.highest_wagered_2;
              }
              if(data.leaderboard.highest_wagered_3) {
                this.winnerlist.wager_comp_three = data.leaderboard.highest_wagered_3.data;
                this.meta_winnerlist.wager_comp_three = data.leaderboard.highest_wagered_3;
              }
              if(data.leaderboard.highest_wagered_4) {
                this.winnerlist.wager_comp_four = data.leaderboard.highest_wagered_4.data; 
                this.meta_winnerlist.wager_comp_four = data.leaderboard.highest_wagered_4; 
              }
              if(data.leaderboard.jackpot_winners_alltime) {
                this.winnerlist.biggest_jp_alltime = data.leaderboard.jackpot_winners_alltime.data;
                this.winnerlist.biggest_jp_alltime = data.leaderboard.jackpot_winners_alltime;
              }
              if(data.leaderboard.match_comp_1_1) {
                this.winnerlist.com_one = data.leaderboard.match_comp_1_1.data;
                this.meta_winnerlist.com_one = data.leaderboard.match_comp_1_1;
              }
              if(data.leaderboard.match_comp_1_2) {
                this.winnerlist.com_two = data.leaderboard.match_comp_1_2.data;
                this.meta_winnerlist.com_two = data.leaderboard.match_comp_1_2;
              }
              if(data.leaderboard.match_comp_1_3) {
                this.winnerlist.com_three = data.leaderboard.match_comp_1_3.data;
                this.meta_winnerlist.com_three = data.leaderboard.match_comp_1_3;
              }
              if(data.leaderboard.match_comp_1_4) {
                this.winnerlist.com_four = data.leaderboard.match_comp_1_4.data;
                this.meta_winnerlist.com_four = data.leaderboard.match_comp_1_4;
              }
              if(data.leaderboard.match_comp_1_5) {
                this.winnerlist.com_five = data.leaderboard.match_comp_1_5.data;
                this.meta_winnerlist.com_five = data.leaderboard.match_comp_1_5;
              }
      
              if(data.leaderboard.match_comp_1_6) {
                this.winnerlist.com_six = data.leaderboard.match_comp_1_6.data; 
                this.meta_winnerlist.com_six = data.leaderboard.match_comp_1_6; 
              }
              if(data.leaderboard.match_comp_1_7) { 
                this.winnerlist.com_seven = data.leaderboard.match_comp_1_7.data; 
                this.meta_winnerlist.com_seven = data.leaderboard.match_comp_1_7; 
              }
            }
            setTimeout(() => {
              this.time = data.draw_info.timeleft;
            }, 15000);
          });
        }
        i++;
      }, 3000);
    })
  }

  // Game timer
  async startTimer() {
    this.isPlay = true;
    this.JackportIncrementStart();
    this.interval = setInterval(() => {
      if (this.time < 0) {
        clearInterval(this.interval);
        return;
      } else if (this.time == 0) {
        clearInterval(this.interval);
        this.JackportIncrementStart();
        this.play();
        return;
      } else if (this.time <= 10) {
        this.isPlay = false;
        this.isBuyingTicket = false;
        this.selectedNumbers = [];
        if (this.time == 10) {
          this.JackportIncrementStart();
        }
      }
      this.time--;
    }, 1000);
  }

  // Jackport Increment
  JackportIncrementStart() {
    // return;
    this.jackpotinterval = setInterval(() => {
      if (this.jackpotBegin.notEquals(this.jackpotAmount))  {
        const x = this.jackpotAmount.subtract(this.jackpotBegin).toString().length;
        if (this.jackpotBegin.lesser(this.jackpotAmount)) {
          if (this.jackpotAmount.subtract(this.jackpotBegin).lesser(_Bigint(10))) {
            this.jackpotBegin = this.jackpotBegin.add(1);
          } else {
            this.jackpotBegin = this.jackpotBegin.add(_Bigint(10).pow(x - 2));
          }
        } else {
          if (this.jackpotBegin.subtract(this.jackpotAmount).lesser(_Bigint(10))) {
            this.jackpotBegin = this.jackpotBegin.subtract(1);
          } else {
            this.jackpotBegin = this.jackpotBegin.subtract(_Bigint(10).pow(x - 2));
          }
        }
      }
      if (this.jackpotBegin == this.jackpotAmount) {
        clearInterval(this.jackpotinterval);
      }
    }, 10);
  }

  // Background Event
  backgroundfunction() {
    this.backgroundinterval = setInterval(() => {
      this.getNewChat();
    }, this.backgroundfunctionIntervalTime);
  }

  // Current Game Details
  async currentGameDetails() {
    const gameDetails = await this.apiService.draw(this.signedRequest).toPromise();
    console.log(gameDetails)
  }

  // Current Game Result
  GetCurrentGameResult() {
    this.gamepayout.coins = 0;
    this.gamepayout.bucks = 0;
    this.apiService.draw(this.signedRequest).subscribe(data => {
        if(data.tickets.length > 0) {
          this.gamepayout.coins = data.credits_won;
          this.gamepayout.bucks = data.points_won;
          this.tickets = data.tickets;
          this.stickets = data.sidebets;
        }
    })
  }

  chooseNumber(num: any) {console.log(this.isPlay)
    if (!this.isPlay) {
      return;
    } else if (this.sidebettype == 'power') {
      this.selectedSideBetNumbers = [num];
    } else {
      this.playtickets = this.minplaytickets;
      this.buyticketnav = 1;
      this.isBuyingTicket = true;
      this.systemSelectNumbers = [];
      if (_.includes(this.selectedNumbers, num)) {
        this.selectedNumbers = this.selectedNumbers.filter(x => x != num);
      } else {
        if (this.selectedNumbers.length >= this.maxSelectableCount) {
          this.selectedNumbers.shift();
        }
        this.selectedNumbers.push(num);
      }
    }
  }

  createTicket() {
    if (this.isTicketbuying) {
      return;
    }
    this.isTicketbuying = true;
    let tck = {};

    if (this.selectedNumbers.length >= this.minSelectableCount) {

    } else if (this.playtickets > 1 && this.quickpickNo > 0) {
     
    } else {
      this.showmessage('Error', 'Please Select Numbers');
      this.isTicketbuying = false;
      return;
    }
    this.apiService.buyBetTicket(this.signedRequest,{
        draw_id: this.gameNumber,
        draws: this.draws,
        multi: { tickets: this.playtickets, numbers: this.quickpickNo },
        type: "regular",
        wager: this.wager
    }).subscribe(data => {
        if(typeof data.error !== 'undefined') {
          this.showmessage('Error...', data.error.msg);
          return;
        }
        if (typeof data.vitals.xp_level !== "undefined") {
          if (this.playerDetails.game_leave < data.vitals.xp_level) {
            this.playerDetails.user_bucks = data.vitals.currency.diamonds;
            this.OpenLevelUp(this.levelupModal);
          }
        }
        
        if(data.tickets.length > 0) {
          this.tickets = _.unionBy(data.tickets, this.tickets);
          this.tickets.sort((a, b) => { return b.n.length - a.n.length })
        }
        this.tickets_count =  data.total_tickets;
        this.playerDetails.user_coin = data.vitals.currency.credits;
        this.playerDetails.user_bucks = data.vitals.currency.diamonds;
        this.selectedNumbers = [];
        this.isTicketbuying = false;
    }, (error) => {
      this.isTicketbuying = false;
    });
  }

  

  powerdrawShow(powerdrawNumber) {
    this.powerdraw = powerdrawNumber;
    setTimeout(() => {
      this.powerdraw = 0;
    },
      3000);
  }

  changewager(chng: number) {
    this.wager = this.gbf.toint(this.wager);
    if (this.wager + chng >= this.playerDetails.user_min_wager && this.wager + chng <= this.playerDetails.user_max_wager) {
      this.wager = this.wager + chng;
    }
    // else if (this.wager + chng < this.playerDetails.user_min_wager) {
    //   this.wager = this.playerDetails.user_max_wager;
    // } else if (this.wager + chng > this.playerDetails.user_max_wager) {
    //   this.wager = this.playerDetails.user_min_wager;
    // }
  }

  changedraws(chng: number) {
    if (this.draws + chng >= this.mindraws && this.draws + chng <= this.playerDetails.user_max_draw) {
      this.draws = this.draws + chng;
    }
    // else if (this.draws + chng < this.mindraws) {
    //   this.draws = this.playerDetails.user_max_draw;
    // } else if (this.draws + chng > this.playerDetails.user_max_draw) {
    //   this.draws = this.mindraws;
    // }
  }

  changetickets(chng: number) {
    this.playtickets = this.gbf.toint(this.playtickets.toString());
    if (this.playtickets + chng >= this.minplaytickets && this.playtickets + chng <= this.playerDetails.user_max_ticket) {
      this.playtickets = this.playtickets + chng;
    }
  }

  schangewager(chng: number) {
    if (this.swager + chng >= this.playerDetails.user_min_wager && this.swager + chng <= this.playerDetails.user_max_wager) {
      this.swager = this.swager + chng;
    }
    // else if (this.wager + chng < this.playerDetails.user_min_wager) {
    //   this.wager = this.playerDetails.user_max_wager;
    // } else if (this.wager + chng > this.playerDetails.user_max_wager) {
    //   this.wager = this.playerDetails.user_min_wager;
    // }
  }

  schangedraws(chng: number) {
    if (this.sdraws + chng >= this.smindraws && this.sdraws + chng <= this.playerDetails.user_max_draw) {
      this.sdraws = this.sdraws + chng;
    }
    // else if (this.draws + chng < this.mindraws) {
    //   this.draws = this.playerDetails.user_max_draw;
    // } else if (this.draws + chng > this.playerDetails.user_max_draw) {
    //   this.draws = this.mindraws;
    // }
  }

  clearvalues() {
    this.quickpickNo = 0;
    this.wager = this.gbf.toint(this.playerDetails.user_min_wager);
    this.draws = this.gbf.toint(this.mindraws);
    this.playtickets = this.gbf.toint(this.minplaytickets);
    this.quickpick();
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Side Bet function
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  sideBetnNumberShow($event, betType) {
    if (betType == 'odd') {
      this.sidebetnumbers = _.range(1, this.totalNumberCount + 1, 2);
    } else if (betType == 'even') {
      this.sidebetnumbers = _.range(2, this.totalNumberCount + 1, 2);
    } else if (betType == 'north') {
      this.sidebetnumbers = _.range(1, this.divNumber + 1, 1);
    } else if (betType == 'south') {
      this.sidebetnumbers = _.range(this.divNumber + 1, this.totalNumberCount + 1, 1);
    } else if (betType == 'east') {
      this.sidebetnumbers = [1, 2, 3, 4, 5, 11, 12, 13, 14, 15, 21, 22, 23, 24, 25, 31, 32, 33, 34, 35, 41, 42, 43, 44, 45, 51, 52, 53, 54, 55, 61, 62, 63, 64, 65, 71, 72, 73, 74, 75];
    } else if (betType == 'west') {
      this.sidebetnumbers = [6, 7, 8, 9, 10, 16, 17, 18, 19, 20, 26, 27, 28, 29, 30, 36, 37, 38, 39, 40, 46, 47, 48, 49, 50, 56, 57, 58, 59, 60, 66, 67, 68, 69, 70, 76, 77, 78, 79, 80];
    } else if (betType == 'power') {
      this.sidebetnumbers = [this.powernumber];
    } else if (betType == 'hot') {
      this.sidebetnumbers = this.hotNumber;
    } else if (betType == 'cold') {
      this.sidebetnumbers = this.coldNumber;
    } else if (betType == 'lastresult') {
      this.sidebetnumbers = this.lastsystemSelectNumbers;
    }
  }

  sideBetnNumberclear($event) {
    this.sidebetnumbers = [];
  }

  setSideBet(bettype) {
    this.sidebettype = bettype;
    if (this.sidebettype == 'odd') {
      this.selectedSideBetNumbers = _.range(1, this.totalNumberCount + 1, 2);
    } else if (this.sidebettype == 'even') {
      this.selectedSideBetNumbers = _.range(2, this.totalNumberCount + 1, 2);
    } else if (this.sidebettype == 'north') {
      this.selectedSideBetNumbers = _.range(1, this.divNumber + 1, 1);
    } else if (this.sidebettype == 'south') {
      this.selectedSideBetNumbers = _.range(this.divNumber + 1, this.totalNumberCount + 1, 1);
    } else if (this.sidebettype == 'east') {
      this.selectedSideBetNumbers = [1, 2, 3, 4, 5, 11, 12, 13, 14, 15, 21, 22, 23, 24, 25, 31, 32, 33, 34, 35, 41, 42, 43, 44, 45, 51, 52, 53, 54, 55, 61, 62, 63, 64, 65, 71, 72, 73, 74, 75];
    } else if (this.sidebettype == 'west') {
      this.selectedSideBetNumbers = [6, 7, 8, 9, 10, 16, 17, 18, 19, 20, 26, 27, 28, 29, 30, 36, 37, 38, 39, 40, 46, 47, 48, 49, 50, 56, 57, 58, 59, 60, 66, 67, 68, 69, 70, 76, 77, 78, 79, 80];
    } else if (this.sidebettype == 'power') {
      this.selectedSideBetNumbers = [];
    }
  }

  clearSideBet() {
    this.sidebettype = 'CHOOSE A SIDEBET!';
  }

  createSideTicket() {

    if (this.isTicketbuying) {
      return;
    }

    this.isTicketbuying = true;
    if (this.sidebettype == 'CHOOSE A SIDEBET!' && this.selectedSideBetNumbers.length == 0) {
      return;
    }
   
    this.apiService.sideBet(this.signedRequest,{
      bet_value: this.sidebetType[this.sidebettype].value,
      draw_id: this.gameNumber,
      draws: this.sdraws,
      type: this.sidebetType[this.sidebettype].type,
      wager: this.swager
    }).subscribe(data => {
        this.playerDetails.user_coin = data.vitals.currency.credits;
        this.doughnutChartData = [this.playerDetails.user_xp, this.playerDetails.xp_needed - this.playerDetails.user_xp];
        this.stickets = _.unionBy({ ...data.ticket } , this.stickets);
        this.selectedNumbers = [];
        this.isTicketbuying = false;
    });
  }

  clearsvalues() {
    this.swager = this.gbf.toint(this.playerDetails.user_min_wager);
    this.sdraws = this.gbf.toint(this.smindraws);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // END Side Bet function
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  quickpick() {
    if (this.playtickets == 1) {
      this.selectedNumbers = _.sampleSize(this.numberset, this.quickpickNo);
    } else {
      this.selectedNumbers = [];
    }
  }

  changequickpickcount(chng: number) {
    if (this.quickpickNo + chng >= 0 && this.quickpickNo + chng <= this.systemSelectCount) {
      this.quickpickNo = this.quickpickNo + chng;
    } else if (this.quickpickNo + chng < 0) {
      this.quickpickNo = this.systemSelectCount;
    } else if (this.quickpickNo + chng > this.systemSelectCount) {
      this.quickpickNo = 0;
    }
    this.quickpick();
  }

  openBuyTicket(tabno: number) {
    if (!this.isBuyingTicket && this.time <= 10) {
      return;
    }
    this.isBuyingTicket = !this.isBuyingTicket;
    if (this.isBuyingTicket && tabno > 0) {
      this.buyticketnav = tabno;
    }

  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Style and Animation function
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  SytemSelectNumberStage(num: any): string {
    if (this.selectedpowernumber == num && this.currentSelectNumber == num) {
      return 'PowerSelectNumber';
    } else if (this.currentSelectNumber == num) {
      return 'SelectNumber';
    } else {
      return 'NotSelectNumber';
    }
  }

  numberClass(num: any): string {
    if (this.selectedSideBetNumbers.length > 0 && _.includes(this.selectedSideBetNumbers, num) && this.sidebettype == 'power') {
      return 'side_bet_powernumber';
    } else if (this.sidebetnumbers.length > 0 && this.powernumber == num) {
      return 'side_bet_powernumber';
    } else if (_.includes(this.sidebetnumbers, num)) {
      return 'side_bet_number';
    } else if (this.selectedpowernumber == num && _.includes(this.systemSelectNumbers, num)) {
      return 'system_selected_power_number';
    } else if (_.includes(this.systemSelectNumbers, num)) {
      return 'system_selected_number';
    } else if (_.includes(this.selectedNumbers, num) && this.sidebetnumbers.length == 0) {
      return 'user_selected_number';
    } else {
      return 'normal_number';
    }
  }

  ticketnumberClass(num: any): string {
    if (this.selectedpowernumber == num && _.includes(this.systemSelectNumbers, num)) {
      return 'power';
    } else if (_.includes(this.systemSelectNumbers, num)) {
      return 'selected';
    } else {
      return 'normal';
    }
  }

  getTicketNumbers(arr: any): number {
    var num = 0;
    arr.map(item => {
      if(_.includes(this.systemSelectNumbers, item)) {
        num = num + 1;
      }
    })

    return num;
    // return arr.length;
  }

  sidebetnumberClass(num: any): string {

    if (this.selectedpowernumber == num && _.includes(this.systemSelectNumbers, num)) {
      return 'power';
    } else if (_.includes(this.systemSelectNumbers, num)) {
      return 'selected';
    } else {
      return 'normal';
    }
  }

  prevnumberClass(num: any, winnumbers): boolean {
    if (_.includes(winnumbers, num)) {
      return true;
    } else {
      return false;
    }
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // END Style and Animation function
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Prize events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  OpenPrize(content) {
   // this.GetPriceStageList();
    this.prizeWindowType = 0;
    this.modalService.open(content, { windowClass: 'modal-prize', backdrop: 'static', keyboard: false });
  }

  OpenPrizeCategory(pstageid) {
    this.apiService.getpricecategorylist({ stage_id: pstageid, player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.prizecategorys = data.main_data.prize_category;
        this.prizebackcolor = this.prizelevels.filter(x => x.stage == pstageid)[0].stage_color;
        this.prizeWindowType = 1;
        this.prizestageId = pstageid;
      }
    });
  }

  OpenPrizeItems(pcat) {
    const _fdata = {
      stage_id: this.prizestageId,
      category_name: pcat,
      player_id: this.playerDetails.player_id
    };
    this.apiService.getpricelist(_fdata).subscribe(data => {
      if (data.sta) {
        this.prizeitems = data.main_data.prize;
        this.prizeWindowType = 2;
        this.prizeselectcategory = pcat;
      }
    });
  }

  GetPriceStageList() {
    this.apiService.getpricestagelist({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.prizelevels = data.main_data.prize_level;
      }
    });
  }
  BuyPrize(item) {
    const _buyData = {
      prize: item.prize_id,
      player_id: this.playerDetails.player_id
    };
    this.apiService.purchaseprize(_buyData).subscribe(data => {
      if (data.sta) {
        this.prizeitems.find(p => p.prize_id == item.prize_id).is_purchased = 1;
        this.playerDetailsFill(data.main_data);
      } else {
        this.showmessage('Error', data.message);
      }
    });

  }

  BuyAllPrize(item) {
    const _buyData = {
      category: item[0].category,
      leave: item[0].stage,
      player_id: this.playerDetails.player_id
    };
    this.apiService.purchaseprizeall(_buyData).subscribe(data => {
      if (data.sta) {
        for (let i = 0; i < this.prizeitems.length; i++) {
          this.prizeitems[i].is_purchased = 1;
        }
        this.playerDetailsFill(data.main_data);
      } else {
        this.showmessage('Error', data.message);
      }
    });

  }
  BackPrize() {
    this.prizeWindowType = this.prizeWindowType - 1;
    if (this.prizeWindowType == 0) {
      this.GetPriceStageList();
    } else if (this.prizeWindowType == 1) {
      this.OpenPrizeCategory(this.prizestageId);
    }
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // END Prize events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // PowerUp events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  powerupselect(type, obj) {
    this.powerupselected[type] = obj;
  }

  powerupconfirmation(pwup, content, type = 0) {
    this.powerupforbuy = pwup;
    if(type) {
      this.powerupforbuy = { power_up_id: (type-1), type: 'chips', level: null, cost: pwup.diamonds, value: pwup.chips };
    }

    this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: 'modal-powerup-confirm', });
  }

  powerupbuy(obj: HTMLDivElement, modal) {
    obj.innerHTML = '';
    this.apiService.purchasepowerup(this.signedRequest, { id: this.powerupforbuy.power_up_id, type:  this.powerupforbuy.type }).subscribe(data => {
        
        obj.innerHTML = '<div class="powerup_purchase_msg">POWERUP SUCCESSFULLY RECEIVED. GOOD LUCK ' + this.playerDetails.name.toUpperCase() + '!</div>';
        setTimeout(() => {
          modal.close('Close click');
        },
          1000);
        //obj.innerHTML = '<div class="powerup_purchase_error">' + data.message + '!</div>';
        //setTimeout(() => {
         // modal.close('Close click');
        //},
        //  1000);
    });

  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // END PowerUp events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Donation events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  OpenDonate(content, chat) {
    this.donationmodalstage = 0;
    this.donationDetails.donatePlayer_name = chat.name;
    this.donationDetails.donatePlayer_id = chat.user_id;
    this.donationDetails.donatePlayer_pic = chat.pic;
    this.donationDetails.donateAmt = this.minDonateAmt;
    this.modalService.open(content, { windowClass: 'modal-donate', backdrop: 'static', keyboard: false });
  }
  changeDonation(chng: number) {
    if (this.donationDetails.donateAmt + chng >= this.minDonateAmt && this.wager + chng <= this.maxDonateAmt) {
      this.donationDetails.donateAmt = this.donationDetails.donateAmt + chng;
    }
  }
  sendDonation(modal) {
    const _donation = {
      receiver: this.donationDetails.donatePlayer_id,
      amount: this.donationDetails.donateAmt
    };
    this.donationmodalstage = 1;
    this.apiService.senddonation(this.signedRequest, _donation).subscribe(data => {
      if (data.status) {
        this.donationmodalstage = 2;
        this.playerDetails.user_coin = data.credits;
        setTimeout(() => {
          modal.close('Close click');
        },
          1000);
      } else {
        this.donationmodalstage = 3;
      }
    });

  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // END Donation events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // other events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  OpenPayout(content, pickcount, wager) {console.log(this.selectedNumbers)
    if(pickcount === 0) {
      return;
    }
    this.selectedpayoutlist.nocount = pickcount;
    this.selectedpayoutlist.payoutlist = Object.keys(this.payoutlist[pickcount]).map(i=> {
      return {
        pick: pickcount,
        matches: i,
        points: this.payoutlist[pickcount][i].points,
        credits: this.payoutlist[pickcount][i].credits,
        wagers: wager,
        chips: wager * this.payoutlist[pickcount][i].credits,
        prizeBuks: wager * this.payoutlist[pickcount][i].points,
      };
    });
    this.modalService.open(content, { windowClass: 'modal-payout', backdrop: 'static', keyboard: false });
  }

  OpenPowerUp(content) {//modalpowerups
    this.apiService.modalpowerups(this.signedRequest).subscribe(async data => {
      this.chipspoweruplist = data.chips_rates;
      Object.keys(data.powerups).map(item => {
        this.poweruplist.push(
          ...data.powerups[item].rates
        )
      })
   
      this.modalService.open(content, { windowClass: 'modal-powerup', backdrop: 'static', keyboard: false });
    })
  }

  OpenAchievement(content) {
    this.apiService.getachivemnt(this.signedRequest).subscribe(data => {
      Object.keys(data.MISC).map(element => {
        this.achivemntlist_misc.push({
          credits: Number(data.MISC[element]?.data.credits),
          current: Number(data.MISC[element]?.data.current),
          diamonds: Number(data.MISC[element]?.data.diamonds),
          id: Number(data.MISC[element]?.data.id),
          level: Number(data.MISC[element]?.data.level),
          levelup_needed: Number(data.MISC[element]?.data.levelup_needed),
          needed: Number(data.MISC[element]?.data.needed),
          numbers: Number(data.MISC[element]?.data.numbers),
          trophies: Number(data.MISC[element]?.data.trophies),
          pt: Number(data.MISC[element]?.data.current)*100/Number(data.MISC[element]?.data.needed)
        });
      });
      Object.keys(data.MATCHES).map(element => {
        this.achivemntlist_matches.push({
          credits: Number(data.MATCHES[element]?.data.credits),
          current: Number(data.MATCHES[element]?.data.current),
          diamonds: Number(data.MATCHES[element]?.data.diamonds),
          id: Number(data.MATCHES[element]?.data.id),
          level: Number(data.MATCHES[element]?.data.level),
          levelup_needed: Number(data.MATCHES[element]?.data.levelup_needed),
          needed: Number(data.MATCHES[element]?.data.needed),
          numbers: Number(data.MATCHES[element]?.data.numbers),
          trophies: Number(data.MATCHES[element]?.data.trophies),
          pt: Number(data.MATCHES[element]?.data.current)*100/Number(data.MATCHES[element]?.data.needed)
        });
      });

      console.log(this.achivemntlist_misc);
      this.modalService.open(content, { windowClass: 'modal-achievement', backdrop: 'static', keyboard: false });
    });
  }

  OpenDiamon(content) {
    this.apiService.modaldiamonds(this.signedRequest).subscribe(data => {
      console.log(data);
    })
    this.modalService.open(content, { windowClass: 'modal-diamond', backdrop: 'static', keyboard: false });
  }

  OpenLevelUp(content) {
    this.modalService.open(content, { windowClass: 'modal-levelup', backdrop: 'static', keyboard: false });
  }

  OpenGamePayout(content) {
    const modal = this.modalService.open(content, { windowClass: 'modal-gamepayout', backdrop: 'static', keyboard: false });
    setTimeout(() => {
      modal.close('Close click');
    }, 3000);
  }

  getwinnerList(id) {
    console.log(id);
    this.winnertypeselect = id;
    this.winnerData = this.leaderboard[id];
    console.log(this.winnerData)
    // var key = this.winnerListType.filter(x => x.id == id)[0].key;
    // this.winnertypeheader = this.meta_winnerlist[key].title;
    // this.winnertypeheaderDetails = this.meta_winnerlist[key].desc;
    // if(id == 3) {
    //   this.winnertypeheader = "POWER BALL";
    // }
  }

  help() {
    this.introJS.setOptions({
      steps: [
        {
          element: '#help_diamonds',
          intro: this.helphtml('Diamonds', 'assets/images/bc_deepred_img.png', 'Earn Gems by playing tickets and matching the correct numbers!'),
          position: 'bottom'
        },
        {
          element: '#help_conins',
          intro: this.helphtml('Coins', 'assets/images/bc_red_img.png', 'Coins are what you use to buy tickets with! Earn coins with bonuses and by winning tickets! Click the Coin to buy more with your bolts!!'),
          position: 'right'
        },
        {
          element: '#help_bugs',
          intro: this.helphtml('Bugs', 'assets/images/bc_red_img.png', 'Bugs are used for purchasing Powerups. There are many different Powerups you can use including more Coins, more draws and much more!'),
          position: 'bottom'
        },
        {
          element: '#help_board_list',
          intro: this.helphtml('THE BOARD', '', 'This is where you choose your numbers and also where the draw numbers popup. Draws are every 90 seconds.'),
          position: 'right'
        },
        {
          element: '#help_ticket_booth',
          intro: this.helphtml('TICKET BOOTH', '', 'This is where you buy your tickets. You can play 1 ticket or multiple tickets at a time.'),
          position: 'right'
        }
      ],
      showProgress: true,
      showStepNumbers: false,
    });

    this.introJS.start();

  }

  helphtml(headerTitle, headerImage, bodytext) {
    let html = '<div align="center">';
    html += '<div class="tutorial_header" >';
    html += '<span class="pulsate" >';
    if (headerImage.length > 0) {
      html += '<img src="' + headerImage + '">';
    }
    html += headerTitle;
    html += '</span>';
    html += '</div>';
    html += '<div class="tutorial_body">';
    html += bodytext;
    html += '</div>';
    html += '</div>';
    return html;
  }

  numformat(num) {
    try {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch (e) {
      return 0;
    }
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // end other events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Admin Donation events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  OpenAdmin(content, chat) {console.log(chat);
    this.adminPlayerSearch(chat.user_id);
    this.modalService.open(content, { windowClass: 'modal-admin', backdrop: 'static', keyboard: false });
  }
  adminPlayerSearch(playerid) {
    this.apiService.playerDetails(this.signedRequest, playerid).subscribe(data => {
      this.adminsearchplayerDetails = data;
      console.log(this.adminsearchplayerDetails);
    });
  }
  admindonation(playerid, dtype, donateAmt) {
    if (donateAmt <= 0) {
      return;
    }
    const _donation = {
      player_to_id: playerid,
      amount: donateAmt
    };
    this.donationmodalstage = 1;
    this.apiService.senddonation(this.signedRequest, _donation).subscribe(data => {
      this.adminPlayerSearch(playerid);
      if (data.status) {
        this.playerDetails.user_coin = data.credits;
        this.showmessage('Send', data.message);
      } else {
        this.showmessage('Error', data.message);
      }
    });
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // End Admin Donation events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // chat events
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event) {
    this.showEmojiPicker = false;
    // const { message } = this;
    // const text = `${message}${event.emoji.native}`;
    const startPos = this.chatmsg.nativeElement.selectionStart;
    this.chatmsg.nativeElement.focus();
    this.chatmsg.nativeElement.value = this.chatmsg.nativeElement.value.substr(0, this.chatmsg.nativeElement.selectionStart)
      + event.emoji.native + this.chatmsg.nativeElement.value.substr(this.chatmsg.nativeElement.selectionStart, this.chatmsg.nativeElement.value.length);
    this.chatmsg.nativeElement.selectionStart = startPos + event.emoji.native.length;
    this.chatmsg.nativeElement.selectionEnd = startPos + event.emoji.native.length;
    this.chatmsg.nativeElement.focus();
    console.log(event.emoji.native.length);

    // this.message = this.message + event.emoji.native;
  }
  sendchat() {
    if (this.chatsending) {
      return;
    }

    this.chatsending = true;
    this.showEmojiPicker = false;
    this.message = this.chatmsg.nativeElement.value;
    const msg = this.gbf.replaceemoji(this.message.trim());
    this.message = '';
    if (msg.length > 0) {
      const _chat = {
        room_id: this.chattype,
        last_chat_id: this.chattype == 1?this.chats[0].chat_id:this.gameChats[0].chat_id,
        message: msg
      };
      this.apiService.createchat(this.signedRequest, _chat).subscribe(data => {console.log(data);
        data.chat.map((item) => {
          var newDate = new Date();
          var current_timestamp = newDate.getTime()
          var res = Math.abs(current_timestamp - item.ts*1000);
          var minutes = Math.floor(res / 60) % 60;
          item.ts = minutes;
          item.b_bg = '#'+item.b_bg;
          // var h = item.l.split(':')[0];console.log(Number(h))
          // var m = item.l.split(':')[1].split(' ');
          // if(m[1] == 'pm') {
          //   var time_diff = (480-this.timeZoneOffset)/60;
          //   if((time_diff + Number(h) + 12) > 24) {
          //     m[1] = 'am';
          //     h = (time_diff + Number(h) + 12) - 24;
          //   } else {
          //     h = (time_diff + Number(h));
          //   }
          // } else {
          //   var time_diff = (480-this.timeZoneOffset)/60;
          //   if((time_diff + Number(h)) > 12) {
          //     m[1] = 'pm';
          //     h = (time_diff + Number(h)) - 12;
          //   } else {
          //     h = (time_diff + Number(h));
          //   }
  
          // }
          // item.l = h +':'+m[0] +' '+m[1];
          return item;
        })
        if(data.status) {
          if(this.chattype == 1) {
            this.chats = _.unionBy(data.chat, this.chats, 'chat_id').sort((a, b) => {
              return b.chat_id - a.chat_id;
            });
          } else {
            this.gameChats = _.unionBy(data.chat, this.gameChats, 'chat_id').sort((a, b) => {
              return b.chat_id - a.chat_id;
            });
          }
          if (this.chattype == 1) {
            this.lastChatId = this.chats[0].chat_id;
          } else {
            this.lastChatId = this.gameChats[0].chat_id;
          }
          this.chatsending = false;
        }
        
      });
    }

  }
  getAllChat() {

    let _chat = {
      lastchatid: 0,
      type: 0,
      count: this.initialchatCount,
      player_id: this.playerDetails.player_id,
      signed_request: this.signedRequest,
      testing: 1  
  };

    this.apiService.getchat(_chat).subscribe(data => {
      this.chats = data.data;
      if (this.chats.length > 0) {
        this.lastChatId = this.chats[0].chat_id;
      }
      this.jackpotAmount = _Bigint(data.jp);
    });
  }
  getNewChat() {
    let _chat = {
      lastchatid: this.lastChatId,
      type: 0,
      count: 0,
      player_id: this.playerDetails.player_id,
      signed_request: this.signedRequest,
      testing: 2
  
  };
    this.apiService.getchat(_chat).subscribe(data => {
      this.chats = _.unionBy(data.data, this.chats, 'chat_id').sort((a, b) => {
        return b.chat_id - a.chat_id;
      });
      if (this.chats.length > 0) {
        this.lastChatId = this.chats[0].chat_id;
      }
      this.jackpotAmount = _Bigint(data.jp);
    });
  }
  ignoreChat(ignoreId) {
    const _ignore = {
      ignore_id: ignoreId
    };
    this.apiService.setchatignore(this.signedRequest, _ignore).subscribe(data => {
      if (data.status) {
        this.chats = this.chats.filter(x => x.player_id != ignoreId);
      }
    });
  }
  deleteChat(chatId) {

    this.apiService.deletechat(this.signedRequest, chatId).subscribe(data => {
      if (data.status) {
        this.chats = this.chats.filter(x => x.chat_id != chatId);
      } else {
        this.showmessage('error', data.error.msg);
      }
    });
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // chat end
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // stat modal
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  
  OpenStat(content) {
     this.apiService.modalstats(this.signedRequest).subscribe(async data => {
       data?.draw_results && data?.draw_results.map(item => {
        var sidebet_ticket_count = data.sidebets[item.draw_id]?.length;
        if(sidebet_ticket_count == undefined) {
          sidebet_ticket_count = 0;
        }
        var tickets_count = sidebet_ticket_count + item.tickets_played;
        this.recentdraws.push({
          draw: item.draw_id,
          tickets: tickets_count,
          wagered: item.wager,
          prize_bucks_won: item.points_won,
          chips_won: item.credits_won
        })
       })
       this.jackpots = data.jackpots
       data.purchases && data.purchases.map(purchase => {
        var total_draws = purchase['ld'] - purchase['fd'] + 1;
        var total_wagered = purchase['w'] * total_draws * purchase['t'];
        this.recentpurchases.push({
          wager: purchase.w,
          first_draw: purchase.fd,
          last_draw: purchase.ld,
          total_draws: total_draws,
          tickets: purchase.t,
          number: purchase.n,
          wager_p_draw: purchase.w * purchase.t,
          total_cost: total_wagered
        })
       })
       
       this.recentsidedraws = [];
        Object.keys(data.sidebets).forEach(element => {console.log(element)
          if(data.sidebets[element].length !== 0) {
            console.log(data.sidebets[element]);
            // console.log({draw_id: element, ...data.sidebets[element] });
            this.recentsidedraws.push(...data.sidebets[element]);
          }
        });
        console.log(this.recentsidedraws);
        this.recentsidedraws.map(item=> {
          item.draw_id = Number(item.draw_id);
          return item;
        })
       this.dailieslogs = data.dailies;
       this.recentachivement = data.achievements;
       this.recentpowerups = data.powerups;
       this.chatignorlist = data.ignores;
       this.donationresendlist = data.donations.sent;
       this.donationrereceivedlist = data.donations.received
       this.modalService.open(content, { size: 'xl', windowClass: 'modal-stats', backdrop: 'static', keyboard: false });
     })
  }
  GetIgnorList() {
    this.apiService.getchatignore({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.chatignorlist = data.main_data;
      }
    });
  }
  UnignoreChat(ignorid) {
    this.apiService.deletechatignor({ ignore_id: ignorid }).subscribe(data => {
      if (data.sta) {
        this.chatignorlist = this.chatignorlist.filter(x => x.chats_ignore_id != ignorid);
      }
    });
  }
  GetDonationList() {
    this.apiService.getdonationlist({ player_id: this.playerDetails.player_id, type: 'N/A' }).subscribe(data => {
      if (data.sta) {
        this.donationresendlist = data.main_data.send;
        this.donationrereceivedlist = data.main_data.recive;
      }
    });
  }
  GetDailiesLog() {
    this.apiService.getdailieslog({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.dailieslogs = data.main_data;
      }
    });
  }
  GetRecentDraws() {
    this.apiService.getyourrecentdraws({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.recentdraws = data.main_data;
      }
    });
  }
  GetRecentSideBet() {
    this.apiService.getrecentsidebets({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.recentsidedraws = data.main_data.map(x => {
          let _bet = '';
          let _winningValue = '';

          if (x.bet_type == 'power') {
            _bet = x.numbers;
            _winningValue = x.twenty_number_set.split(', ', 20)[0];
          } else {
            _bet = this.sidebetType[x.bet_type].value;
            if (x.payout_sidebet == 1) {
              _winningValue = this.sidebetType[x.bet_type].win;
            } else {
              _winningValue = this.sidebetType[x.bet_type].lost;
            }
          }

          return {
            draw: x.game_id,
            type: this.sidebetType[x.bet_type].type,
            bet: _bet,
            winningValue: _winningValue,
            wager: x.wager,
            payout: x.payout_amount_c
          };
        });
      }
    });
  }
  GetRecentPurchases() {
    this.apiService.getrecentpurchases({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.recentpurchases = data.main_data.normal;
      }
    });
  }
  GetRecentPowerUp() {
    this.apiService.getrecentpoweruppurchase({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.recentpowerups = data.main_data;
      }
    });
  }
  GetRecentAchivement() {
    this.apiService.getstatsachivementlist({ player_id: this.playerDetails.player_id }).subscribe(data => {
      if (data.sta) {
        this.recentachivement = data.main_data;
      }
    });
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // END stat modal
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // functions
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  setdata(data: any[]) {
    data.forEach(setting => {
      if (setting.setting_key === 'jackpotBegin') {
        this.jackpotBegin = _Bigint(setting.setting_value);
      } else if (setting.setting_key === 'jackpotCurrentAmount') {
        this.jackpotAmount = _Bigint(setting.setting_value);
      } else if (setting.setting_key === 'initialchatCount') {
        this.initialchatCount = setting.setting_value;
      } else if (setting.setting_key === 'backgroundfunctionInterval') {
        this.backgroundfunctionIntervalTime = setting.setting_value;
      }
    });
  }

  timeformat(time) {
    // Hours, minutes and seconds
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';
    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
  }

  totalwager() {
    return this.wager * this.playtickets * this.draws;
  }

  stotalwager() {
    return this.swager * this.sdraws;
  }

  showmessage(title, msg) {
    this.msgModaltile = title;
    this.msgModalbody = msg;
    this.modalService.open(this.msgModal, { windowClass: 'modal-errormsg', backdrop: 'static', keyboard: false });
  }

  validatebetdetails(data: string) {
    if (data === 'regular_wager') {
      if (this.wager < this.playerDetails.user_min_wager) {
        this.wager = this.playerDetails.user_min_wager;
      } else if (this.wager > this.playerDetails.user_max_wager) {
        this.wager = this.playerDetails.user_max_wager;
      }
    } else if (data === 'regular_draw') {
      if (this.draws < this.mindraws) {
        this.draws = this.mindraws;
      } else if (this.draws > this.playerDetails.user_max_draw) {
        this.draws = this.playerDetails.user_max_draw;
      }
    } else if (data === 'regular_ticket') {
      if (this.playtickets < this.minplaytickets) {
        this.playtickets = this.minplaytickets;
      } else if (this.playtickets > this.playerDetails.user_max_ticket) {
        this.playtickets = this.playerDetails.user_max_ticket;
      }
    } else if (data === 'side_wager') {
      if (this.swager < this.playerDetails.user_min_wager) {
        this.swager = this.playerDetails.user_min_wager;
      } else if (this.swager > this.playerDetails.user_max_wager) {
        this.swager = this.playerDetails.user_max_wager;
      }
    } else if (data === 'side_draw') {
      if (this.sdraws < this.smindraws) {
        this.sdraws = this.smindraws;
      } else if (this.sdraws > this.playerDetails.user_max_draw) {
        this.sdraws = this.playerDetails.user_max_draw;
      }
    }
  }

  playerleveldetails(l) {
    return this.xplist.filter(x => x.level == l)[0];
  }

  getcurrentgstatus() {
    this.apiService.getcurrentgstatus({}).subscribe(data => {
      if (data.sta) {
        this.currentuserplaystatus.playerOnline = data.players_online;
        this.currentuserplaystatus.allLiveticket = data.all_live_ticket;
        this.currentuserplaystatus.allChipWaged = data.all_chips_wagged;
      }
    });
  }

  isEven(value) {
    if (value%2 == 0)
      return true;
    else
      return false;
  }

  matchcount(ticketnunbers: []): number {
    const matchcount = _.intersection(ticketnunbers, this.systemSelectNumbers).length;
    return matchcount;
  }

  scrollTop(element, position1, position2) {
    $(element).animate({
      scrollTop: position1
    }, 200);
    this.fbsdk.FbCanvasScroll(0, position2);
  }



  playerDetailsFill(data: any) {
      this.playerDetails.user_min_wager = this.gbf.toint(data.min_wager);
      this.playerDetails.user_max_wager = this.gbf.toint(data.max_wager);
      if(data.max_draws > 400) {
        data.max_draws = 400;
      }
      this.playerDetails.user_max_draw = this.gbf.toint(data.max_draws) ;
      this.playerDetails.user_max_ticket = this.gbf.toint(data.max_tickets);
  }

  stats_enable_function(id:any ) {
    this.stats_enable = id;
  }

  getformat(value){
    if(value == 0) {
      return 0;
    }
    else
    {        
    // hundreds
    if(value <= 999){
      return value ;
    }
    // thousands
    else if(value >= 1000 && value <= 999999){
      return (value / 1000).toFixed(2) + 'K';
    }
    // millions
    else if(value >= 1000000 && value <= 999999999){
      return (value / 1000000).toFixed(2) + 'M';
    }
    // billions
    else if(value >= 1000000000 && value <= 999999999999){
      return (value / 1000000000).toFixed(2) + 'B';
    } else if(value >= 1000000000000 && value <= 999999999999999){
      return (value / 1000000000000).toFixed(2) + 'T';
    } else if(value >= 1000000000000000 && value <= 999999999999999999){
      return (value / 1000000000000000).toFixed(2) + 'QD';
    }
    else if(value >= 1000000000000000000 && value <= 999999999999999999999){
      return (value / 1000000000000000000).toFixed(2) + 'QT';
    }
    else if(value >= 1000000000000000000000 && value <= 999999999999999999999999){
      return (value / 1000000000000000000000).toFixed(2) + 'ST';
    }
    else
      return value ;
    }
  }


  gamepayout_getformat(value){
    if(value == 0) {
      return 0;
    }
    else
    {        
    // hundreds
    if(value <= 999){
      return value ;
    }
    // thousands
    else if(value >= 1000 && value <= 999999){
      return (value / 1000).toFixed(2) + ' K';
    }
    // millions
    else if(value >= 1000000 && value <= 999999999){
      return (value / 1000000).toFixed(2) + ' M';
    }
    // billions
    else if(value >= 1000000000 && value <= 999999999999){
      return (value / 1000000000).toFixed(2) + ' B';
    } else if(value >= 1000000000000 && value <= 999999999999999){
      return (value / 1000000000000).toFixed(2) + ' T';
    } else if(value >= 1000000000000000 && value <= 999999999999999999){
      return (value / 1000000000000000).toFixed(2) + ' QD';
    }
    else if(value >= 1000000000000000000 && value <= 999999999999999999999){
      return (value / 1000000000000000000).toFixed(2) + ' QT';
    }
    else if(value >= 1000000000000000000000 && value <= 999999999999999999999999){
      return (value / 1000000000000000000000).toFixed(2) + ' ST';
    }
    else
      return value ;
    }
  }
  
  
}



