import { Injectable } from '@angular/core';
import { Howl, Howler } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class Sound {

  public  numberSelect = new Howl({
    src: ['assets/sound/numberselect.mp3'],
    html5: true
  });
  public  systemnumberSelect = new Howl({
    src: ['assets/sound/systemselected.mp3'],
    html5: true
  });
  public  systemnumberSelectMatch = new Howl({
    src: ['assets/sound/systemselectedMatch.mp3'],
    html5: true
  });
  public  buyticket = new Howl({
    src: ['assets/sound/ticket.mp3'],
    html5: true
  });

  public  time = new Howl({
    src: ['assets/sound/time.mp3'],
    html5: true
  });

  public soundMute = false;

  constructor() {

  }

 public mute() {
    this.soundMute = !this.soundMute;
    Howler.mute(this.soundMute);
  }

}
