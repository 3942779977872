import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toInt',
  pure: true
})
export class ToIntPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): number {
    console.log(value);
    return parseInt(value.toString().trim(), 10);
  }

}
