import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectFilter'
})
export class ObjectFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, filters: string[]): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    searchText = searchText.toString().toLowerCase();

    let filteredItems = [];
    items.forEach(item => {
      filters.forEach(filter => {
        if (item[filter] !== null && item[filter].toLowerCase()==searchText) {
          filteredItems.push(item);
        }
      });
    });
    return filteredItems;
  }
}
