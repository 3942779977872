import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
const link = document.createElement('link');
link.rel = 'stylesheet';
link.type = 'text/css';
link.href = 'assets/css/custom.css?' + Math.round(new Date().getTime() / 1000);
link.media = 'all';
document.head.prepend(link);
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
