import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable, of, observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ThemeService } from 'ng2-charts';
const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

@Injectable({
  providedIn: 'root'
})
export class KenoapiService {
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  baseUrl: string = environment.phpApiUrl;
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  constructor(private http: HttpClient) { }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Current Game Details
  currentGameDetails(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-current-game-details', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Player Details
  playerDetails(signID, user_id): Observable<any> {
    return this.http.get<any>('https://api.powerkeno.net/modal2.php?type=admin&user_id='+user_id+'&signed_request='+signID);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Get Game Side Status
  getcurrentgstatus(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-current-gstatus', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Add Edit API Player Details
  addEditPlayer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'insert-update-user', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // TICKET BUY
  // buyBetTicket(data: any): Observable<any> {
  //   return this.http.post<any>(this.baseUrl + 'generate-ticket', data);
  // }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Game Details
  getGameDetails(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-game-details', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Game Details
  getcurrentgameresult(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-current-game-result', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Hot Cold Number
  getHotColdNumber(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-hot-cold-number', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Chat Insert
  createchat(signID: any, data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'chat?signed_request='+signID, data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Insert Get


 /*
 getchat(data: any): Observable<any> {
    console.log(data);
console.log(data);	    
return this.http.post<any>('https://api.powerkeno.net/tournaments/chat', data);
  }
*/



//getchat(data: any, signID: string): Observable<any> {
getchat(data: any): Observable<any> {

  // Convert the data object into a URL-safe query string
    const queryString = Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
    
  // Append the query string to the URL
  const url = `https://api.powerkeno.net/tournaments/chat?${queryString}`;



//const queryString = Object.keys(data)
    //.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
   // .join('&');

  // Ensure signed_request is included in the data object without quotes
 // let cleanSignedRequest = data.signed_request.replace(/['"]/g, "");

  // Append the query string to the URL with the cleaned signed_request
//  const url = `https://api.powerkeno.net/tournaments/chat?signed_request=${cleanSignedRequest}&${queryString}`;



  // Log the data and the URL
  console.log(data);
  console.log(url);

  // Make the HTTP POST request with the data
  return this.http.get<any>(url, data);
}




  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Get Setting
  getsettings(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-setting', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Insert Chat Ignore
  setchatignore(signID: any, data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'ignore?signed_request='+signID, data);
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // delete Chat Ignore
  deletechatignor(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/delete-delete-ignore-list', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // delete Chat
  deletechat(signID: any, data: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'deletechat?signed_request='+signID+'&chat_id'+data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // get price  stage list
  getpricestagelist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-price-stage-list', data);
  }

  // get price category list
  getpricecategorylist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-price-category-list', data);
  }

  // get price item list
  getpricelist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-price-list', data);
  }

  // Buy price item
  purchaseprize(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'purchase-prize', data);
  }

  // Buy price item
  purchaseprizeall(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'purchase-prize-all', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // send donation
  senddonation(signID: any, data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'donation?signed_request='+signID, data);
  }


  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  get payoutlist log
  payoutlist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'payout-list', data);
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  get RECENT Draws
  getyourrecentdraws(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-your-recent-draws', data);
  }

  //  get RECENT Sidebet
  getrecentsidebets(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-recent-sidebets', data);
  }

  //  get RECENT PURCHASES
  getrecentpurchases(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-recent-purchases', data);
  }

  //  get Dailies Log
  getdailieslog(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-dailies-log', data);
  }

  // get donation
  getdonationlist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-donation', data);
  }

  //  get RECENT achivemnt
  getstatsachivementlist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-achievements', data);
  }


  //  get RECENT PowerUp
  getrecentpoweruppurchase(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-recent-powerups', data);
  }

  // Get Chat Ignore lIST
  getchatignore(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'status/get-ignores', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  get PowerUp List
  getpowerplist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'power/get-list', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  PowerUp PURCHASES
  purchasepowerup(signID, data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'powerups?signed_request='+signID, data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  get Leader Board
  getleaderboard(data: any, type: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'leaderboard/' + type, data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  get XP list
  getxplist(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'get-xp-list', data);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  get achivemnt Status
  getachivemnt(signID): Observable<any> {
    return this.http.get<any>('https://api.powerkeno.net/modal2.php?type=achievements&signed_request='+signID);
  }

  getPaulData(signID): Observable<any> {
    return this.http.get<any>(this.baseUrl+'onload?signed_request='+signID);
  }

  getGamechat(signID): Observable<any> {
    return this.http.get<any>(this.baseUrl+'chat?signed_request='+signID+'&room_id=2');
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //https://api.powerkeno.net/v1/update?signed_request=pkk5&chat_id=3251681&video_id=&room_id=2
  update(signID, data: any): Observable<any> {
    return this.http.get<any>(this.baseUrl+'update?signed_request='+signID+'&chat_id='+data.chat_id+'&video_id=&room_id='+data.room_id);
  }

  draw(signID): Observable<any> {
    return this.http.get<any>(this.baseUrl+'draw?signed_request='+signID);
  }

  drawend(signID): Observable<any> {
    return this.http.get<any>(this.baseUrl+'drawend?signed_request='+signID);
  }

  buyBetTicket(signID, data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'tickets?signed_request='+signID, data);
  }

  sideBet(signID, data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'sidebet?signed_request='+signID, data);
  }

  modalstats(signID):Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain'});  
    return this.http.get<any>('https://api.powerkeno.net/modal2.php?type=stats&signed_request='+signID);
  }

  modalpowerups(signID):Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain'});  
    return this.http.get<any>('https://api.powerkeno.net/modal2.php?type=powerups&signed_request='+signID);
  }

  modaldiamonds(signID):Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain'});  
    return this.http.get<any>('https://api.powerkeno.net/modal2.php?type=diamonds&signed_request='+signID);
  }


  checkStatus():Observable<any> {
    return this.http.get<any>('https://api.powerkeno.net/tournaments/status?signed_request=pkk5')
  }

}
