import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { KenoboardComponent } from './kenoboard/kenoboard.component';
import { TournamentComponent } from './tournament/tournament.component';


import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ChartsModule } from 'ng2-charts';
import { AutosizeModule } from 'ngx-autosize';

import { ReplaceEmojisPipe } from './pipes/replace-emojis.pipe';
import { ObjectFilterPipe } from './pipes/object-filter.pipe';
import { TimeElapsedPipe } from './pipes/time-elapsed.pipe';
import { ChatFilterPipe } from './pipes/chat-filter.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { ThousandSuffPipe } from './pipes/thousand-suff.pipe';
import { NumbformatPipe } from './pipes/numbformat.pipe';
import { ToIntPipe } from './pipes/to-int.pipe';
import { OrdinalPipePipe } from './pipes/ordinal-pipe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    TimeElapsedPipe,
    KenoboardComponent,
    TournamentComponent,
    ReplaceEmojisPipe,
    ObjectFilterPipe,
    ChatFilterPipe,
    OnlyNumberDirective,
    ThousandSuffPipe,
    NumbformatPipe,
    ToIntPipe,
    OrdinalPipePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    NgxBootstrapSliderModule,
    PickerModule,
    EmojiModule,
    HttpClientModule,
    ChartsModule,
    AutosizeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
