import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KenoboardComponent } from './kenoboard/kenoboard.component';
import { TournamentComponent } from './tournament/tournament.component';

const routes: Routes = [
  { path: '', component: KenoboardComponent },
  { path: 'tournament', component: TournamentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
