import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable, of, observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TournamentapiService {
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  baseUrl: string = environment.phpApiUrl;
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  constructor(private http: HttpClient) { }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Current Game Details
  tourlist(data: any): Observable<any> {
    return this.http.get<any>('https://api.powerkeno.net/tournaments/tournaments?signed_request=pkk5');
  }
  // Current Game Details
  touruserlist(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.net/tournaments/get-tour-user-list?signed_request=pkk5', data);
  }
  // Current Game Details
  tourregdereg(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.net/tournaments/set-tour-reg-dereg', data);
  }
  // Tournament Start
  tournamentisstarted(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.net/tournaments/tournament-started', data);
  }
  // Played Tournament Details
  tournamentStatus(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.net/tournaments/get-curr-tour-game-details', data);
  }
  // Buy ticket Details
  buyticket(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.net/tournaments/buy-ticket', data);
  }
  // draw result Details
  drawresult(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.nettournaments/get-payout-details', data);
  }
  // draw result Details
  tourresult(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.nettournaments/get-curr-tour-final-rest-show', data);
  }
  // player stats/tournament history
  playerStats(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.nettournaments/player-history', data);
  }
  // tournament payout list
  tourpayoutlist(data: any): Observable<any> {
    return this.http.post<any>('https://api.powerkeno.nettournaments/tourney-payout', data);
  }
  
  turneyinfo(data: any): Observable<any> {
    return this.http.get<any>('https://api.powerkeno.net/tournaments/info?signed_request=pkk5&id='+data.turney_id);
  }
}
