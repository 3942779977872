import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSuff'
})
export class ThousandSuffPipe implements PipeTransform {

  transform(input: any, args?: any): any {
    let number = parseInt(input);
    let output = input || 0;

    const decimal = args || 0;

    // let decimal = !decimal ? true : false;


    let input_format = this.add_commas(number);
    const input_count = input_format.split(',').length - 1;


    let num = 0;
    let letter = '';

    if (input_count != 0) {


      switch (input_count) {

        case 1:

          num = number / 1000;
          letter = 'K';
          break;

        case 2:
          num = number / 1000000;
          letter = 'M';
          break;

        case 3:

          num = number / 1000000000;
          letter = 'B';
          break;

        case 4:
          num = number / 1000000000000;
          letter = 'T';
          break;

        case 5:
          num = number / 10000000000000;
          letter = 'QD';
          break;

          case 6:
          num = number / 10000000000000000;
          letter = 'QI';
          break;

          case 7:
          num = number / 10000000000000000000;
          letter = 'SX';
          break;

          case 8:
          num = number / 10000000000000000000000;
          letter = 'ST';
          break;

          case 9:
          num = number / 10000000000000000000000000;
          letter = 'OT';
          break;

      }// END OF INPU


      // number = shorten_number_decimal(num, decimal) + ' '+letter;
      output = this.floor(num, decimal) + ' ' + letter;

    }// END OF IF UNDER 1 K

    return output;
    // let exp, rounded,
    //   suffixes = ['K', 'M', 'B', 'T', 'TB', 'E'];

    // if (Number.isNaN(input)) {
    //   return null;
    // }

    // if (input < 1000) {
    //   return input;
    // }

    // exp = Math.floor(Math.log(input) / Math.log(1000));

    // return (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  }


  add_commas(nStr) {
    nStr += '';
    const xxx = nStr.split('.');
    let x1 = xxx[0];
    const x2 = xxx.length > 1 ? '.' + xxx[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  floor(num, upto) {
    return Math.floor(num * Math.pow(10, upto)) / Math.pow(10, upto);
  }
}
